.App {
    text-align: center;
}

#root {
    text-align: -webkit-center;
}

/* do not remove */
body>iframe {
    display: none;
}

.relative {
    position: relative;
}

.bi-info-circle {
    margin-top: -2px;
}

iframe::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 40px;
}

iframe::-webkit-scrollbar {
    width: 10px;
}

iframe::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fff;
    background: #000000 !important;
    border: 1px solid #000;
}

.step_containers::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 40px;
}

.step_containers::-webkit-scrollbar {
    width: 10px;
}

.step_containers::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fff;
    background: #000000 !important;
    border: 1px solid #000;
}

.container::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 40px;
}

.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fff;
    background: #000000 !important;
    border: 1px solid #000;
}

.step_containers_mobile::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 40px;
}

.step_containers_mobile::-webkit-scrollbar {
    width: 10px;
}

.step_containers_mobile::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fff;
    background: #000000 !important;
    border: 1px solid #000;
}

mi-list::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
}

mi-list::-webkit-scrollbar {
    width: 10px;
}

mi-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fff;
    background: #000000 !important;
    border: 1px solid #000;
}

.location_info_container::-webkit-scrollbar {
    width: 8px;
}

.vr_global_container::-webkit-scrollbar {
    width: 8px;
}

.location_info_container ::-webkit-scrollbar-track {
    width: 8px;
    margin-top: 50px;
    margin-bottom: 50px;
}

::-webkit-scrollbar-thumb {
    background: #cfcece !important;
    border-radius: 10px;
    z-index: 999;
    border: 1px solid #acacac;
}

.ListToSelect::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.calendar_container::-webkit-scrollbar {
    background: transparent !important;
}

.uppercase-s {
    text-transform: uppercase;
    font-size: 14px;
    margin-top: -5px;
}

.uppercase-xs {
    text-transform: uppercase;
    font-size: 10px;
}

.wrap {
    flex-wrap: wrap;
    display: flex;
}

.pb-25 {
    padding-bottom: 25px !important
}

.padt-5 {
    padding-top: 5px !important
}

.mt--3 {
    margin-top: -3px !important
}

.mt-3 {
    margin-top: 3px !important
}

.mt-20 {
    margin-top: 20px !important
}

.ml-1 {
    margin-left: 0.5rem !important
}

.ml-2 {
    margin-left: 2px !important
}

.ml-12 {
    margin-left: 12px !important
}

.mr-4 {
    margin-right: 4px !important
}

.mr-5 {
    margin-right: 5px !important
}

.bold {
    font-family: "GucciSans-Bold" !important;
}

.uppercase {
    text-transform: uppercase;
}

.bold>h2 {
    font-family: "GucciSans-Bold" !important;
}

.gucci-text-bold {
    font-family: "GucciSans-Bold" !important;
    font-size: 30px;
}

.gucci-text-bold-s {
    font-family: "GucciSans-Bold" !important;
    font-size: 15px;
}

.gucci-text-bold-m {
    font-family: "GucciSans-Bold" !important;
    font-size: 22px;
}

.gucci-text {
    align-self: center;
    font-size: 16px;
    padding-top: 15px;
    margin-left: 3px;
    font-family: 'GucciSans-Book';
}

.flex {
    display: flex;
}

.flex_wrap {
    display: flex;
    flex-wrap: wrap;
}

.flex_menu {
    display: flex;
    width: 100%;
}

.right_block {
    width: 200px;
    min-width: 200px;
}

.hidden {
    visibility: hidden;
    position: absolute;
    right: 70px;
    width: 400px;
    top: 30px;
    height: 550px;
    background-color: #eeeeef;
    z-index: 5;
    display: none;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    padding: 0 10px;
    height: 8px;
    z-index: 999;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    z-index: 999;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
    z-index: 999;
}

.pointer {
    cursor: pointer;
}

.cursor_auto {
    cursor: auto !important;
}

/* Homepage */
.home_page {
    display: inline-flex;
    width: 100%;
    /*position: absolute;*/
    max-width: 1366px;
    padding: 0 10px;
    margin-top: 15px;
    position: relative;
}

.home_title {
    font-family: "GucciSans-Bold";
    font-size: 25px;
    width: 90%;
    align-self: center;
}

.home_menu {
    width: calc(100% - 300px);
    display: inline-flex;
}

.bg-white {
    background-color: white !important;
    height: 20px;
}

.web_pathlist {
    background-color: #fff;
    height: 25px;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 204;
    padding: 0 10px;
    border-bottom: 1px solid lightgray;
}

.web_pathlist_text {
    text-align: left;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.title_container {
    margin: 25px;
    display: flex;
}

.title_container_wellness {
    margin: 20px 25px 10px 25px;
    display: flex;
}

.calendar {
    width: 95%;
    overflow: hidden;
}

.calendar_day {
    padding-bottom: 5px;
}


.deskbooking_container {
    padding-top: 20px;
}

.datePicker_filter {
    border: 0px;
    margin-right: 20px;
    background-color: #fbfbfb;
    margin-top: 13px;
}

/*
.filter_title {
    padding-bottom: 2%;
    margin-top: 10px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: -webkit-left;
    margin-left: 12px;
    font-family: "GucciSans-Bold";
}*/

.filter_title {
    padding-bottom: 2%;
    margin-top: 10px;
    color: black;
    /* font-size: 12px; */
    /* text-transform: uppercase; */
    text-align: -webkit-left;
    margin-left: 16px;
    font-family: "GucciSans-Bold";
}

.filters_list {
    font-family: "GucciSans-Bold";
    color: #5f5f5fa1;
    height: 70%;

}

.filters_body {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    text-align: -webkit-center;
    height: 50vh;
}

.filters_body::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.filters_card {
    width: 300px;
    background-color: #fbfbfb;
    border-radius: 7px;
}

.filters_card_s {
    width: 300px;
    border-radius: 7px;
    position: relative;
}

.filter_input {
    width: 100%;
    border: 0;
    background-color: #fbfbfb;
}

.filter_input_services {
    width: 93%;
    border: 0;
    height: 46px;
    margin-left: 5%;
}

.filter_input_update {
    width: 70%;
    border: 0;
    height: 45px;
    font-size: 14px;
    margin-top: -5px;
}

.filter_input_update>.css-1tvvavn-control>.css-319lph-ValueContainer {
    padding: 2px 0 !important;
}

.modal_title {
    font-family: "GucciSans-Bold";
}

.modal_body {
    margin: 0 10px 10px;
}

.modal_select {
    margin-top: 40px
}

.participants_container {
    overflow-y: auto;
    max-height: 300px;
}

.filter_input::placeholder {
    color: black;
}

.filter_input_name {
    width: 94%;
    border: 0;
    height: 46px;
    /* margin-left: 5%; */
    background-color: #fbfbfb;
    font-family: "GucciSans-Book";
}

.filter_input_name::placeholder {
    color: #a4a3a0;
}

.filter_input_name:focus {
    outline: none;
}

.filter_text_fixed {
    width: 93%;
    border: 0;
    font-family: "GucciSans-Bold";
    font-size: 14px;
    padding-top: 2%;
    padding-bottom: 2%;
    /*color: black;*/
}

.filter_input::after {
    border-color: black;
}

.filter_separator {
    margin: 0;
    width: 95%;
    margin-left: 5%;
}

.profile {
    /*width: 866px;*/
    margin-top: 15px;
    margin-left: 15px;
    padding: 0 20px;

}

.profileLabel {
    background-color: #808080;
    border-radius: 50px;
    cursor: pointer;
    display: -webkit-flex;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 18px;
    width: 34px;
    transform: scale(1.5);
    margin-left: 32px;
    margin-top: 6px;
}

.filter_label {
    background-color: #808080;
    border-radius: 50px;
    cursor: pointer;
    display: -webkit-flex;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 16px;
    width: 31px;
    transform: scale(1.5);
    margin-left: auto;
    margin-right: 5px;
    margin-top: 4px;
}

.checkbox:checked+.filter_label .filter_ball {
    transform: translateX(15px);
    background-color: white;
}

.checkbox:checked+.filter_label {
    background-color: #000000;
}


.label {
    background-color: #808080;
    border-radius: 50px;
    cursor: pointer;
    display: -webkit-flex;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 23px;
    width: 38px;
    transform: scale(1.5);
    margin-left: 32px;
}

.checkbox:checked+.label .ball {
    transform: translateX(15px);
    background-color: white;
}

.checkbox:checked+.label {
    background-color: #000000;
}

.checkbox:checked+.profileLabel .profileBall {
    transform: translateX(15px);
    background-color: white;
}

.checkbox:checked+.profileLabel {
    background-color: #000000;
}

.checkbox {
    opacity: 0;
    position: absolute;
}

.label .ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    height: 19px;
    width: 19px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.profileLabel .profileBall {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    height: 14px;
    width: 14px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.filter_label .filter_ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    height: 12px;
    width: 12px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.close {
    float: right;
    cursor: pointer;
}

.pm_guide_dialog>.MuiDialog-container>.MuiPaper-root {
    width: fit-content !important;
    border-radius: 20px;
}

.tutorial_dialog>.MuiDialog-container>.MuiPaper-root {
    /*background-color: #efefef;*/
    width: fit-content !important;
    border-radius: 20px;
}

body>div.MuiDialog-root.tutorial_dialog>div.MuiDialog-container.MuiDialog-scrollPaper>div>div {
    padding: 0 !important;
}

.whats_new_dialog>.MuiDialog-container>.MuiPaper-root {
    background-color: #000;
    color: #fff;
    border-radius: 20px;
}

#search_reset {
    margin-left: -5%;
    margin-top: 7px;
}

.filters_container {
    animation: open_menu 0.3s forwards;
    position: absolute;
    left: calc(88% - 330px);
    width: 330px;
    top: 80px;
    z-index: 15;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 1px 5px #cfcfcf;
}

.filters_container_admin {
    animation: open_menu 0.3s forwards;
    position: absolute;
    left: calc(88% - 330px);
    width: 330px;
    top: 80px;
    z-index: 15;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 1px 5px #cfcfcf;
}

/* calendar desk booking */
div.rbc-time-header>div.rbc-label.rbc-time-header-gutter {
    min-width: 59px !important;
}

.rbc-toolbar {
    display: none !important;
}

.reservation_container {
    display: none;
}

.DatePicker_buttonWrapper__3OoZD {
    display: flex;
    align-items: flex-end;
    z-index: 3;
    background-color: #ececec7d;
    padding-right: 8px !important;
}

.DatePicker_button__2cDoZ {
    background-color: black !important;
}

.DatePicker_monthYearLabel__8WC42 {
    color: black !important;
}

.DatePicker_dateDayItem__3rHW7 {
    color: grey;
    border-color: black !important;
    width: 50px !important;
    height: 50px !important;
    place-content: center !important;
}

.DatePicker_dayLabel__3L0-J {
    font-size: 14px;
}

.DatePicker_dateLabel__3OOEX {
    font-size: 14px;
}

#selector {
    color: black !important;
}

.separator {
    margin: 9px 9.5% 8px 0% !important;
    width: 100%;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.courses_container {
    padding-bottom: 50px;
    padding-top: 10px;
    width: 100%;
    text-align: -webkit-left;
    float: left;
}

#alert-dialog-title {
    padding: 10px;
}

.ReactModal__Overlay {
    z-index: 220
}

.ReactModal__Content {
    z-index: 99;
    border-radius: 20px !important;
}

.desk_calendar_container {
    height: 95vh;
    padding-bottom: 50px;
    padding-top: 10px;
}

.card_container {
    /* margin-left: 3% !important;
    padding-left: 3% !important;
    */
    padding-top: 1% !important;
    padding-bottom: 2% !important;
    padding-right: 1% !important;
    width: 94%;
    animation: fade 2s forwards;
}

.card_container:hover {
    cursor: pointer;
}

.course_image {
    width: 85px !important;
    height: 80px;
    margin-top: 8px;
    border-radius: 5px;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 85px;
    min-width: 85px;
}

.reservation_course_sx {
    width: 40%
}

.reservation_course_dx {
    width: 50%
}

.reservation_course_button {
    width: 20%;
    text-align: -webkit-center;
}

.reservation_course_image {
    width: 100px !important;
    min-width: 100px;
    height: 80px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.course_name {
    font-family: "GucciSans-Bold";
    font-size: 14px;
    margin-top: 1% !important;
    margin-left: 4%;
    height: 80px;
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.reservation_course_name {
    font-family: "GucciSans-Bold";
    font-size: 15px;
    margin-left: 10px;
    height: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;

}

.reservation_manage_course_name {
    font-family: "GucciSans-Bold";
    font-size: 15px;
    margin-left: 10px;
    height: 90px;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.margin_course_location {
    margin-top: 6px !important;
}

.reservation_icon {
    margin-top: 12px;
}

.course_description {
    color: grey;
    font-family: "GucciSans-Bold";
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.reservation_course_description {
    color: black;
    font-family: "GucciSans-Book";
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.reservation_course_certificate {
    color: black;
    font-family: "GucciSans-Bold";
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
}


.course_seats {
    color: grey;
    font-family: "GucciSans-Bold";
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.book_button_container {
    align-self: center;
    text-align: -webkit-center;
    margin-top: 8px;
    width: 25%;
}

.filters_buttons_container {
    align-self: center;
    text-align: -webkit-center;
    position: relative;
    margin-bottom: 30px;
}

.container_list_button {
    text-align: -webkit-right;
    align-items: center;
    width: 25%;
    justify-content: right;
}

.book_button {
    color: black;
    font-family: "GucciSans-Book";
    border: 2px solid black;
    padding: 15px 7px;
    text-align: center;
    cursor: pointer;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    width: 130px !important;
    font-size: 12px;
    text-transform: uppercase;
}

.book_button_disabled {
    color: #bfbfbf;
    font-family: "GucciSans-Book";
    border: 2px solid #bfbfbf;
    padding: 15px 7px;
    text-align: center;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    width: 130px !important;
    font-size: 12px;
    text-transform: uppercase;
}

.remove_booking {
    background-color: black;
    color: white;
    font-family: "GucciSans-Book";
    border: 1px solid black;
    padding: 15px 7px;
    text-align: center;
    cursor: pointer;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    width: 130px !important;
    font-size: 12px;
    text-transform: uppercase;
}

.remove_waiting {
    background-color: black;
    color: white;
    font-family: "GucciSans-Book";
    border: 1px solid black;
    padding: 7px 7px;
    text-align: center;
    cursor: pointer;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    width: 130px !important;
    font-size: 12px;
}


.book_button_add_user {
    background-color: black;
    color: white;
    font-family: "GucciSans-Bold";
    border: 1px solid black;
    height: 40px;
    width: 100px;
    padding-top: 7px;
    text-align: center;
    cursor: pointer;
    border-radius: 25px;
}

.modal_button {
    margin-top: 150px;

    float: right;
    margin-right: 20px;
}

.modal_button_confirm {
    color: white !important;
    background-color: #000000 !important;
}

.remove_modal_button {
    background: linear-gradient(rgb(160 5 5) 64%, rgb(194 0 0 / 76%) 92%) !important;
    padding-left: 7px !important;
    margin-left: 0px !important;
    color: white !important;
}

.apply_filters_button,
.reset_filters_button {
    background-color: #000000;
    color: white;
    font-family: "GucciSans-Book";
    border-radius: 20px;
    width: 150px;
    padding: 7px 0;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
}


.success-alert {
    background: rgba(11, 104, 0, 0.72);
    width: 50%;
    float: right;
    margin-top: -39px;
    border-radius: 10px;
    padding: 6px;
    height: 50px;
    margin-right: 3px;
}

.iziToast-wrapper-bottomCenter {
    text-align: right !important;
}

.iziToast-body {
    padding-right: 33px !important;
}

#dropdown-basic {
    background-color: #000000;
    border: 0 !important;
    /*color: black !important;*/
    color: white;
    border-radius: 5px !important;
}

.dropdown-menu {
    z-index: 1001;
}

#root>div>div.title_container>div.filters_container>div.filters_list>div:nth-child(4)>div.flex>div.react-datepicker-wrapper>div>input[type="text"] {
    width: 100%;
    background-color: #eeeeef;
    border: 0 !important;
    border-radius: 3px;
    padding-left: 10%;
}

.filters_container_admin>div.filters_list>div:nth-child(4)>div.flex>div.react-datepicker-wrapper>div>input[type="text"] {
    width: 100%;
    background-color: #eeeeef;
    border: 0 !important;
    border-radius: 3px;
    padding-left: 10%;
}

#root>div>div.title_container>div.filters_container>div.filters_list>div:nth-child(4)>div.flex>div.react-datepicker-wrapper>div>input[type="text"]:focus {
    outline: none;
}

.filters_container_admin>div.filters_list>div:nth-child(4)>div.flex>div.react-datepicker-wrapper>div>input[type="text"]:focus {
    outline: none;
}

/*select*/
.css-1s2u09g-control {
    border: 0 !important;
}

.css-1s2u09g-control:hover {
    border-color: white !important;
    box-shadow: 0 0 0 1px white !important;
}

.css-1pahdxg-control {
    border-color: white !important;
    box-shadow: 0 0 0 1px white !important;
}

.css-1pahdxg-control:hover {
    border-color: white !important;
    box-shadow: 0 0 0 1px white !important;
}

.no_available {
    margin-top: 20%;
    text-align: -webkit-center;
    height: fit-content;
    width: 100%;
}

.no_courses {
    font-family: "GucciSans-Bold";
    font-size: 23px;
    margin-top: 20px;
}

.swipeable-list-item {
    cursor: e-resize;
}

.swipeable-list-item__trailing-actions {
    cursor: pointer;
}

/*course page*/

.course {
    width: 100%;
}

@keyframes from_left {
    from {
        margin-left: -50%;
    }

    to {
        margin-left: 0%;
    }
}

@keyframes from_left_res {
    from {
        margin-left: -50%;
    }

    to {
        margin-left: 0;
    }
}

@keyframes from_right {
    from {
        margin-left: 200%;
    }

    to {
        margin-left: 0%;
    }
}

.header_container {
    margin-top: 20px;
}

.course_header {
    width: 250px;
    min-width: 250px;
    height: 180px;
    background-image: url("./resources/img/example.jpg");
    transition: 1s;
    animation: from_left 0.5s forwards;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    /* border-top-left-radius: 10px; */
    /* border-bottom-left-radius: 10px; */
    border-radius: 10px;
    /* margin-top: 20px; */

}

.course_header_manage {
    width: 230px;
    min-width: 230px;
    height: 180px;
    transition: 1s;
    animation: from_left 0.5s forwards;
    background-size: cover;
    border-radius: 10px;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position: center;
    /*try to fll bg color*/
    mix-blend-mode: darken;
}

.reservation_header {
    width: 90%;
    top: 50px;
    height: 45px;
    transition: 1s;
    animation: from_left_res 0.5s forwards;
    background-size: cover;
    border-top-left-radius: 53px;
    text-align: -webkit-center;
}

.reservation_title {
    font-family: "GucciSans-Bold";
    font-size: 25px;
    width: 100%;
    text-align: center;
}

.reservation_search {
    width: 100%;
    margin: 10px 0;
    transition: 1s;
    animation: from_left_res 0.5s forwards;
}

.reservation_class {
    transition: 1s;
    animation: from_left_res 0.5s forwards;
    width: 100%;
    border: 0;
    margin-top: 10px;
    background-color: #fbfbfb;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    font-family: "GucciSans-Book";
    display: -webkit-flex;
}

.reservation_class_web {
    background-color: #ededed;
    margin-bottom: 30px;
    width: 500px;
    border-radius: 20px;
    margin-top: 10px;
    height: 28px;
    text-align: center;
    border-radius: 10px;
    font-family: "GucciSans-Book";
    display: -webkit-flex;
}

.reservation_button_selected_web {
    background-color: #fff;
    padding: 2px;
    width: 250px;
    border-radius: 20px;
    height: 25px;
    margin-top: 1px;
    box-shadow: 0 1px 5px #cfcfcf;
}

.reservation_button_web {
    padding: 2px;
    width: 250px;
    height: 25px;
    margin-top: 1px;
}

.reservations_container {
    transition: 1s;
    animation: from_left_res 0.5s forwards;
    padding: 0 40px;
    margin-top: 20px;
    text-align: center;
    font-family: "GucciSans-Book";
}

.reservations_container::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.reservation_description_container {
    width: 100%
}

.reservation_course_manage_button {
    width: 20%;
    align-self: center;
}

.reservation_line {
    width: 100%
}

.reservation_line:hover {
    cursor: pointer
}

.container_manage_reservation::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.manage_courses_list_container {
    transition: 1s;
    animation: from_left_res 0.5s forwards;
    width: 90%;
    padding-right: 2%;
    border: 0;
    margin-top: 20px;
    text-align: center;
    border-radius: 10px;
}

.manage_courses {
    font-family: "GucciSans-Book";
    overflow: auto;
    height: 62vh;
    margin-right: -20px;
}

.manage_courses::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.ReservationDetailsfixed {
    padding-top: 30px;
    padding-bottom: 30px;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    width: 719px !important;
    border-radius: 15px 15px 0 0;
    background-color: #F0EFEF;
    animation-duration: 0.5s;
    animation-name: slideinDetail;
    left: 74.8% !important;
    transform: translateX(-50%);
    border: 1px solid #c5c5c540;
}

.reservation_class_confirmed {
    width: 50%;
    height: 40px;
    padding-top: 8px;
    cursor: pointer;
}

.reservation_class_confirmed_selected {
    width: 50%;
    height: 34px;
    padding-top: 5px;
    margin-top: 3px;
    /*margin-left: 5px;*/
    background-color: white;
    border-radius: 9px;
    font-family: "GucciSans-Bold";
}

.reservation_class_waiting {
    width: 50%;
    height: 40px;
    padding-top: 8px;
    cursor: pointer;
}

.reservation_class_waiting_selected {
    width: 50%;
    height: 34px;
    padding-top: 5px;
    margin-top: 3px;
    margin-right: 5px;
    background-color: white;
    border-radius: 9px;
    font-family: "GucciSans-Bold";
}

.back {
    margin-top: 15px;
    margin-left: 25px;
    z-index: 99;
    width: 3%;
    cursor: pointer;
}

.back_left_bar {
    margin-left: 25px;
    z-index: 99;
    width: 5%;
}

.back_to_calendar {
    margin-top: 15px;
    z-index: 99;
    width: 7%;
    visibility: hidden;
}

.central_component {
    max-width: 1066px;
    width: calc(100% - 300px);
    overflow-x: hidden;
    /*height: 110vh;*/
    /*test it on mobile*/
    /*padding-bottom: 200px;*/
    padding-bottom: 50px;
    position: absolute;
    left: 300px;
    top: 75px;
}

.central_component_width {
    width: 90%;
    background-color: white;
    border-radius: 20px;
    /* min-height: 100vh;*/
    min-height: calc(100vh - 135px);
    box-shadow: 0 5px 10px #bfbfbf;
    padding-bottom: 40px;
}

.central_component_wellness_admin {
    width: 98% !important;
    float: right;
}

.central_component::-webkit-scrollbar {
    width: 0;
}

.manage_page_title_container {
    width: 100%;
}

.page_title_container {
    width: 85%;
    display: grid;
    justify-items: center;
}

.course_page_title {
    font-family: "GucciSans-Bold";
    font-size: 28px;
    align-self: center;
    margin-top: 12px;
    width: 80%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.info {
    width: 100%;
}

.info_header {
    animation: from_right 0.5s forwards;
    /*background-color: #fbfbfb;*/
    display: flex;
    width: 100%;
    padding-left: 20px;
}

.text-center {
    text-align: center;
}

.gym_info {
    margin-top: 40px;
    width: 100%;
}

.gym_info_image_container {
    width: 380px;
    height: 250px;
    background-size: cover;
    border-radius: 10px;
}

.gym_info_description {
    margin: 30px 10px;
    max-width: 500px;
}

.gym_info_day {
    min-width: 160px;
    text-align: -webkit-left;
}

.gym_info_row {
    place-content: center;
}

.gym_info_hours_container {
    margin-top: 20px;
}

.gym_info_container_hours {
    display: flex;
    padding-top: 15px;
    border-radius: 8px;
    max-height: 130px;
    overflow: hidden;
}

.day {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid;
}

.last_day {
    padding-right: 20px;
}

.day_footer {
    padding-right: 5px;
    margin-right: 10px;
}

.day_name {
    padding-right: 5px;
    margin-right: 10px;
    width: 80px;
}

.day_hour {
    margin-right: 2px;
    margin-left: 2px;
}

.hours {
    line-height: 0.1rem;
    margin-top: 5px;
}

.gym_info_hours {
    font-family: "GucciSans-Bold";
    font-size: 11px;
    margin-top: -20px;
    padding: 0.5rem;
}

.gym_location_container {
    margin-left: 5px;
    font-family: "GucciSans-Bold";
    font-size: 13px;
    margin-top: -10px;
    padding: 0.5rem;
    justify-content: center;
}

.hours_container {
    font-family: "GucciSans-Bold";
    font-size: 11px;
    margin-top: -10px;
    padding: 0.5rem;
}

.gym_info_hours_mobile {
    margin-left: 5px;
    font-family: "GucciSans-Bold";
    font-size: 11px;
    margin-right: 10px;
    margin-top: -20px;
    display: none;
    width: 100%;
}

.flag_complete {
    background-color: #000000;
    text-transform: uppercase;
    color: white;
    font-family: "GucciSans-Bold";
    font-size: 12px;
    padding: 3px;
    margin-left: 10px;
    margin-top: 5px;
    border-radius: 3px;
    height: max-content;
}

.flag_booked {
    background-color: #000000;
    text-transform: uppercase;
    color: white;
    font-family: "GucciSans-Bold";
    font-size: 12px;
    padding: 3px;
    margin-left: 10px;
    border-radius: 3px;
    height: max-content;
    width: fit-content;
}

.footer_course {
    position: absolute;
    bottom: 40px;
    width: 100%;
}

.reserv_menu_container {
    padding: 35px;
    width: 100%;
}

.reserv_menu {
    position: relative;
    max-width: 1066px;
    width: 100%;
    overflow: auto;
}


.reserv_menu_manage {
    position: relative;
    max-width: 1066px;
    width: 65%;
    overflow: auto;
}

.course_page_info_title {
    font-family: "GucciSans-Bold";
    font-size: 20px;
    align-self: center;
    text-align: left;
    align-items: center;
}

.update_coure_icon {
    margin-left: 5px;
    margin-top: -3px;
}

.course_page_info_title_manage {
    font-family: "GucciSans-Bold";
    font-size: 16px;
    align-self: center;
    margin-top: 8px;
    text-align: left;
}

.course_page_info_subtitle {
    font-family: "GucciSans-Book";
    font-size: 16px;
    align-self: center;
    text-align: left;
    color: #5a5956
}

.course_page_info_subtitle_manage {
    font-family: "GucciSans-Bold";
    font-size: 14px;
    align-self: center;
    text-align: left;
    color: #5a5956
}

.manage_location {
    font-family: "GucciSans-Bold";
    font-size: 14px;
    margin-top: 15px;
    align-items: center;
    color: #5a5956
}

.progressbar_container {
    align-self: center;
}

.statistic_container {
    text-align: -webkit-right;
}

.add_booking {
    margin-top: 5px;
    margin-bottom: 15px;
}

.progressbar {
    width: 80px;
    height: 80px;
    margin: 0 5px;
}

.legend_progressbar {
    font-size: 10px;
    margin-top: 5px;
    text-transform: uppercase;
    text-align: center;
}

.CircularProgressbar-text {
    dominant-baseline: middle;
    text-anchor: middle;
}

.list_container {
    margin-top: 20px;
    width: 99%;
    text-align: -webkit-center;
}

#list_container::-webkit-scrollbar-thumb {
    margin-left: -2px !important;
    background-color: #000000 !important;
}

#list_container_trainer::-webkit-scrollbar-thumb {
    margin-left: -2px !important;
    background-color: #000000 !important;
}

#list_container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 46vh;
}

#list_container_trainer {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 46vh;
}

.booked_title_container {
    background-color: #e7e7e7;
    width: 100%;
    padding: 5px 5px 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.booked_title {
    font-family: "GucciSans-Bold";
    font-size: 16px;
    color: #252525;
    width: 40%
}

.booked_container {
    background-color: #ffffff;
    width: 100%;
    font-family: "GucciSans-Book";
    padding: 5px 5px 5px 10px;
}

.waiting_title_container {
    background-color: #e7e7e7;
    width: 100%;
    font-family: "GucciSans-Bold";
    padding: 5px 5px 5px 10px;
}

.waiting_container {
    background-color: #ffffff;
    width: 100%;
    font-family: "GucciSans-Book";
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px 5px 5px 10px;
}

.booked_manage_card_container {
    width: 40%;
}

.actions_card_container {
    width: 15%;
    justify-content: center;
}

.actions_card {
    margin-top: 10px;
    margin-bottom: 10px;
    display: -webkit-inline-box;
}

.action_icon {
    margin-left: 10px;
    cursor: pointer;
}

.booked_manage_card {
    height: 60px;
    align-items: center;
    padding: 5px 0px;
}

.circle_manage_card {
    background-color: #ffffff;
    font-family: "GucciSans-Bold";
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 13px
}

.name_manage_card {
    margin-left: 10px;
    font-family: "GucciSans-Book";
    text-align: left;
}

.timestamp_manage_card {
    font-family: "GucciSans-Book";
    margin-top: 10px;
    margin-bottom: 10px;
}

.course_info {
    width: 80%;
}

.course_info_manage {
    width: 100%;
}

.course_img_container {
    width: 20%;
    align-self: center;

}

.course_img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
}

.course_description_container {
    width: 100%;
}

.course_description_row {
    width: 100%;
    align-items: center;
    margin-top: 30px;
}

.course_description_title {
    font-family: "GucciSans-Bold";
    font-size: 20px;
    width: 75%;
    /* margin-left: 25px; */
    text-transform: uppercase;
    text-align-last: left;
}

.course_description_content {
    font-family: "GucciSans-Book";
    text-align: justify;
    font-size: 14px;
    margin-top: 12px;
    width: 100%;
    height: 36vh;
    overflow-y: auto;
}

.course_page_footer_container {
    justify-content: center;
}

.course_page_footer {
    width: 100%;
    height: 130px;
    background-color: #e9e9e9;
    border-radius: 35px;
    padding-top: 15px;
    color: #252525;
    margin-left: 8px;
    margin-top: 10px
}

.course_page_footer_slim {
    width: 100%;
    background-color: #dcdcdc;
    border-radius: 15px;
    color: #252525;
    padding: 10px 0;
}

.align_center {
    text-align: center;
}

.vertical_center {
    align-items: center;
}

.footer_info {
    font-family: "GucciSans-Bold";
    font-size: 25px;
    color: white;
    margin-left: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.footer_sub_info {
    font-family: "GucciSans-Bold";
    font-size: 20px;
    color: white;
    margin-left: 20px;
}

.book_button_footer {
    color: white;
    background-color: black;
    border: 1px solid white;
    font-family: "GucciSans-Bold";
    height: 40px;
    width: 100px;
    padding-top: 7px;
    text-align: center;
    cursor: pointer;
}

.booked_button_footer {
    color: white;
    background-color: #4e8d1e;
    border: 1px solid white;
    font-family: "GucciSans-Bold";
    height: 40px;
    width: 100px;
    padding-top: 7px;
    text-align: center;
    cursor: pointer;
}

.book_button_container_footer {
    position: absolute;
    right: 0px;
    margin-right: 50px;
    bottom: 70px;
}

.follow_button {
    background-color: black;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    padding-top: 5px;
}

.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #cccccc;
    border-radius: 50%;
    animation: loading 1.5s cubic-bezier(0.8, 0.5, 0.2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
}

@keyframes loading {
    0% {
        transform: translateY(0px);
        background-color: #797877;
    }

    50% {
        transform: translateY(50px);
        background-color: #020202;
    }

    100% {
        transform: translateY(0px);
        background-color: #777777;
    }
}

.circle-1 {
    animation-delay: 0.1s;
}

.circle-2 {
    animation-delay: 0.2s;
}

.circle-3 {
    animation-delay: 0.3s;
}

.circle-4 {
    animation-delay: 0.4s;
}

.circle-5 {
    animation-delay: 0.5s;
}

.circle-6 {
    animation-delay: 0.6s;
}

.circle-7 {
    animation-delay: 0.7s;
}

.circle-8 {
    animation-delay: 0.8s;
}

@keyframes alterne {
    0% {
        background-image: url("./resources/img/gym_1.png");
    }

    50% {
        background-image: url("./resources/img/gym_1.png");
    }

    60% {
        background-image: url("./resources/img/gym_2.png");
    }

    100% {
        background-image: url("./resources/img/gym_2.png");
    }
}

/* custom gym loader*/
.gym_loading {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 40%;
    background-size: contain;
    left: 30%;
    animation: alterne 0.5s infinite;
}

/*LOGIN*/
.login {
    width: 100%;
    position: absolute;
    height: 100vh;
    background-color: #000000;
    overflow-y: auto;
}

.pm_info_row {
    width: 95%;
    padding: 0px 4%;
    justify-self: center;
}

.pm_info_row_nav {
    width: 100%;
}

@keyframes login_sep_animation {
    from {
        width: 0px;
    }

    to {
        width: 150px;
    }
}

.login-separator {
    width: 11vw;
    height: 2px !important;
    margin-right: 25px !important;
    margin-left: 25px !important;
    color: #000;
}

.symb {
    font-size: 16px;
    color: #ffffff;
    font-family: 'GucciSans-Book';
}

.login-separator-cont {
    align-items: center;
    display: -webkit-inline-flex;
}

.separator-container {
    text-align: -webkit-center;
}

.logo-container {
    width: 30%;
    border-radius: 5%;
    padding-bottom: 0px;
    margin: auto;
    margin-top: 10px;
    text-align: -webkit-center;
}

@keyframes logo_animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.logo {
    background-image: url("/src/resources/img/gucci-logo.png");
    width: 95%;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    margin-bottom: 30px;
    animation: logo_animation 2s;
}

.input-container {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}


@keyframes login_animation {
    from {
        width: 0%;
        font-size: 0px;
    }

    to {
        width: 25%;
        font-size: 18px;
    }
}

.login-input-text::placeholder {
    text-align: center;

}

.login-input-text {
    width: 30%;
    border-radius: 10px;
    border: 0;
    height: 46px;
    text-align: center;
}


.login-button {
    width: 30%;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 1px;
    border: 0;
    height: 46px;
    background-color: #ffffff;
    font-family: "GucciSans-Bold";
    font-size: 18px;
    margin-top: 25px;
    box-shadow: 3px 3px black;
    color: black;
}

.login-button:focus {
    box-shadow: none
}

.login-button:hover {
    background-color: #c5c5c5;
}

@keyframes logout_animation {
    from {
        width: 0%;
    }

    to {
        width: 87%;
    }
}

.logout-container {
    display: block;
    position: absolute;
    bottom: 5px;
    width: 300px;
    font-size: 18px;
    background-color: #F0EFEF;
    text-align: center;
}

.logout-container-closed {
    display: block;
    position: absolute;
    bottom: 5px;
    width: 300px;
    font-size: 18px;
    visibility: visible;
    background-color: #F0EFEF;
    text-align: center;
}

.logout-button {
    width: 85%;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 1px;
    border: 0;
    height: 46px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #000000;
    font-family: "GucciSans-Bold";
    font-size: 18px;
    margin-top: 25px;
    box-shadow: 3px 3px black;
    color: yellow;
}

.logout-button:focus {
    box-shadow: none
}

.logout-button:hover {
    background-color: #96050e;
}

.logout-button-open {
    width: 85%;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 1px;
    border: 0;
    height: 46px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #000000;
    font-family: "GucciSans-Bold";
    font-size: 18px;
    margin-top: 25px;
    box-shadow: 3px 3px black;
}

.logout-button-open:focus {
    box-shadow: none
}

.logout-button-open:hover {
    background-color: #96050e;
}

/*GIM PAGE*/

.wellness_admin_filters_container {
    margin-left: auto;
}

.wellness_container {
    padding: 1px 5px;
}

.show_more {
    font-family: "GucciSans-Bold";
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
}

.course-type {
    margin-left: 20px;
}

.course-type-page {
    margin-left: 20px;
    margin-right: 20px;
    width: 95%;
}

.course_type_description {
    font-family: "GucciSans-Bold";
    margin-top: 15px;
}

.home_gym_title {
    font-family: "GucciSans-Bold";
    font-size: 43px;
    width: 94%;
    align-self: center;
}

.gym_container {
    background-color: #c8c8c847;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    padding-bottom: 20px;
    padding-top: 3px;
}

.gym_page_info_subtitle {
    font-family: "GucciSans-Bold";
    font-size: 25px;
    align-self: center;
    margin-top: 12px;
    width: 96%;
    text-align: left;
    margin-left: 25px;
    color: #000000;
    font-style: italic;
}

.people-icon {
    margin-top: 2px;
    margin-right: 5px;
}

.pilates {
    width: 30px;
    height: 30px;
    background-size: cover;
    margin-right: 5px;
    background-image: url("./resources/img/gym_1.png");
}

.item-center {
    align-items: center;
}

/*LEFTBAR*/

.welcome_string {
    text-align: center;
    /* margin: 5% 0; */
    margin-top: 30px;
    margin-bottom: 20px;
}

.leftbar {
    width: 300px;
    height: 100vh;
    z-index: 12;
    min-width: 300px;
}

.leftbar_closed {
    width: 300px;
    height: 100vh;
    z-index: 12;
    overflow: hidden;
    min-width: 300px;
    background-color: #e5e4e4;
}

.left_bar_menu_container {
    margin-bottom: 100px;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
}

.left_bar_menu_container_closed {
    margin-bottom: 100px;
    height: 70vh;
    overflow-x: hidden;
    margin-top: 10px;
}

.back_button_left_menu_container {
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.back_button_left_menu {
    font-family: "GucciSans-Bold";
}

.back_button_component {
    position: fixed;
    top: 11px;
    left: 10px;
}

.back_button_component_dx {
    position: relative;
    padding-left: 30px;
    padding-top: 20px;
    align-items: center;
    font-size: 18px;
    font-family: 'GucciSans-Bold';
    margin-bottom: -20px;
}

.back_button_component_dx_wellness {
    position: relative;
    margin-top: -20px;
    align-items: center;
    font-size: 18px;
    font-family: 'GucciSans-Bold';
    margin-bottom: -10px;
}

.back_button_component_ext {
    position: relative;
    padding-top: 7px;
    align-items: center;
    font-size: 14px;
    font-family: 'GucciSans-Bold';
    margin-left: 12px;
    display: flex !important;
    color: black;
    padding-bottom: 10px;
}

.back_button_component_dx_feed {
    position: relative;
    padding-left: 50px;
    padding-top: 0px;
    align-items: center;
    font-size: 18px;
    font-family: 'GucciSans-Bold';
    margin-bottom: 0px;
}

.back_button_component_dx_feed_detail {
    position: relative;
    padding-left: 60px;
    padding-top: 0px;
    align-items: center;
    font-size: 18px;
    font-family: 'GucciSans-Bold';
    margin-bottom: 0px;
}

.back_button_component_dx_feed_grouplist {
    position: relative;
    padding-left: 50px;
    padding-top: 0px;
    align-items: center;
    font-size: 18px;
    font-family: 'GucciSans-Bold';
    margin-bottom: 0px;
}



.sidemenu_cont {
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
    height: calc(100vh - 300px);
    align-content: start;
    position: fixed;
    top: 280px;
    width: 300px;
    padding: 8px;
}

.welcome_string_web {
    position: fixed;
    top: 110px;
    width: 300px;
}

.sidemenu_no_event {
    top: 140px !important;
    height: calc(100vh - 140px) !important;
}

.sidemenu_no_event_web {
    top: 102px !important;
    height: calc(100vh - 102px) !important;
}

.submenu_no_event {
    top: 102px !important;
}

.sidebar_web_background {

    background-color: white;
    border-radius: 20px;
    box-shadow: 0 5px 5px #bfbfbf;
}

.other_location_menu_cont {
    height: calc(100vh - 330px);
    overflow: auto;
    overflow-x: hidden;
    align-content: start;
    position: fixed;
    width: 300px;
    top: 300px;
    padding: 0 8px;
}

.other_location_menu_cont_no_event {
    height: calc(100vh - 110px) !important;
    overflow: auto;
    overflow-x: hidden;
    align-content: start;
    position: fixed;
    width: 300px;
    top: 110px !important;
    padding: 0 8px;
}

.left_carousel_arrow {
    position: absolute;
    z-index: 30;
    left: 5px;
    top: 35%;
}

.rigth_carousel_arrow {
    position: absolute;
    z-index: 30;
    right: 5px;
    top: 35%;
}

.carousel {

    margin-top: 20px;
    width: 100%;
}

/* NAVBAR */

.navbar_container {
    background-color: #000000;
    height: 60px;
    display: inline-flex;
    width: 100%;
    place-content: center;
    padding: 0 10px;
    position: fixed;
    left: 0;
    z-index: 201;
}

.navbar_gucci_logo {
    background-image: url("/src/resources/img/gucci-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    border-bottom-left-radius: 50px;
    height: 105px;
    width: 110px;
    min-width: 110px;
    margin-top: -25px;
    position: absolute;
}

.icon_navbar_container {
    margin-left: auto;
    margin-top: 15px;
}


/* LEFTBAR */

.carousel_cont {
    position: fixed;
    width: 300px;
    top: 100px;
}

.top_header {
    background-color: #000000;
    height: 75px;
    display: inline-flex;
    width: 100%;
}

.gucci_logo {
    background-image: url("/src/resources/img/gucci-logo.png");
    height: 74px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    border-bottom-left-radius: 50px;
    height: 112px;
    width: 272px;
    margin-left: 45px;
    margin-top: -22px;
}

.smart_office {
    font-family: "GucciSans-Bold";
    font-size: 16px;
    color: #ffffff;
    align-self: center;
    transform: scale(0.9, 1.4);
}

.user_info {
    display: table-cell;
    width: 100vw;
    height: 50px;
    margin: 7px;
}

.user_info_closed {
    display: table-cell;
    width: 100vw;
    height: 50px;
    margin: 7px;
}

.user_text {
    font-family: "GucciSans-Bold";
    width: 280px;
    font-size: 18px;
    margin-top: 6px;
    text-transform: uppercase;
    text-align: center;
}

.user_icon {
    width: 13%;
}

/*notification bar*/
.notification {
    /* height: 54px;
    padding: 6px;
    background: #4b000a;
    color: white;*/
    padding: 10px 6px;
    background: #4b000a;
    color: white;
}

.notificationBar_closed_one {
    display: none;
}

.notificationTitle {
    font-family: 'GucciSans-Bold';
    /*font-size: 16px;*/
    font-size: 12px;
    text-transform: uppercase;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 190px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0px !important;
}

#panel1a-header>div.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
    align-items: center;
}

.notificationText {
    font-family: 'GucciSans-Bold';
    font-size: 14px;
    text-transform: capitalize;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 190px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.notificationValue {
    font-size: 14px;
}

.notificationBar {
    /*
    right: 0;
    width: 0px;
    height: 100vh;
    background-color: #F0EFEF;
    z-index: 14;
    animation: openNotificationBar 0.6s forwards;
    border-right: 1px solid #8080801f;
    position: absolute;
    right: 120px;
    top: 65px;
    */
    height: 40vh;
    background-color: transparent;
    z-index: 14;
    width: 300px;
    overflow: hidden;
    max-width: 300px;
    margin-top: -7px;
}

.topNotificationIcon {
    position: relative;
}

.notificationBar_closed {
    position: absolute;
    height: 100vh;
    background-color: #F0EFEF;
    z-index: 11;
    animation: closeNotificationBar 1s forwards;
}

.notificationContent {
    animation: openNotificationBar 1s forwards;
    width: 300px;
    overflow: hidden;
    max-width: 300px;
    overflow: auto;
    height: 100vh;
}

.notificationContentClosed {
    animation: closeNotificationBar 1s forwards;
    width: 0px;
    overflow: hidden;
    max-width: 300px;
}

/* NOTIFICATION DD */

/* The container <div> - needed to position the dropdown content */

.dropdown {
    position: absolute;
    display: inline-block;
    right: 0px;
    top: 0px;
}

.dropbtn {
    width: 80px;
    height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 5;
}

.drop {
    object-position: center;
    background: #ffffff;
    padding: 2px 11px;
    height: 35px;
    box-shadow: 1px 6px 9px #acacac63;
    border-radius: 2px;
    min-width: 110px;
    place-content: center;
}

.drop:hover {
    background: #ebebeb;
    cursor: pointer;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-header {

    position: absolute;
    z-index: 14;
    font-family: 'GucciSans-Book' !important;
    /*margin-top: 20px;*/
    margin-top: -8px;
    width: auto;
    right: 0;

}

/* Links inside the dropdown */
.dropdown-content a {
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}

.hide {
    display: none;
}


@keyframes openNotificationBar {
    from {
        right: 0px;
    }

    to {
        right: 300px;
    }
}

@keyframes closeNotificationBar {
    from {
        right: 300px;
    }

    to {
        right: 0px;
    }
}

.notification_num {
    color: white;
    font-family: 'GucciSans-Bold';
    position: absolute;
    top: -12px;
    right: -3px;
    text-shadow: -1px 2px 3px black;
    z-index: 9;
}

.no_logo_menu {
    margin: 0% 0px 3% 0px;
    display: flex;
}

.no_logo_menu_container {
    width: 90%;
    margin: auto;
    text-align: -webkit-center;
    align-self: center;
    background-color: white;
    border: 0;
    min-height: 45px;
    border-radius: 40px;
    display: grid;
    padding-top: 8px;
    padding-bottom: 8px;
}

.no_logo_text_menu {
    font-family: 'GucciSans-Bold';
    font-size: 15px;
    color: #000;
    align-self: center;
    width: 98%;
}

.menu_row {
    display: inline-flex;
    min-height: 55px;
    width: 98%;
    /*margin: 2% 1%;*/
}

.menu_row1 {
    display: inline-flex;
    min-height: 40px;
    width: 255px;
    /*margin: 2% 1%;*/
}


.sub_menu_row {
    display: inline-flex;
    min-height: 45px;
    width: 98%;
    margin: 1% 1%;
}

.sub_menu_row_mobile {
    display: inline-flex;
    min-height: 65px;
    width: 350px;
    margin: 5px -1%;
    padding-left: 5px;
}

.submenu_row {
    width: 80%;
    display: inline-flex;
    max-height: 55px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 7px;
    margin-left: 30px;
}

.canteen_status {
    width: 30%;
    padding: 10px;
    background: #f6f6f6;
    color: black;
    border-radius: 20px;
}

.max_seats {
    width: 30%;
    background: #f6f6f6;
    color: rgb(0, 0, 0);
    margin-left: 20px;
    border-radius: 20px;
    align-content: center;
}

.max_seats>div {
    padding-top: 0;
}

.wellness_lab_menu {
    border-radius: 18px;
    cursor: pointer;
    color: white;
}

.ready_to_grow_menu {
    border-radius: 18px;
    cursor: pointer;
    color: white;
}

.audio_component_width {
    width: calc(100% - 120px);
    border: none;
    padding-top: 25px;
    padding-bottom: 25px;
}

.audio_feed_title {
    margin: 15px 15px;
    display: flex
}

.feed_menu {
    border-radius: 18px;
    cursor: pointer;
    color: white;
}

.feed_card {
    background: #f4f4f6;
    border-radius: 20px;
    min-height: 110px;
    margin: 10px 0px;
    display: flex;
    padding: 20px;
    min-height: 130;
}

.feed_thumb {
    width: 120px;
    height: 120px;
    border-radius: 20px;
}

.feed_content {
    width: calc(100% - 160px);
    text-align: left;
    font-size: 14px;
    font-family: 'GucciSans-Book';
    margin: 0px 20px;
}

.feed_title {
    font-family: 'GucciSans-Bold';
    font-size: 14px;
}

.feed_description {
    font-family: 'GucciSans-Book';
    font-size: 13px;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.feed_card_ico {
    align-self: center;
    padding: 5px;
}

.feed_viewed {
    display: flex;
    align-items: center;
    color: #8a8a8a;
    font-size: 13px;
}

.audio_player {
    box-shadow: none !important;
    width: calc(100% - 135px);
}

.audio_player_comp {
    box-shadow: none !important;
    width: calc(100% - 130px);
    padding: 0px 15px 15px 0px
}

.audio_date {
    margin-left: auto;
    font-size: 14px;
}

.audio_image {
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    margin: 15px 10px 15px 0px
}

.rhap_progress-filled,
.rhap_progress-indicator,
.rhap_volume-indicator {
    background-color: #000 !important;
}

.rhap_repeat-button {
    color: black !important;
}

.other_locations_region_button {
    background-color: white;
    padding: 10px 30px;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 12px;
    margin-bottom: 10px;
}

.other_locations_region_button_selected {
    background-color: black;
    color: white;
}

.other_locations_menu {
    border-radius: 18px;
    cursor: pointer;
    color: white;
}

.other_loc_accordions {
    box-shadow: unset !important;
    margin: 0px 2%;
}

.share2go_accordions{
    margin: 0 !important;
}

.other_loc_accordions {
    box-shadow: unset !important;
    margin: 0px 2%;
}

.other_loc_accordions_web {
    background-color: #fff !important;
    box-shadow: unset !important;
    border-radius: 20px !important;
    margin: 0px 2%;
}

div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root,
div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-hidden.css-bz4dnt-MuiCollapse-root {
    transition-duration: 0ms !important;
    transition: none !important;
}

.other_loc_accordion_web {
    width: 270px;
}

.other_loc_accordions::before {
    background-color: transparent !important;
}

.other_loc_accordions,
.accordion_details_container {
    border: none;
    min-height: 40px !important;
}

.css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root, .share_to_go_accordion  {
    transition-duration: 0s !important
}
/*
.accordion_details_container {
    background-color: #f5f5f7 !important;
}
*/
.accordion_details_container_web {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.MuiAccordionSummary-contentGutters {
    margin: 10px 0 !important;
    align-items: center;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0px 0;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
    display: none;
}

.offices_menu {
    border-radius: 18px;
    cursor: pointer;
    color: white;
    border-radius: 18px;
}

.offices_menu_web {
    border-radius: 0 !important;

}

.offices_menu_mobile {
    border-radius: 18px;
    cursor: pointer;
    color: white;
    border-radius: 18px;
}

.sub_offices_menu {
    border-radius: 8px;
    cursor: pointer;
    color: white;
    justify-content: right;
}

.business_travel_menu {
    cursor: pointer;
}

.wellness_lab_menu:hover {
    border-radius: 18px;
    cursor: pointer;
    color: white;
}

.offices_logo {
    padding-top: 2px;
    padding-bottom: 4px;
    border-radius: 50%;
    margin-top: 8px;
    height: 40px;
    width: 40px;
}

.offices_logo1 {
    padding-top: 2px;
    padding-bottom: 4px;
    border-radius: 50%;
    margin-top: 5px;
    height: 36px;
    width: 36px;
}

.logo_offices_icon {
    height: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}

.logo_offices_icon1 {
    height: 32px;
    width: 32px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    background-size: contain;
}


.logo_desk_booking_icon {
    background-image: url("./resources/img/desk_icon.png");
    height: 34px;
    background-size: contain;
    margin: 3%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;

    border-radius: 50%;
}

.logo_bookshelf_icon {
    background-image: url("./resources/img/bookshelf_icon.png");
    height: 34px;
    background-size: contain;
    margin: 3%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;

    border-radius: 50%;
}

.logo_menu {
    padding-left: 12px;
    height: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
    margin-left: 5px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;

}

.logo_menu_other_locations {
    padding-left: 12px;
    height: 65px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /*
    margin-left: 12px;
    */
}

.logo_menu_other_locations1 {
    padding-left: 12px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    /*
    margin-left: 12px;
    */
}

.submenu_arrow {
    color: #418588;
    margin-top: -50px;
    margin-left: 177px;
}

.text_submenu_container {
    width: 100%;
    align-self: center;
    margin-right: 0px;
    border: 0;
    height: 35px;
    text-align: center;
    padding-bottom: 1px;
    text-align: -webkit-center;
    border-bottom-left-radius: 13px;
    padding-left: 0px;
    border-top-left-radius: 13px;
}

.text_menu_container {
    width: 95%;
    align-self: center;
    padding-left: 13px;
    margin-right: 12px;
    background-color: white;
    border: 0;
    height: 55px;
    text-align: -webkit-left;
    /* */
    margin-right: 12px;
    padding-right: 12px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

}

.text_menu_container_other_locations {
    width: 95%;
    align-self: center;
    padding-left: 13px;
    background-color: white;
    border: 0;
    height: 55px;
    text-align: -webkit-center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /*
    margin-right: 12px;
    margin-right: 12px;
    padding-right: 12px;
    */

}


.text_menu_container_other_locations1 {
    width: 95%;
    align-self: center;
    background-color: white;
    border: 0;
    height: 45px;
    padding-left: 13px;
    text-align: -webkit-left;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    /*
    margin-right: 12px;
    margin-right: 12px;
    padding-right: 12px;
    */

}

.text_sub_menu_container {
    width: 98%;
    align-self: center;
    padding-left: 13px;
    margin-right: 1%;
    margin-left: 1%;
    background-color: white;
    border: 0;
    height: 45px;
    text-align: center;
    padding-right: 12px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    text-align: -webkit-center;
    border-radius: 50px;
}

.text_sub_menu_container_mobile {
    width: 98%;
    align-self: center;
    padding-left: 12px;
    margin-right: 1%;
    margin-left: 1%;
    background-color: white;
    border: 0;
    height: 65px;
    text-align: center;
    padding-right: 12px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    text-align: -webkit-center;
    border-radius: 50px;
    align-self: center;
    display: grid;
}

.text_menu {
    font-family: "GucciSans-Bold";
    font-size: 15px;
    color: black;
    border: 0;
    height: 55px;
    display: table-cell;
    vertical-align: middle;
    text-align: -webkit-left;
    max-width: 190px;
    overflow: hidden;
}

.text_menu1 {
    font-family: "GucciSans-Bold";
    font-size: 15px;
    color: black;
    border: 0;
    height: 45px;
    display: table-cell;
    vertical-align: middle;
    text-align: -webkit-left;
}

.text_sub_menu {
    font-family: "GucciSans-Bold";
    font-size: 15px;
    color: black;
    border: 0;
    height: 45px;
    display: table-cell;
    vertical-align: middle;
    text-align: -webkit-left;
}

.button_sub_menu {
    font-family: "GucciSans-Bold";
    font-size: 12px;
    color: black;
    border: 0;
    height: 30px;
    display: table-cell;
    vertical-align: middle;
    text-align: -webkit-left;
    box-shadow: 0 5px 10px #bfbfbf;
    padding: 0px 12px;
    border-radius: 20px;
    min-width: 200px;
    text-align: -webkit-center;
}

.text_sub_menu_sel>span {
    font-family: "GucciSans-Bold";
}

.text_sub_menu>span {
    font-family: "GucciSans-Bold";
}

.text_sub_menu_mobile {
    font-family: "GucciSans-Bold";
    font-size: 16px;
    color: black;
    border: 0;
    text-align: -webkit-center;
    align-self: center;
    width: 100%;
}

.text_sub_menu_sel {
    text-decoration: underline;
}

.subtext_menu {
    margin-right: 0px;
    width: 100%;
    color: black;
    background-color: white;
    border: 0;
    height: 35px;
    text-align: center;
    padding-top: 7px;
    padding-right: 4px;
    font-size: 15px;
    font-family: "GucciSans-Bold";
    border-bottom-right-radius: 2px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 22px;
    border-top-left-radius: 2px;
}

.other_loc_accordions.Mui-expanded {
    margin: 0 !important;
    min-height: 40px !important;
}

.logo_wellness_lab_icon {
    height: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}

.logo_ready_to_grow_icon {
    height: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}

.logo_feed_icon {
    height: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
}

.other_locations_icon {
    background-image: url("./resources/img/other_locations.png");
    height: 36px;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

/* modifica schermata login */
.container_reset_password {
    margin-top: 5%;
}

.business_travel_center,
.offices_menu_center {
    width: 98%;
}

.business_travel_center::-webkit-scrollbar-track,
.offices_menu_center::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 20px;
    margin-bottom: 20px;
}

.business_travel_center::-webkit-scrollbar,
.offices_menu_center::-webkit-scrollbar {
    width: 7px;
}

.business_travel_center::-webkit-scrollbar-thumb,
.offices_menu_center::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid #000;
}

.logo_preview_container {
    width: 100%;
    text-align: center;
    position: relative;
    height: calc(100vh - 275px);
}

.logo_preview {
    width: 300px;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: calc(50% + 40px);
    right: 50%;
    transform: translate(50%, -50%);
    background-image: url('./resources/img/gucci-logo.png');
    filter: invert(1);
    opacity: 0.5;
}



.text_preview {
    font-family: 'GucciSans-Bold';
    width: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: calc(35% - 160px);
    left: 10%;
    opacity: 0.5;
    text-align: -webkit-center;
}

.center_menu_s {
    height: 95vh;
    width: 40%;
    border-radius: 10px;
    margin: 19px 6px 19px 12px;
    z-index: 2;
    overflow-y: auto;
}

.center_menu_s_close {
    height: 95vh;
    width: 40%;
    border-radius: 53px;
    margin: 19px 6px 19px 12px;
    background-color: #F0EFEF;
    z-index: 2;
}

.right_menu {
    width: 60%;
    border-radius: 10px;
    margin: 20px 20px 4px 9px;
    height: 95vh;
    z-index: 1;
    overflow-y: hidden;
}

.single_module_container {
    margin: 0 0 4px 9px;
    /* height: 95vh; */
    z-index: 1;
    overflow-y: hidden;
    max-width: 1066px;
    width: 100%;
}

.ready_to_grow_sm_container {
    padding-top: 30px;
}

.right_menu_col {
    background-color: #F0EFEF;
}

.center_menu_l {
    height: 95vh;
    width: 98%;
    border-radius: 53px;
    margin: 19px 6px 19px 12px;
    background-color: #F0EFEF;
    z-index: 2;
    text-align: -webkit-center;
}

.subtitle_home {
    font-family: "GucciSans-Book";
    color: #000000;
    font-size: 16px;
    text-align: -webkit-center;
    margin-bottom: 15px;
    margin-top: 10px;
    padding: 0 10px;
}

.subtitle_home_well {
    font-family: "GucciSans-Book";
    color: #000000;
    font-size: 16px;
    text-align: -webkit-center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.wellness_goto_button_close {
    color: #000 !important;
    font-family: "GucciSans-Bold" !important;
    text-transform: capitalize !important;
    background-color: #F0EFEF !important;
    border-radius: 25px !important;
}

.wellness_goto_button_confirm {
    color: #fff !important;
    font-family: "GucciSans-Bold" !important;
    text-transform: capitalize !important;
    background-color: #000 !important;
    border-radius: 25px !important;
}

.join_text {
    font-family: "GucciSans-Bold";
    color: #000000;
    font-size: 23px;
    text-align: -webkit-center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.wellness_central_logo {
    background-image: url("./resources/img/applogo_copy.png");
    width: 80%;
    height: 35vh;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1.5em;
    background-position: center;
    margin-bottom: 10px
}

.wellness_central_logo_container {
    width: 100%;
    text-align: -webkit-center;
}

.mobile_go_next {
    display: none;
}

.home_text_container_left {
    color: #000000;
    font-size: 30px;
    text-align: -webkit-center;
    font-family: "GucciSans-Bold";
}

.home_text_welcome {
    text-transform: uppercase;
    font-family: 'GucciSans-Book';
    font-size: 22px;
    margin: 10px 13px;
}

.home_title_welcome {
    text-transform: uppercase;
    font-size: 21px;
}

.home_text_container_right {
    color: #000000;
    font-size: 27px;
    text-align: -webkit-center;
    font-family: "GucciSans-Bold";
}

.doc_download_ico {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 2px;
}

.doc_buttons_container {
    width: 100%;
    text-align: -webkit-center;
}

.doc_button {
    width: 60%;
    height: 45px;
    background-color: #000000;
    border-radius: 50px;
    text-align: center;
    padding-top: 15px;
    color: #fff;
    line-height: 0.9rem;
    font-family: "GucciSans-Bold";
    margin-bottom: 5px;
    box-shadow: rgb(28 27 27 / 20%) 0px -1px 12px;
    cursor: pointer;
    text-decoration: none;
}

.link_button {
    text-decoration: none;
}

.info_container {
    width: 100%;
    text-align: -webkit-center;
}

.info_manage_container {
    width: 80%;
}

.info_manage {
    width: 70%;
    padding: 0 10px;
}

.row_info_title {
    width: 100%;
    padding-bottom: 15px;
}

.progress_button {
    width: 43px;
    height: 43px;
    border-radius: 50px;
    background-color: #000000;
    text-align: -webkit-center;
    padding-top: 7px;
    font-size: 20px;
    font-family: "GucciSans-Bold";
    color: white;
    margin-top: 20px;
}

.menu_selected {
    font-size: 16px;
    text-decoration-line: underline;
}

.burger_menu {
    display: none;
}

.user_icon_top {
    align-self: center;
    margin-right: 5px;
    width: 36px;
    height: 40px;
    cursor: pointer;
    margin-top: 10px;
}

.user_icon_top_disabled {
    display: none;
}

.CalendarFooterThin {
    display: none;
}

/*LOCATION SETTING */
.setting_container {
    margin-top: 16%;
    width: 750px;
    text-align: -webkit-center;
}

.welcome_text {
    font-family: 'GucciSans-Bold';
    font-size: 20px;
}

/*MAP */
/*.map_container {
    display: flex;
    height: 96vh;
    width: 100%;
    margin-top: 11px;
}
*/
.grid {
    display: grid;
}

.content {
    display: contents;
}

.map_container {
    display: flex;
    height: 96vh;
    width: 100%;
    margin-top: 11px;
    padding-top: 40px;
}

.map_style {
    color: black;
    width: 900px !important;
    height: 75vh !important;
    position: absolute;
    justify-self: center;
    z-index: 0;
    border: 1px solid #F0EFEF;
    box-shadow: 3px 2px #cfcdcd;
    border-radius: 24px;
    overflow: hidden;
    margin: 80px 33px 0px 33px
}

.go_to_button {
    height: 40px;
    display: flex;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 15px;
    margin-left: 5px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
    cursor: pointer;
    color: white;
    justify-content: center;
}

.go_to_button_icon {
    background-image: url('./resources/img/directions1.png');
    width: 23px;
    height: 23px;
    margin-left: 5px;
    margin-top: 1px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

/*

.map_search {
    width: calc(92% - 300px);
    height: 50px;
    display: grid;
    z-index: 1;
    margin-left: 8px;
    position: absolute;
    margin-top: 8px;
}

.map_search_results {
    width: calc(94.6% - 300px);
    position: absolute;
    top: 50px;
    height: 200px;
    margin-left: 10px;
    margin-top: 8px;
}
*/
.loading {
    position: absolute;
    width: 90%;
    height: 100%;
    z-index: 8;
    background: #ede8e8a6;
    top: 60px;
}

.gucci_text {
    align-self: center;
    font-size: 21px;
    margin-top: 11px;
    margin-left: 3px;
    color: black;
}

.loader {
    width: 48px;
    height: 48px;
    display: block;
    margin: 20px auto;
    box-sizing: border-box;
    position: absolute;
    top: 40%;
    left: 47%;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    left: 0;
    bottom: 0;
    position: absolute;
    border-radius: 50% 50% 0;
    border: 15px solid #000000;
    transform: rotate(45deg) translate(0, 0);
    box-sizing: border-box;
    animation: animMarker 0.4s ease-in-out infinite alternate;
}

.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 150%;
    width: 24px;
    height: 4px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    animation: animShadow 0.4s ease-in-out infinite alternate;
}

@keyframes animMarker {
    0% {
        transform: rotate(45deg) translate(5px, 5px);
    }

    100% {
        transform: rotate(45deg) translate(-5px, -5px);
    }
}

@keyframes animShadow {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

.map_info_container {
    width: 100%;
    padding-left: 11px;
}

.directions_container {
    height: 160px;
    background-color: #F0EFEF;
    padding: 2px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.direction_header {
    display: flex;
    width: 100%;
    height: 37px;
    margin-left: 10px;
}

.direction_header_mobile {
    height: 57px !important;
}

.walk {
    bottom: 28px;
    width: 38px;
    height: 42px;
    left: 395px;
    cursor: pointer;
    padding: 5px;
}

.travel_selected {
    background-color: #000000b5;
    border: 1px solid;
    border-radius: 50%;
}

.car {
    bottom: 30px;
    width: 40px;
    height: 37px;
    left: 443px;
    cursor: pointer;
    padding: 5px;
}

.bike {
    bottom: 25px;
    width: 45px;
    height: 45px;
    left: 497px;
    cursor: pointer;
    transform: scaleX(-1);
    padding: 5px;
}

.train {
    bottom: 27px;
    width: 45px;
    height: 44px;
    left: 552px;
    cursor: pointer;
    padding: 5px;
}

.nav_items_container {
    display: grid;
    justify-items: center;
    margin-top: 20px;
}

.menu_item {
    width: 290px;
    display: inline-flex;
    min-height: 65px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 14px;
}

/*

.map_info_name {
    color: #000000;
    font-family: "GucciSans-Bold";
    padding: 10px 0px 10px 0px;
    font-size: 20px;
    margin-left: -6px;
    align-items: center;
}

.map_info_description {
    display: flex;
    font-family: "GucciSans-Book";
    margin-top: 10px;
    font-size: 15px;
}

.map_info_icon {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-right: 25px;
    background: rgb(141 152 170);
    font-family: 'GucciSans-Bold';
}

.map_description_icon {
    margin-right: 25px;
}

.map_building_info {
    padding-left: 7px;
}

.map_building_icon {
    margin-right: 17px;
    align-self: center;
}

.go_to_container {
    float: right;
    margin-top: 12px;
}

.go_back_container {
    margin-top: 12px;
}

.go_back {
    height: 40px;
    display: flex;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Bold';
    margin-right: 15px;
    margin-left: 5px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
    cursor: pointer;
    color: #878787;
    place-items: center;
    font-size: 18px;
}

.go_to_button {
    height: 40px;
    display: flex;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 15px;
    margin-left: 5px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
    cursor: pointer;
    color: white;

}

.go_to_button_icon {
    background-image: url('./resources/img/directions1.png');
    width: 23px;
    height: 23px;
    margin-left: 5px;
    margin-top: 1px;
    margin-right: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.go_to_arrow {
    -moz-transform: rotate(-40deg) skew(-3deg, 0deg);
    -webkit-transform: rotate(-40deg) skew(-3deg, 0deg);
    -ms-transform: rotate(-40deg) skew(-3deg, 0deg);
    -o-transform: rotate(-40deg) skew(-3deg, 0deg);
    transform: rotate(-40deg) skew(-3deg, 0deg);
    margin-top: -11px;
}

.back_button_map {
    display: none;
}

.details-title {
    font-family: 'GucciSans-Bold' !important;
}


.step_bar {
    width: 300px;
    background: #F0EFEF;
    margin-top: -11px;
}

.step_bar_mobile {
    display: none;

}

.directions_container {
    height: 160px;
    background-color: #F0EFEF;
    padding: 2px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.direction_header {
    display: flex;
    width: 100%;
    height: 37px;
    margin-left: 10px;
}

.direction_header_mobile {
    height: 57px !important;
}

.directions_search {
    text-align: -webkit-center;
    margin-top: 15px;
}

.back_button {
    width: 15%;
}

.travel_mode_container {
    width: 85%;
    margin-left: 5px;
    text-align: -webkit-center;
}

.steps_duration {
    color: gray;
    text-align: -webkit-center;
    margin-top: 7px;
    margin-left: 12px;
    margin-bottom: 10px;
}

.step_container {
    text-align: -webkit-center;
    padding-bottom: 5px;
    cursor: pointer;
}

.step_container_selected {
    background-color: #ffffff;
    border-radius: 12px;
    cursor: pointer;
}

.step_title {
    font-family: 'GucciSans-Bold';
    padding: 7px;
    text-align: -webkit-center;
}

.step_summary {
    width: 70%;
    text-align: -webkit-center;
}

hr {
    margin: 0 0 !important
}

.expand_icon {
    align-self: center;
    color: #000000;
}

.step_containers {
    height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
}

.step_containers_mobile {
    display: none;
}

.step_row {
    padding-top: 2px;
    padding-bottom: 2px;
}

.step_icon {
    width: 80px;
    align-self: center;
    text-align: -webkit-right;
    padding-right: 12px;
}

.step_details {
    width: 220px;
    padding-left: 16px;
}

.step_text {
    font-size: 13px;
}

.step_distance {
    padding-left: 35px;
    font-size: 13px;
}

.change_buttons {
    display: flex;
    width: 300px;
    margin-top: 5px;
    margin-bottom: 16px;
}

.prev_button {
    height: 40px;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-left: 15px;
    margin-right: 5px;
    padding-top: 7px;
    padding-left: 7px;
    cursor: pointer;
    color: white
}

.prev_button_disabled {
    height: 40px;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-left: 15px;
    margin-right: 5px;
    padding-top: 7px;
    padding-left: 7px;
}

.next_button {
    height: 40px;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 15px;
    margin-left: 5px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
    cursor: pointer;
    color: white
}

.next_button_disabled {
    height: 40px;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 15px;
    margin-left: 5px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
}

.address_container {
    height: 80vh !important;
    background: none;
}

.address_cont {
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
}

.pm_info_container_disabled {
    display: block;
}

.pm_info_container {
    display: block;
    padding: 0% 3%;
}

.util_info_cont {
    margin-top: 40px;
}

.details-title {
    font-family: "GucciSans-Bold" !important;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.direction_button {
    display: none;
}

.return_button {
    display: none;
}

#box_description {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 5px !important;
    background: white;
    top: 83px;
    left: 345px;
    z-index: 10000;
    font-size: 12px;
    display: none;
    border-radius: 6px;
    color: #000000;
    text-align: -webkit-center;
    border: 1px solid #e0ded9;
    box-shadow: 2px 2px #cfcdcd;
    min-width: 160px;
    min-height: 77px;
}

#box_text {
    color: #000000;
    font-family: "GucciSans-Bold";
    text-shadow: white;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
}

#box_text_description {
    text-transform: lowercase;
    color: black;
    font-family: "GucciSans-Book";
    padding: 1px;
}

.box_img {
    width: 30px;
    height: 30px;
    background-image: url('./resources/img/directions.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    cursor: pointer;
}
*/

#my_canva_container {
    padding: 36px 20px 20px 20px;
}

.map_info_container_mobile {
    width: 100%;
    margin-top: 1px;
}

.map_info_container_mob {
    width: 100%;
}

.map_info_icon_mobile {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-right: 25px;
    background: rgb(141 152 170);
    font-family: 'GucciSans-Bold';
}

.map_info_name_mobile {
    color: #000000;
    font-family: "GucciSans-Bold";
    padding: 16px 0px 0px 2px;
    font-size: 22px;
    width: 100%;
    margin-top: -49px;
    text-align: -webkit-center;
}

.map_info_description {
    place-content: center;
    margin-top: 11px;
    min-height: 20px;
}

.map_building_info {
    align-self: center;
}

.go_to_container_mobile {
    margin-top: 11px;
    width: 95%;
    text-align: -webkit-right;
}

#box_description {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 5px !important;
    background: white;
    top: 88px;
    left: 11px;
    z-index: 10000;
    font-size: 12px;
    display: none;
    border-radius: 6px;
    color: #000000;
    text-align: -webkit-center;
    border: 1px solid #e0ded9;
    box-shadow: 2px 2px #cfcdcd;
    min-width: 160px;
    min-height: 77px;
}

.map_search {
    display: none;
}

.back_button_map {
    width: 10%;
    padding-left: 5px;
    display: block;
}

.map_search_results {
    width: 100%;
    position: relative;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.map_search_mobile {
    width: 100%;
    height: 50px;
    display: flex;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 8px;
    justify-content: center;
}

#search_component {
    justify-self: left !important;
}

.full_screen {
    animation: fullscreen 0.6s forwards !important;
}

.hide_screen {
    animation: hidescreen 0.6s forwards !important;
}

@keyframes fullscreen {
    0% {
        height: 30vh
    }

    100% {
        height: 86vh
    }
}

@keyframes hidescreen {
    0% {
        height: 30vh
    }

    100% {
        height: 47px
    }
}

@keyframes open_up {
    0% {
        height: 0px
    }

    100% {
        height: 200px
    }
}

.screen_settings {
    margin-top: 4px;
}

.directions_container_disabled {
    visibility: hidden;
}

.directions_container_mobile_disabled {
    display: none;
}

.step_title {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: 2px;
}

.step_row_mobile {
    padding-top: 2px;
    padding-bottom: 2px;
    place-content: center;
}

.step_container_selected_mobile {
    background-color: #F0EFEF;
    cursor: pointer;
    text-align: -webkit-center;
    /*border-radius: 20px;*/
}

.step_container_mobile {
    text-align: -webkit-center;
    padding-bottom: 5px;
    cursor: pointer;
}

.expand_icon_mobile {
    align-self: center;
    color: #000000;
    width: 5%;
}

.step_summary_mobile {
    width: 90%;
    text-align: -webkit-center;
}

.step_bar {
    display: none;
}

.step_bar_mobile {
    display: none;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
}

@keyframes goUp {
    0% {
        height: 0;
    }

    100% {
        height: 200px;
    }
}

.step_bar_mobile_up {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 900px;
    height: 200px;
    animation: goUp 1s;
    margin-left: 31px;
}

.directions_search_mobile {
    text-align: left;
    text-align: -webkit-center;
    margin-top: 13px;
}

/*
.direction_button {
    height: 40px;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 14px;
    margin-left: 14px;
    padding-left: 6px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
    cursor: pointer;
    position: absolute;
    bottom: 17px;
    right: 18px;
    display: flex;
    z-index: 1;
}
*/
.return_button {
    height: 40px;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 14px;
    margin-left: 14px;
    padding-left: 6px;
    padding-top: 7px;
    padding-right: 9px;
    text-align: -webkit-right;
    cursor: pointer;
    position: absolute;
    bottom: 17px;
    left: 18px;
    display: flex;
    z-index: 1;
}

.direction_button {
    display: none;
}

.direction_button_text {
    font-family: "GucciSans-Bold";
    color: white;
}

#directions_img {
    width: 24px;
    margin-left: 11px;
    height: 30px;
    background-image: url('./resources/img/directions1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    cursor: pointer;

}

.steps_duration {
    margin-top: 11px;
}

.steps_duration_mobile {
    margin-top: 2px;
    width: 85%;
    font-size: 14px;
    text-align: -webkit-center;
}

.step_containers_mobile {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    /*width: 1044px;*/
    width: inherit;
    background: #fbfbfb;
    bottom: 0px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    animation: open_up 0.6s forwards;

}

.steps_mobile {
    margin-top: 48px;
    margin-bottom: 20px;
}

.change_buttons_mobile {
    display: flex;
    /*width: 24%;*/
    width: fit-content;
    margin-top: 3px;
    margin-bottom: 16px;
    margin-right: 3px;
}

.next_button_mobile {
    height: 35px;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-right: 10px;
    margin-left: 5px;
    padding-top: 6px;
    padding-right: 5px;
    text-align: -webkit-right;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: right;
    color: white;
}

.prev_button_mobile {
    height: 35px;
    background-color: #000000;
    border-radius: 8px;
    width: 130px;
    font-family: 'GucciSans-Book';
    margin-left: 15px;
    margin-right: 5px;
    padding-top: 6px;
    padding-left: 0px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    color: white;
}

.prev_text_mobile {
    padding-top: 2px;
}

.next_text_mobile {
    padding-top: 2px;
}

.direction_header_mobile {
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    margin-left: 10px;
}

.directions_container_mobile {
    height: 48px;
    background-color: #f1f1f1;
    padding: 2px;
    display: flex;
    position: fixed;
    /* width: 1044px;*/
    width: inherit;
    z-index: 1;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border: 1px solid #cbc6c6;
}

.directions_container_start {
    height: 200px;
    background-color: #F0EFEF;
    padding: 2px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1px solid darkgrey;
}

.back_mobile_button {
    width: 18%;
    text-align: -webkit-left;
    margin-left: 15px;
}

.next_mobile_button {
    width: 80%;
    text-align: -webkit-right;
    padding-right: 13px;
    margin-top: 13px;
}

.direction_header_mob {
    display: flex;
    width: 100%;
    height: 38px;
}


.wellness_lab_center_menu {
    height: 95vh;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

/*INFO UTILI PALAZZO MANCINI*/

.pm_info_global_container {
    display: block;
    overflow: auto;
    height: 85vh;
    margin-top: 5%;
    width: 98%;
}

.pm_info_global_container::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.pm_info_street_address {
    display: grid;
    justify-items: center;
    overflow: auto;
}

.pm_info_submenu {
    padding: 5px;
    padding-left: 40px;
    min-width: calc(100% - 60px);
    margin: 15px 29px;
}

.pm_info_value {
    width: 50%;
}

.pm_info_row_timetable {
    display: flex;
}

.pm_info_bus_table {
    margin-left: 40px;
    margin-right: 40px;
    clear: left;
    width: -webkit-fill-available;
}

.pm_info_bus_table_th {
    font-family: "GucciSans-Book";
    font-weight: normal;
    text-align: center;
}

.pm_info_bus_table_main_td {
    font-family: "GucciSans-Bold";
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.pm_info_bus_table_td {
    /* border-bottom: 0.5px solid rgba(0, 0, 0, .2);*/
}

.pm_info_button {
    background-color: #000;
    width: max-content;
    padding: 5px;
    border-radius: 10px;
    margin: 5px 15px 5px -100px
}

.menu_button_canteen {
    background-color: #f6f6f6;
    width: 80%;
    padding: 9px;
    border-radius: 9px;
    align-items: center;
    place-content: center;
    text-decoration: underline;
}

.menu_button_canteen_web {
    width: 80%;
    padding: 9px;
    border-radius: 9px;
    align-items: center;
    place-content: center;
    text-decoration: underline;
}

.pm_restaurant_link {
    font-family: "GucciSans-Bold";
    font-style: normal;
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.pm_restaurant_link:hover {
    font-family: "GucciSans-Bold";
    font-style: normal;
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.pm_bus_rides {
    width: 100%;
    background: #dfdede;
    border-radius: 20px;
    margin: 2% 4%;
}

.contacts_web_container {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 88%;
}

.info_box_web {
    height: 260px;
    max-width: 270px;
    width: 270px;
    border-radius: 20px;
    background-color: #f5f5f7;
    margin: 0 16px;
    padding: 10px 20px;
}

.info_box_web_col1 {
    min-width: 90px;
    text-align: -webkit-left;
}

.info_box_web_col2 {
    min-width: 135px;
    text-align: -webkit-left;
}

.info_box_web_text {
    font-size: 14px;
    margin-top: 25px;
}

.bus_rides_web {
    height: auto;
    width: 88%;
    border-radius: 20px;
    background-color: #f5f5f7;
    margin: 0 16px;
    padding: 10px 20px;
    margin-bottom: 30px;
}

.bus_rides_buttons {
    margin-top: 10px;
    background-color: #ededed;
    width: 500px;
    height: 25px;
    border-radius: 50px;
}

.direction_button_web {
    width: 50%;
    font-size: 14px;
    margin-top: 2px;
}

.direction_button_web_selected {
    background-color: #fff;
    border-radius: 50px;
    margin-top: 2px;
    box-shadow: 0 1px 5px #cfcfcf;
}

/* WORKPLACE PALAZZO MANCINI */

.gh_changemakers_global_container {
    display: block;
    overflow: auto;
    height: 84vh;
    width: 98%;
    justify-items: center;
    margin-top: 20px;
    padding: 5%;
    text-align: justify;
}

.gh_changemakers_global_container::-webkit-scrollbar-thumb {
    background: #000000 !important;
}

.generic_menu_global_container {
    display: block;
    overflow: auto;
    height: 90vh;
    width: 98%;
    justify-items: center;
    margin-top: 20px;
    padding: 5%;
    text-align: justify;
}

.generic_menu_global_title_container {
    text-align: -webkit-center;
    margin-bottom: 10px;
    margin-top: -20px;
}

.generic_menu_global_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c6c6c6;
    border-radius: 10px;
    z-index: 999;
}

.arrow {
    float: left;
    margin-left: 10px;
    margin-top: -50px;
}

.today_info_container {
    background: white;
    border-radius: 20px;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
}

.info_utili_title_cont {
    align-self: center;
    padding: 10px;
    width: 100%;
    min-width: 150px;
    text-align: -webkit-center;
}

.info_service_today {

    margin: 5px;

}

.info_service_name {
    font-family: 'GucciSans-Book';
    margin: 0px 0px 0px 8%;
    font-size: 16px;
    width: calc(50% - 40px);
    place-self: center;
    align-items: center;
}

.info_service_time {
    font-family: 'GucciSans-Bold';
    margin: 4px 1%;
    font-size: 15px;
    width: calc(50% - 40px);
    text-align: -webkit-right;
    place-self: center;
    text-align: -webkit-center;
}

/*INFO UTILI GUCCI HUB*/

.location_info_container {
    display: flex;
    /*overflow: auto;
    height: calc(100vh - 120px);*/
    flex-wrap: wrap;
    overflow-x: hidden;
    max-width: calc(1366px - 300px);
    margin-right: auto;
}

span>a,
div.street_address_useful_info>a,
div.homepage_info.book>a {
    cursor: pointer;
    color: #000 !important;
}

.homepage_info {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 23px;
}

/*CANTEEN*/

.canteen_global_container {
    display: block;
    width: 98%;
}

.canteen_container {
    overflow-y: auto;
    width: 100%;
    max-width: 1066px;
    margin-right: auto;
    padding-top: 20px;
}

.canteen_container::-webkit-scrollbar-thumb {
    background: #000000;
}

.canteen_subtitle_1 {
    font-family: "GucciSans-Bold";
    font-size: 12px;
    margin-top: 4%;
    text-align: center;
    color: #414442
}

.canteen_subtitle_2 {
    font-size: 12px;
    text-align: -webkit-center;
    color: #414442
}

#canteen_nodata_message {
    font-size: 15px;
    margin-top: 15%;
    text-align: -webkit-center;
    opacity: 0.8;
}

.main_baloon_container {
    display: flex;
    /*text-align: center;*/
    margin-top: 3%;
    place-content: center;
}

.react-datepicker-wrapper {
    margin: auto;
}

.canteen_datepicker {
    background-color: black;
    text-align: center;
    color: white;
    width: 200px !important;
    border: 0;
    border-radius: 20px;
    cursor: pointer;

}

.canteen_date_tag {
    background-color: black;
    text-align: center;
    color: white;
    margin: auto;
    width: 200px;
    padding: 0 20px 0 20px;
    border-radius: 15px;
    cursor: pointer;
}

.canteen_graph {
    width: 80%;
    min-height: 280px;
    margin: 3% auto auto auto;
    padding: 10px;
    background-color: #f6f6f6;
    border-radius: 9px;
    position: center;
}

.canteen_dates_container {
    margin-left: 10px;
}

#container_main_logo {
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
}

.logo_restaurant {
    background-image: url("./resources/img/restaurant-icon.png");
    background-color: black;
    height: 20px;
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    margin-right: 5px;
}

.logo_restaurant_canteen {
    background-image: url("./resources/img/restaurant-icon.png");
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    margin-right: 5px;
    filter: invert(1);
}

.logo_restaurant_canteen_web {
    background-image: url("./resources/img/restaurant-icon.png");
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(1);
}

.menu_text {
    font-family: 'GucciSans-Bold';
    color: rgb(0, 0, 0);
    margin-left: 10px;
    font-size: 22px;
    padding-left: 20px;
}

.menu_text_web {
    font-family: 'GucciSans-Bold';
    color: rgb(0, 0, 0);
    font-size: 22px;
}

.logo_canteen_green {
    background-image: url("./resources/img/img_canteenoccupancy_green.png");
    background-color: #40B250;
    float: left;
    height: 65px;
    width: 65px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
}

.canteen_open_title {
    font-family: "GucciSans-Bold";
    font-size: 16px;
}

.canteen_open_subtitle {
    font-size: 16px;
}

.logo_canteen_yellow {
    background-image: url("./resources/img/img_canteenoccupancy_yellow.png");
    background-color: #F0BA49;
    float: left;
    height: 65px;
    width: 65px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    margin-right: 10px;
}

.logo_canteen_red {
    background-image: url("./resources/img/img_canteenoccupancy_red.png");
    background-color: #C4000D;
    float: left;
    height: 65px;
    width: 65px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    margin-right: 10px;
}

.back_button_right_menu_mobile {
    display: none;
}

.back_mobile_arrow {
    padding-left: 20px;
    position: absolute;
    top: 13px;
    width: 50px;
    left: 0;
    cursor: pointer;
}

.office_page_title,
.office_page_title>span {
    font-family: "GucciSans-Bold";
    font-size: 15px;
}

.office_page_subtitle,
.office_page_subtitle>span {
    font-family: "GucciSans-Book";
    font-size: 13px;
}

.custom_page_container {
    /*
    margin-top: 40px;
    height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
    */
    padding: 20px;
    width: 100%;
    justify-content: center;
}

/* WELLNESSLAB CALENDAR */

.arrows_container {
    display: flex;
    height: 30px;
    margin-top: 3px;
}

.arrow_button {
    background: none;
    border: none;
    height: 30px;
    display: flex;
    align-items: center;
}


.calendar_arrow1:hover {
    margin-top: 5px;
}

.calendar_arrow {
    color: #212529;
    height: 23px;
    margin-top: -5px;
}

.calendar_arrow:hover {
    height: 28px;
    margin-top: -2px;
}

.calendar_view {
    height: 30px;
    padding: 4px 5px;
    font-family: 'GucciSans-Book';
    font-size: 15px;
    cursor: default;
}

.day_card {
    /*background-color: #fbfbfb;
    height: 78vh;
    */
    width: 305px;
    margin: 0px 10px;
    border-radius: 10px;
    padding: 10px;
    min-width: 300px;
    height: calc(100vh - 200px);
}

.today_card {
    /* background-color: #dddddd; */
    height: calc(100vh - 235px);
    width: 305px;
    margin: 0px 10px;
    border-radius: 20px;
    padding: 10px;
    /* border: solid 2px #000000; */
    min-width: 300px;
    border: 1px solid #c5c5c5;
}

.background_card_manager {
    background-color: #fbfbfb;
    border-radius: 10px;
    padding: 10px;
    height: 80vh;
}

.card_day_container {
    height: calc(100vh - 295px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 20px;
}

.card_day_container::-webkit-scrollbar {
    width: 8px;
}


.card_days_container {
    margin-top: 30px;
}

.card_days_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    width: 100%;
    height: calc(100vh - 220px);
    white-space: nowrap;
}

.day_title {
    font-family: "GucciSans-Bold";
    text-align: -webkit-center;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.day_title_manage {
    font-family: "GucciSans-Bold";
    margin-bottom: 10px;
    text-transform: uppercase;
}

.my_day_service_course {
    background-color: #e7e7e7;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
}

.my_day_course {
    background-color: #f5f5f7;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
}

.day_course {
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
}

.day_course_selected {
    background-color: #fafafa;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    border: solid 2px #000000
}

.day_course_name {
    font-family: "GucciSans-Bold";
    font-size: 15px;
}

.day_course_trainer {
    font-family: "GucciSans-Book";
    font-size: 14px;
}

.day_course_hour {
    font-family: "GucciSans-Book";
    font-size: 13px;
}

.day_course_gym {
    font-family: "GucciSans-Book";
    font-size: 12px;
}

.map_row {
    padding: 2%;
    height: 425px;
    width: 100%;
    display: flex;

}

.info_row {
    padding: 0;
    width: 50%;
    /*display: grid;*/
}

.info_utili_row {
    background-size: cover;
    background-position: left center;
    border-radius: 31px;
    height: auto;
    padding: 0 13px 13px 13px;
}

.empty_row {
    background-color: transparent
}

.info_utili_title {
    font-size: 20px;
    font-family: 'GucciSans-Bold';
    text-align: -webkit-center;
    color: #131313;
    text-shadow: 0 0 2px white;
}

.info_utili_s {
    font-size: 20px;
    font-family: 'GucciSans-Bold';
    text-align: -webkit-center;
    color: #131313;
    text-shadow: 0 0 2px white;
}

.calendar_date,
.calendar_date_selected {
    padding: 4px 12px;
}

.calendar_date_selected {
    color: #000;
    font-size: 18px;
}

.calendar_date {
    color: hsla(0, 0%, 50.2%, .5686274509803921);
    font-size: 17px;
    cursor: pointer;
}

.timetable {
    width: 100%;
    background-color: #cdcdcd40;
    padding: 4%;
    border-radius: 20px;
    max-width: 400px;
    font-size: 13px;
    height: auto;
    margin-bottom: 20px;
}

.extra_info_row {
    max-width: 400px;
    text-align: -webkit-center;
}

.extra_info_button {
    padding: 5px;
    border-radius: 10px;
    margin-top: 5px;
    font-size: 13px;
    font-style: italic;
    text-decoration: underline;
}

.extra_info_string {
    padding: 5px;
    border-radius: 10px;
    margin-top: 5px;
    font-size: 13px;
    font-style: italic;
}

#map {
    height: 400px;
    width: 100%;
}

/* NAVBAR */

.sub_menu_mobile {
    display: none;
}

.right_menu_mobile_dis {
    display: block;
}

.event_menu_bottom {
    z-index: 3;
    margin-top: 29%;
    position: relative;
    /*height: calc(95vh - 38vh) !important*/
    height: calc(95vh - 30vh) !important
}

.today_left_container {
    overflow: auto;
    height: inherit;
    /* padding-bottom: 20px;*/
    padding-bottom: 60px;
}

/* BOOKSHELF */

.bookshelf_detail_container {
    text-align: -webkit-left;
}


.bookshelf_search .bookshelf_search_sm {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin: 15px 0;
}

.search_button_bookshelf {
    height: 40px;
    border-radius: 4px;
    padding: 5px;
    display: grid;
    place-items: center;
    border-bottom: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    width: 45px;
}

.bookshelf_search_input {
    width: 90%;
    border: 0;
    background-color: #ffffff00;
    height: 40px;
    text-align: center;
    border-radius: 0px;
    font-family: "GucciSans-Book";
    border-bottom: 1px solid #d2d2d2;
}


.bookshelf_container {

    /*overflow-y: auto;*/
    /*padding-bottom: 100px;*/
    padding-right: 10px;
    margin-right: 10px;
}

.bookshelf_container::-webkit-scrollbar-track {
    width: 0px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.bookshelf_container::-webkit-scrollbar {
    width: 0px !important;
}

.bookshelf_card_view_all {
    position: absolute;
    top: 70px;
    /* left: 2px; */
    font-family: 'GucciSans-Bold';
    font-size: 15px;
    -webkit-user-select: none;
    user-select: none;
    width: 100px;
    padding: 0 5px;
    text-align: -webkit-center;
}

.bookshelf_card_view_all_single_module {
    position: absolute;
    top: 85px;
    /* left: 2px; */
    font-family: 'GucciSans-Bold';
    font-size: 15px;
    -webkit-user-select: none;
    user-select: none;
    width: 120px;
    padding: 0 5px;
    text-align: -webkit-center;
}

.book_cover_img_view_all {
    background-color: #f5f5f7 !important;
    border-bottom: 2px solid #d5d5d5;
    border-right: 2px solid #d5d5d5;
    border-radius: 5px;
    padding: 3px 9px;
}

.bookshelf_search_input_single_module {
    border: 0;
    background-color: #ffffff00;
    height: 40px;
    text-align: center;
    border-radius: 0px;
    font-family: "GucciSans-Book";
    width: 90%;
    /* margin-left: 4%;*/
    border-bottom: 1px solid #d2d2d2;
}

.bookshelf_search_input::placeholder {
    font-family: "GucciSans-Book";
    border-color: grey;
    border: 0;
}

.bookshelf_search_input::after {
    border: 0;
    border-color: #e4e4e4;
}

.bookshelf_section_title_container>div>.show.dropdown>.dropdown-menu.show>a:nth-child(1) {
    min-height: 30px !important;
}

#search_reset_bookshelf {
    margin-left: -5%;
    margin-top: 2px;
    margin-right: 2%;
}

#search_reset_bookshelf_single_module {
    margin-left: -45px;
    margin-top: 2px;
    margin-right: 20px;
    cursor: pointer;
}

.past_book {
    opacity: 0.5;
}

.bookshelf_line {
    width: 96%;
    height: auto;
    padding: 3px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 15px;
    margin-left: 2%;
    text-align: left;
}

.bookshelf_line_single_module {
    width: 96%;
    height: auto;
    padding: 3px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 15px;
    margin-left: 2%;
}

.bookshelf_section_title {
    font-family: "GucciSans-Bold";
}

.bookshelf_section_title_container {
    /*padding: 0 25px;*/
    align-items: center;
}

.bookshelf_section_books {
    padding: 5px 0;
    display: flex;
}

.bookshelf_section_books_all_single_module,
.bookshelf_section_books_all {
    /* max-height: 75vh; */
    padding: 10px 0;
    flex-wrap: wrap;
    /* height: auto; */
    display: flex;
    /* overflow-y: auto; */
    margin-right: 10px;
    /* min-height: 70vh; */
}

.bookshelf_section_books_container {
    overflow-x: auto;
}

.bookshelf_section_books_container::-webkit-scrollbar-track {
    height: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.bookshelf_section_books_container::-webkit-scrollbar {
    height: 8px !important;
}

.category_margin {
    margin-top: -70px;
}

.book_cover_img {
    width: 100px;
    min-width: 100px;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    margin: 5px 15px 5px 0;
    cursor: pointer;
    border-radius: 15px;
}

#dropdown-bookshelf {
    background-color: #fff0;
    border: 0 !important;
    color: black !important;
    border-radius: 5px !important;
    border-bottom: 1px solid #d5d5d5 !important;
    border-right: 1px solid #d5d5d5 !important;
    height: 40px;
}

#root>div>div.home_menu>div>div.right_menu>div>div:nth-child(3)>div>div:nth-child(1)>div:nth-child(2)>div>div {
    overflow-y: auto !important;
    height: 400px !important;
}

#root>div>div.home_menu>div>div:nth-child(3)>div>div:nth-child(1)>div:nth-child(2)>div>div {
    overflow-y: auto !important;
    height: 400px !important;
}

.bookshelf_section_title_container>div>.show.dropdown>.dropdown-menu.show {
    overflow-y: auto !important;
    height: 400px !important;
}

.book_cover_img_sigle_module {
    width: 120px;
    min-width: 120px;
    height: 185px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    margin: 5px 15px 5px 0;
    cursor: pointer;
    border-radius: 15px;
}

.bookshelf_detail_header {
    width: 100%;
    /*height: 70vh;*/
    margin-top: 10px;
}

.bookshelf_detail_cover {
    height: 310px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    margin: 5px;
    min-width: 206px;
    width: 206px;
    border-radius: 15px;
}

.bookshelf_detail_cover_single_module {
    height: 310px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    margin: 5px;
    min-width: 206px;
    width: 206px;
    text-align: -webkit-center;
    border-radius: 15px;
}

.bookshelf_detail_title_container {
    margin-left: 20px;
    /*  overflow: auto;
    height: 80vh;*/
    padding-right: 10px;
}

.bookshelf_detail_title_container_single_module {
    margin-left: 20px;
    padding-right: 10px;
    /*overflow: auto;
    height: 80vh;*/
    text-align: -webkit-left;
}

.bookshelf_detail_title {
    font-family: "GucciSans-Bold";
    font-size: 14px;
}

.bookshelf_detail_subtitle {
    font-family: "GucciSans-Book";
    font-size: 18px;
}

.bookshelf_rental_exp_date {
    margin-bottom: 10px;
    margin-left: 20px;
}

.bookshelf_detail_button {
    background-color: #000;
    color: white;
    padding: 10px 15px;
    border-radius: 25px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "GucciSans-Bold";
    margin-top: 20px;
}

.bookshelf_detail_button_single_module {
    background-color: #000;
    color: white;
    padding: 10px 15px;
    border-radius: 25px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "GucciSans-Bold";
    margin-top: 30px;
}

.unavailable {
    font-family: "GucciSans-Book";
    background-color: #b8b8b8;
}

#barcode_search {
    display: none;
}

.barcode_cont {
    display: none;
}

.bookshelf_button_retry {
    background: black;
    color: white;
    padding: 5px 5px;
    width: 145px;
    text-align: -webkit-center;
    border-radius: 33px;
    min-height: 55px;
    margin-left: 3%;
    align-self: center;
    cursor: pointer;
}

.camera_container {
    width: 100%;
    overflow: hidden;
    margin-top: -3%;
    margin-bottom: 0%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
}

.camera_container_after {
    width: 100%;
    overflow: hidden;
    margin-top: -10%;
    margin-bottom: 0%;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

video {
    margin-top: -15%;
    z-index: 110 !important;
}

div.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root>div>video {
    margin-top: -15%;
    z-index: 110 !important;
}

.borrowing_text {
    text-align: center;
    font-size: 23px;
}

.borrowing_subtext {
    text-align: center;
    font-size: 18px;
}

.today_left_container::-webkit-scrollbar-track {
    width: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.tutorial_container {
    text-align: -webkit-right;
    width: 100%;
    margin-right: 20px;
    margin-top: 20px;
}

.tutorial_icon {
    position: absolute;
    right: 0;
}

.tutorial_icon_global {
    position: absolute;
    right: 17px;
    top: 5px;
}

.business_travel_tutorial_icon {
    position: absolute;
    right: 20px;
    top: 15px;
}

.tutorial_icon_wellness {
    position: absolute;
    right: 17px;
    top: 15px;
}

.tutorial_icon_info {
    position: absolute;
    right: 30px;
    top: 14px;
}

.tutorial_icon_sharetogo {
    position: absolute;
    right: 17px;
    top: -25px;
}

.tutorial_icon_canteen {
    position: absolute;
    right: 10px;
    top: 10px;
}

.tutorial_icon_bookshelf {
    position: absolute;
    right: 10px;
    top: -30px;
}

.tutorial_icon_bookshelf_sm {
    position: absolute;
    right: 0;
    top: 15px;
}

.tutorial_icon_readytogrow {
    position: absolute;
    right: 10px;
    top: 11px;
}

.tutorial_icon_readytogrow_sm {
    position: absolute;
    right: 20px;
    top: 11px;
}

.desk_tutorial_container,
.desk_guide_container {
    justify-content: center;
    display: flex;
    align-items: center;
}

.desk_guide_page {
    width: 380px;
    height: 62vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
}

.desk_tutorial_page {
    width: 350px;
    height: 70vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
}

.e-calendar .e-content table,
.e-bigger.e-small .e-calendar .e-content table {
    margin-left: 0px !important;
}

.book_detail_container {
    width: 96%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 19px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 9px;
    max-height: 91vh;

}

.mess-container {
    place-content: center;
    margin-bottom: 13px;
    margin-top: 13px;
}

.required {
    color: #d50000;
    margin-left: 15px;
}

.message {
    font-family: "GucciSans-Book"
}

/* READY TO GROW */

.ready_to_grow_container {
    padding-bottom: 20px;
}

.ready-to-grow-calendar {
    width: 100%;
}

.ready-to-grow-calendar>.e-calendar .e-content table,
.e-bigger.e-small .e-calendar .e-content table {
    min-height: 35vh;
    width: 95%;
    margin-left: 35px;
}

.ready-to-grow-calendar>.e-bigger.e-small .e-calendar,
.e-calendar {
    max-width: 97% !important;
}

.ready-to-grow-calendar>.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
    font-size: 16px;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-selected span.e-ready-to-grow.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day {
    background-color: transparent !important;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-selected span.a-all-booked.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day {
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    color: #000 !important;
    background-color: #b6b6b6 !important;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-selected span.a-bookable.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day {
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    color: #000 !important;
    background-color: #fff !important;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-selected span.a-booked-by-me.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day {
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    color: #000 !important;
    background-color: #f0ba49 !important;
}

.ready-to-grow-calendar>.e-calendar .e-content span.a-bookable.e-day,
td.e-selected:hover span.e-day {
    background-color: #f5f5f7 !important;
    border-radius: 50%;
    color: #000;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background: none;
    border: 1px solid #000 !important;
    border-radius: 50%;
    box-shadow: none;
}


.ready-to-grow-calendar>.e-calendar .e-content span.a-all-booked.e-day {
    background-color: #b6b6b6 !important;
    border-radius: 50%;
    color: #000;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background-color: #000 !important;
    box-shadow: none;
    color: #fff;
}



.ready-to-grow-calendar>.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    border: solid 1px #000 !important;
    color: #fff !important;
}

.ready-to-grow-calendar, .share_to_go_calendar>.e-calendar .e-content td.e-selected.e-focused-date span.e-day {

    background-color: transparent !important;
    color: #000 !important;
}
.ready-to-grow-calendar>.e-calendar .e-content.e-month td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-month td.e-today span.e-day.e-today-date {
    background-color: #000 !important;
    box-shadow: none;
    color: #fff !important;
}

.a-booked-by-me {
    background-color: #f0ba49 !important;
    border-radius: 50% !important;
    font-size: 16px;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-selected.e-focused-date span.e-day .a-booked-by-me {
    border: 0;
    box-shadow: none;
    border-radius: 50%;
    border: solid 1px #000 !important;
    color: #000 !important;
    background-color: #f0ba49 !important;
}

.ready-to-grow-calendar>.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
    font-size: 16px;
    /*background-color: #F0EFEF;*/
}

.share_to_go_calendar > .e-calendar .e-content td.e-selected span.e-day, .e-calendar .e-content td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day  {
    background-color: transparent !important;
    box-shadow: none;
    color: #000 !important;
    border: solid 1px #000 !important;
    border-radius: 50% !important;
}

.share_to_go_calendar > .e-calendar .e-content td.e-selected span.e-day, .e-calendar .e-content td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day :hover {
    background-color: transparent !important;
    box-shadow: none;
    color: #000 !important;
    border: solid 1px #000 !important;
    border-radius: 50% !important;
}

.ready-to-grow-calendar>.e-calendar .e-content td.e-focused-date span.e-day.e-today-date {
    background-color: #000 !important;
    box-shadow: none;
    color: #fff !important;
}

.ready-to-grow-calendar>div>div.e-content.e-month>table>tbody>tr>.e-cell {
    padding: 15px
}
.ready-to-grow-calendar, .share_to_go_calendar>div>div.e-content.e-month>table>tbody>tr>.e-cell {
    padding: 0px;
}

.ready-to-grow-calendar>.e-selected .a-booked-by-me .e-day {
    border: solid 1px #000 !important;
    background-color: #f0ba49 !important;
    border-radius: 50% !important;
}

#title-ready-to-grow {
    width: 86%;
}

#subtitle-ready-to-grow {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.slot_list_global_container {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
}


.slot_list_container {
    margin-top: 10px;
}

.slot_card {
    width: 100%;
    margin-bottom: 10px;
}

.slot_icon {
    background-color: #b6b6b6;
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
}

.slot_hr_name {
    font-size: 26px;
    font-family: 'GucciSans-Bold';
    color: rgb(0, 0, 0);
    text-align-last: center;
    margin-top: 10px;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.slot_title {
    text-transform: uppercase;
    font-family: "GucciSans-Bold";
    font-size: 14px;
}

.slot_subtitle {
    font-size: 14px;
    display: flex;
}

.slot_button_container {
    width: 155px;
    margin-left: auto;
}

.slot_button_align {
    align-items: center;
    justify-content: center;
    display: flex;
}

.book_slot_button {
    color: black;
    font-family: "GucciSans-Book";
    border: 2px solid black;
    padding: 10px 10px;
    text-align: center;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    width: 130px !important;
    font-size: 14px;
    text-transform: uppercase;
    min-width: 130px;
}

.booked_slot_button {
    color: black;
    font-family: "GucciSans-Book";
    padding: 10px 10px;
    text-align: center;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    font-size: 14px;
    text-transform: uppercase;
    min-width: 120px;
}

.booked_byme_slot_button {
    color: white;
    background-color: black;
    font-family: "GucciSans-Book";
    border: 2px solid black;
    padding: 10px 10px;
    text-align: center;
    border-radius: 25px;
    margin-top: 5px;
    line-height: 16px;
    font-size: 14px;
    text-transform: uppercase;
    min-width: 120px;
}

.delete_slot_button {
    margin-left: 10px;
    width: 22px;
    min-width: 22px;
}

.loader_ellipsis_slots {
    position: relative;
    margin-top: calc(10vh - 75px);
}

/* MEETING ROOM */

.meeting_room_container {
    display: block;
    overflow: auto;
    width: 100%;
    overflow-x: hidden;
    padding: 0 30px;
    margin-top: 20px;
}

.meeting_room_container_web {
    overflow: hidden;
    height: auto;
    padding-top: 40px;
}

.meeting_room_container::-webkit-scrollbar {
    width: 5px;
}

.meeting_room_container ::-webkit-scrollbar-track {
    width: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.mr_filter_header_container {
    height: 90px;
    display: flex;
    width: 100%;
}

.react-datepicker__tab-loop {
    position: absolute !important;
    top: 100px !important;
}

.opportunities_datepicker_container>.react-datepicker__tab-loop {
    position: absolute !important;
    top: auto !important;
}



.react-datepicker-wrapper {
    width: auto !important;
}

.mr_datepicker_container {
    margin-left: auto;
    margin-top: 5px;
}

#mr_filter_header_left {
    width: 50%;
}

#mr_filter_header_right {
    width: 50%;
}

.mr_timepicker {
    margin-left: auto;
}

.filter_name {
    font-size: 12px;
}

.close_modal {
    text-align: -webkit-right;
}

.mr_loader_ellipsis {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 75px;
    margin-top: 20vh;
}

.meeting_rooms_datepicker {
    border-radius: 0px;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 16px;
    min-width: fit-content;
    color: black;
    cursor: pointer;
    border: 0px !important;
    border-bottom: 1px solid #d2d2d2 !important;
    height: 30px;
    text-align: center;
    padding-left: 0px !important;
}

.meeting_rooms_datepicker:focus {
    outline: 0px;
}

.meeting_rooms_details_popper {
    left: 110px !important;
    top: -40px !important;
}

.meeting_room_detail_card_img_container {
    text-align: -webkit-left;
}

.opportunities_pm_navbar {
    padding-left: 18px !important;
}

.opportunities_card_cont_mt {
    margin-top: 60px;
}

.meeting_rooms_input {
    width: 98%;
    min-width: 135px;
    border: 0;
    background-color: #ffffff00;
    text-align: left;
    border-radius: 0px;
    font-family: "GucciSans-Book";
    border-bottom: 1px solid #d2d2d2;
    cursor: pointer;
    height: 30px;
    margin-right: 10px;
}

.meeting_rooms_input:focus {
    outline: 0px;
}

.meeting_room_switch_label {
    margin-left: 10px !important;
}

.react-time-picker__inputGroup {
    min-width: 50px !important;
}

.meeting_room_switch_label {
    background-color: #808080;
    border-radius: 50px;
    cursor: pointer;
    display: -webkit-flex;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 14px;
    width: 30px;
    transform: scale(1.5);
    margin-left: 0px !important;
}

.meeting_room_switch_label .meeting_room_ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    height: 10px;
    width: 10px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.checkbox:checked+.meeting_room_switch_label .meeting_room_ball {
    transform: translateX(16px);
    background-color: white;
}

.checkbox:checked+.meeting_room_switch_label {
    background-color: #000000;
}

.mr_header_icon {
    background-color: #000;
    width: 32px;
    height: 32px;
    min-width: 35px;
    border-radius: 5px;
    padding: 3px;
    margin-top: 10px;
    text-align: -webkit-center;
    margin-left: 5px;
}

.meeting_room_line {
    background-color: #fff;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
}

.meeting_room_line_web {
    box-shadow: 0 2px 5px #bfbfbf;
}

.meeting_room_label {
    background-color: #808080;
    border-radius: 50px;
    cursor: pointer;
    display: -webkit-flex;
    align-items: center;
    -webkit-justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 24px;
    width: 38px;
    transform: scale(1.5);
    margin-left: 32px;
}

.meeting_room_label .ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    height: 19px;
    width: 19px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
    border: 0 !important;
}

.checkbox:checked+.meeting_room_label .ball {
    transform: translateX(14px);
    background-color: white;
}

.checkbox:checked+.meeting_room_label {
    background-color: #000000;
}

.meeting_room_name {
    font-family: "GucciSans-Bold";
    font-size: 20px;
    text-align: -webkit-left;
}

.meeting_room_capacity_container {
    max-width: 50px;
    width: 50px;
}

.capacity_icon {
    max-width: 25px;
}

.meeting_room_capacity {
    font-family: "GucciSans-Bold";
    font-size: 14px;
    margin-left: 5px;
}

.meeting_rooms_icon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 15px;
    background-size: cover;
}

.meeting_rooms_vertical_separator {
    border-right: 1px solid #808080;
    height: 60px;
    margin: 15px 10px 15px 0;
}

.room_icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.room_icon_active {
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.monitor_icon {
    background-image: url('./resources/img/monitor_icon.png')
}

.cam_icon {
    background-image: url('./resources/img/cam_icon.png')
}

.board_icon {
    background-image: url('./resources/img/presentation_icon.png')
}

.projector_icon {
    background-image: url('./resources/img/projector_icon.png')
}

.telephone_icon {
    background-image: url('./resources/img/telephone_icon.png')
}

.filter_board_container {
    background-color: #e7e6e6;
    border-radius: 20px;
    width: 100%;
    padding: 10px 20px;
    height: auto;
    margin-bottom: 10px;
}

.filter_board_options_container {
    display: flex;
}

.filter_board_column {
    margin-right: auto;
    border-left: 1px solid grey;
    padding: 10px;
}

.level_icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    background-image: url('./resources/img/floor_icon.png');
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.mr_filter_items_container {
    padding: 10px 0;
    display: flex;
    flex-flow: wrap;
}

.mr_filter_item {
    border: 1px solid #000;
    font-size: 12px;
    border-radius: 25px;
    padding: 2px 5px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "GucciSans-Bold";
    margin-right: 5px;
    margin-bottom: 5px;
}

.mr_filter_item_selected {
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    border-radius: 25px;
    padding: 2px 5px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "GucciSans-Bold";
    margin-right: 5px;
    margin-bottom: 5px;
}

.mr_filter_icon {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.mr_filter_selected_icon {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.mr_filter_bottom {
    display: flex;
}

.mr_filter_buttons_container {
    width: 40%;
    justify-content: right;
    margin-top: 5px;
}

#reset_filter_button {
    background-color: #fff;
    padding: 5px 30px;
    border-radius: 20px;
    font-family: "GucciSans-Bold";
    margin-right: 10px;
}

.reset_filter_button_mobile {
    background-color: #000;
    padding: 5px 30px;
    border-radius: 20px;
    font-family: "GucciSans-Bold";
    color: #fff;
    width: fit-content;
}

#apply_filter_button {
    background-color: #000;
    padding: 5px 30px;
    border-radius: 20px;
    font-family: "GucciSans-Bold";
    color: #fff;
}

.mr_global_container_modal {
    width: 100%;
    display: flex;
    overflow-y: auto;
}

.availability_container {
    width: 50%;
}

.mr_multiselect_container {
    width: 50%;
    padding: 0 20px;
}

.mr_timepicker_container {
    margin: 20px 5px;
}

#mr_label_calendar {
    text-transform: capitalize;
    font-family: "GucciSans-Bold";
    margin: 10px 0;
}

@import url("https://cdn.syncfusion.com/ej2/material.css");

* {
    margin: 0;
    padding: 0;
    font-family: "GucciSans-Book";
}

.Navbar {
    display: flex;
    align-items: center;
    padding: 10px 10px 15px 10px;
}

.Navbar .AppName {
    width: -webkit-fill-available;
    font-family: 'GucciSans-Bold';
    color: black;
}

.Navbar .QrLogo {
    width: 30px;
    cursor: pointer;
    padding-top: 10px;
    margin: 0 10px;
    display: none;
}

.Navbar .ProfileLogo {
    width: 30px;
    margin: 0 10px;
    cursor: pointer;
    padding-top: 10px;
}

.Navbar h4 {
    padding-top: 10px;
    padding-left: 10px;
    text-align: left;
    border: 2px black;
    font-size: 1.4em;
    margin: 0;
}

.Calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4px;
}


.CalendarSideBar {
    max-height: 66vh;
    padding-right: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-left: 10px;
}

.CalendarSideBar::-webkit-scrollbar-track {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 40px;
}

.CalendarSideBar::-webkit-scrollbar {
    width: 10px;
}

.CalendarSideBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #fff;
    background-color: #a8d2e9 !important;
    border: 1px solid #000;
}


.CalendarSideBar .DateString,
.CalendarFooter .DateString {
    display: flex;
    padding-left: 22px;
    margin: 0;
    color: #000000;
    font-family: 'GucciSans-Book';
}

.CalendarSideBar .DifferenceDateString,
.CalendarFooter .DifferenceDateString {
    padding-left: 22px;
    display: flow-root;
    text-align: left;
    margin: 0;
    color: grey;
    font-size: 8px;
}



.BookingContainer,
.CalendarFooter .BookingContainer {
    display: flex;
    margin-left: 8px;
    margin-top: 15px;
}

.BookingDetail,
.CalendarFooter .BookingDetail {
    display: flow-root;
    text-align: left;
    padding-left: 5px;
    border-radius: 6px;
    width: -webkit-fill-available;
}

.BookingDetail p,
.CalendarFooter .BookingDetail p {
    margin: 2px;
    font-size: 13px;
}

.BookingDetail .BookingDetailData,
.CalendarFooter .BookingDetail .BookingDetailData {
    margin: 2px;
    font-size: 9px;
}

.CalendarContainer {
    padding-bottom: 36px;
}

.QrCodeScanner {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: black;
    animation-duration: 0.6s;
    animation-name: slideinQR;
    z-index: 10;
    left: 0;
}

.CloseQrCodeScanner {
    width: 35px;
    height: 35px;
    color: white;
    position: absolute;
    top: 18px;
    right: 21px;
    cursor: pointer;
    z-index: 100;
    animation-duration: 1s;
    animation-name: slideinContent;
}

#InfoBoxBookingDetail {
    display: none;
}

#InfoBoxBookingDetailMonth {
    display: none;
}

#InfoBoxCalendar {
    display: none;
}

#InfoBoxOfficeDetail {
    display: none;
}

#InfoBoxProfile {
    display: none;
}

#InfoBoxQRCodeScanner {
    display: none;
}

.react-datepicker-popper {
    z-index: 1001 !important;
}

.InfoBox {
    position: fixed;
    width: 50%;
    min-height: 200px;
    z-index: 1000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    color: white;
    flex-direction: column;
    top: 30%;
    left: 25%;
}

.InfoBoxTitle {
    font-size: 1.2em;
    padding: 10px;
}

.InfoBoxContent {
    padding: 5px 15px;
}

.CloseInfoBox {
    font-size: 1em;
    margin-top: auto;
    padding: 15px 0;
    width: 100%;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
}

.CloseInfoBox:hover {
    background-color: #181818;
}

.ChooseDefaultFloor {
    width: 100%;
    background: #F0EFEF;
    position: absolute;
    top: 0;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 80px;
    min-height: 100vh;
    animation-duration: 0.6s;
    animation-name: rightslidein;
    z-index: 10000;
    left: 0;

    height: 150vh;
}

#ChooseDefaultFl {
    width: calc(100% - 300px);
    background: #F0EFEF;
    position: absolute;
    top: 0;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 80px;
    min-height: 100vh;
    animation-duration: 0.6s;
    animation-name: rightslideinmax;
    z-index: 10000;
    left: 300px;

    height: 103vh;
}

.CloseChooseDefaultFloor {
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    z-index: 10000;
    font-size: 1em;
    font-family: 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 1px;
    color: #000000;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CloseProfileBox {
    height: 45px;
    position: absolute;
    top: 0;
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    z-index: 100;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #0d80fe;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Calendar */
.container-fluid {
    appearance: none !important;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
    max-width: 92%;
    padding: 0;
    overflow: hidden;
    background: none;
    border: 0;
}

.e-calendar .e-content span.a-present.e-day {
    background-color: #000000;
    border: none;
    border-radius: 50%;
    color: #e0e7e9;
}

.e-calendar .e-content span.b-away.e-day {
    background-color: #aaa32c;
    border: none;
    border-radius: 50%;
    color: #e0e7e9;
}

.e-calendar .e-content span.c-holiday.e-day {
    background-color: #9a1b23;
    border: none;
    border-radius: 50%;
    color: #e0e7e9;
}

.e-calendar .e-content span.a-present.b-away.e-day {
    background-color: #000000;
    border: none;
    border-radius: 50%;
    color: #e0e7e9;
}

.e-calendar .e-content td:hover span.e-day.a-present,
.e-calendar .e-content td.e-selected span.e-day.a-present,
.e-calendar .e-content td.e-selected:hover span.e-day.a-present,
.e-calendar .e-content td.e-focused-date span.e-day.a-present {
    background-color: #000000;
    border: none;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td:hover span.e-day.b-away,
.e-calendar .e-content td.e-selected span.e-day.b-away,
.e-calendar .e-content td.e-selected:hover span.e-day.b-away,
.e-calendar .e-content td.e-focused-date span.e-day.away {
    background-color: #aaa32c;
    border: none;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td:hover span.e-day.c-holiday,
.e-calendar .e-content td.e-selected span.e-day.c-holiday,
.e-calendar .e-content td.e-selected:hover span.e-day.c-holiday,
.e-calendar .e-content td.e-focused-date span.e-day.c-holiday {
    background-color: #9a1b23;
    border: none;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td:hover span.e-day.a-present.b-away,
.e-calendar .e-content td.e-selected span.e-day.a-present.b-away,
.e-calendar .e-content td.e-selected:hover span.e-day.a-present.b-away,
.e-calendar .e-content td.e-focused-date span.e-day.a-present.b-away {
    background-color: #000000;
    border: none;
    border-radius: 50%;
    color: white;
}


/* Calendar Height */
.e-calendar .e-content table,
.e-bigger.e-small .e-calendar .e-content table {
    min-height: 55vh;
    width: 95%;
    margin-left: 35px;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
    font-family: 'GucciSans-Bold';
    text-transform: uppercase;
    font-size: 22px;
    color: #000000;
    margin-left: 9px;
}

.e-calendar .e-content td.e-today span.e-day {
    background: none !important;
    border: none;
    color: black !important;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-calendar .e-content td:hover.e-today.e-selected span.e-day {
    background: #eee;
    border: 1px solid #e3165b;
    border-radius: 50%;
    color: black;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background: #eee;
    border: 1px solid #000;
    box-shadow: none;
    color: #000;
}

.e-calendar .e-content td.e-focused-date span.e-day,
.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-calendar .e-content td.e-focused-date:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:focus span.e-day {
    background: none !important;
}

.e-calendar .e-content td.e-today span.e-day.a-present,
.e-calendar .e-content td.e-today.e-selected span.e-day.a-present,
.e-calendar .e-content td:hover.e-today.e-selected span.e-day.a-present {
    background: #000000 !important;
    border: 1px solid #e3165b;
    border-radius: 50%;
    color: white !important
}

.e-calendar .e-content td.e-today span.e-day.b-away,
.e-calendar .e-content td.e-today.e-selected span.e-day.b-away,
.e-calendar .e-content td:hover.e-today.e-selected span.e-day.b-away {
    background: rgb(81, 255, 0);
    border: 1px solid #e3165b;
    border-radius: 50%;
    color: black;
}

.e-calendar .e-content td.e-today span.e-day.c-holiday,
.e-calendar .e-content td.e-today.e-selected span.e-day.c-holiday,
.e-calendar .e-content td:hover.e-today.e-selected span.e-day.c-holiday {
    background: red;
    border: 1px solid #e3165b;
    border-radius: 50%;
    color: black;
}

.e-calendar .e-content td.e-today span.e-day.a-present.b-away,
.e-calendar .e-content td.e-today.e-selected span.e-day.a-present.b-away,
.e-calendar .e-content td:hover.e-today.e-selected span.e-day.a-present.b-away {
    background: #5b9ada;
    border: 1px solid #e3165b;
    border-radius: 50%;

}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
    box-shadow: none
}

.e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
    border-radius: 50%;
    background: black !important;
    border: none !important;
    color: #ffffff !important;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
    background-color: #eee;
    color: black;
    border: none;
    border-radius: 50%;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day.a-present {
    background-color: #5b9ada;
    color: white;
    border: none;
    border-radius: 50%;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day.b-away {
    background-color: rgb(81, 255, 0);
    color: black;
    border: none;
    border-radius: 50%;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day.c-holiday {
    background-color: red;
    color: black;
    border: none;
    border-radius: 50%;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day.a-present.b-away {
    background-color: #5b9ada;
    color: black;
    border: none;
    border-radius: 50%;
}

.e-calendar .e-footer-container,
.e-bigger.e-small .e-calendar .e-footer-container {
    background-color: transparent;
}

/* Today Button */
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
    display: none;
}

/*Selected Year or Month Hover */
.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
    background-color: #000000;
}

.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
    font-size: 18px;
    font-weight: normal;
    height: 40px;
    line-height: 40px;
    width: 40px;
}

.e-calendar .e-content.e-month td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-month td.e-today span.e-day {
    line-height: 40px;
}

.e-calendar .e-content.e-year td>span.e-day,
.e-calendar .e-content.e-decade td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
    background: transparent;
}

.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
    background-color: #F0EFEF;
}

.w-80 {
    width: 80%;
}

.w-100 {
    width: 100%;
}

.OfficeDetailBox {
    width: 100%;
    max-height: fit-content;
    height: 90vh;
    position: relative;
    animation-duration: 0.5s;
    animation-name: slidein;
    z-index: 10;
    background-color: #F0EFEF;
    border-radius: 45px;
    margin-bottom: 50px;
}

.OpenOfficeDetailBox {
    position: relative;
    width: 134px;
    height: 35px;
    display: flex;
    color: white;
    background: #000000;
    border-radius: 27px;
    z-index: 1;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    margin-top: -13px;
}


.CloseOfficeDetailBox_button {
    position: relative;
    width: 134px;
    height: 35px;
    display: flex;
    color: black;
    background: white;
    border-radius: 27px;
    z-index: 1;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    margin-top: -13px;
    margin-left: 10px;
}

.CloseOfficeDetailBox {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 18px;
    right: 21px;
    cursor: pointer;
    z-index: 100;
}

.close_container {
    width: 100%;
    text-align: -webkit-right;
    padding-right: 20px;
    cursor: pointer;
}

.OfficeDetailInfoContainer {
    margin: 0;
    padding-bottom: 4px;
    background-color: #F0EFEF;
    border-top-left-radius: 37px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
    padding-top: 2px;
    overflow: hidden;
}

.OfficeDetailFloorMap {
    top: 2px;
    padding: 10px;
    width: 69%;
    border: 2px solid rgba(0, 0, 0, .507);
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, .329);
    border-radius: 10px;
    margin: -2px auto auto;
    position: relative;
}

.tools {
    position: absolute;
    z-index: 5;
    bottom: 11px;
    right: 11px;
    display: grid;
}

.ListToSelect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    padding: 10px 20px;
}

.ListToSelect#DateSelect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    padding: 10px 2px;
    width: 94%;
    margin-left: 18px;
}

.ListToSelect#LocationSelect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    padding: 10px 2px;
    width: 94%;
    margin-left: 18px;
}

.dateButton {
    border-radius: 5px;
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: #fbfbfb;
    min-width: fit-content;
    color: black;
    cursor: pointer;
}

.selectedDateButton {
    border: none !important;
    background-color: #000000;
    color: white !important;
}

.FloorBookingDetail {
    position: relative;
    cursor: move;
}

.Desks {
    position: absolute;
    width: 1.5%;
    font-size: 4px;
    font-weight: 600;
    color: black;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ProfileBox {
    width: calc(100% - 308px);
    background: #F0EFEF;
    position: absolute;
    top: 0;
    padding-left: 7px;
    padding-right: 10px;
    padding-top: 50px;
    min-height: 100vh;
    animation-duration: 0.6s;
    animation-name: rightslideinprofile;
    z-index: 13;
    overflow-y: auto;
    padding-bottom: 30px;
    height: 100vh;
    overflow-x: hidden;
}

.react-slideshow-wrapper.slide {
    text-align: -webkit-center !important;
}

.CloseProfileBox {
    height: 45px;
    position: absolute;
    top: 0;
    width: calc(100% - 308px);
    padding-top: 10px;
    padding-left: 10px;
    cursor: pointer;
    z-index: 100;
    font-size: 1em;
    font-family: 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 1px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: #F0EFEF;
    animation-duration: 0.6s;
    animation-name: rightslideinprofile;
}

.profile_container {
    padding-top: 40px;
}

.ContentProfileName {
    font-size: 30px;
    font-weight: 700;
    text-align: left;
}

.ContentProfileSubName {
    font-size: 15px;
    text-align: left;
    padding-top: 13px;
    padding-bottom: 5px;
    color: rgb(83, 83, 83);
    width: 100%;
    margin-left: 5%;
}

.ProfileDetails {
    width: 100%;
    margin: 10px 0 10px 0;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ProfileDetailsWeb {
    width: 100%;
    margin: 10px 0 10px 0;
    padding: 5px 0px 5px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.UserInformation {
    padding-left: 20px;
    text-align: left;
    font-size: 15px;
    line-height: 17px;
    padding-top: 20px;
}

.DefaultDetails {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
}

.profileSettingsContainer {
    background-color: #f5f5f7;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
}

.DefaultDetailsWeb {
    width: 100%;
}

.profile_button {
    padding: 5px 30px;
    box-shadow: 0 2px 5px #bfbfbf;
    border-radius: 20px;
}

.SelectedDateBooker {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
    top: 170px;
    z-index: 6;
}

.BookerName {
    display: none;
    background: #000000;
    color: white;
    padding: 14px 30px;
    border-radius: 25px;
}

.textinone {
    display: inline-block;
    font-size: 1em;
    line-height: 2em;
    width: max-content;
}

.textinone2 {
    display: inline-block;
    font-size: 1em;
    line-height: 2em;
}

.textPositionLeft {
    margin-left: 5%;
}

.textPositionRight {
    text-align: right;
    color: grey;
    width: -webkit-fill-available;
    margin-right: 5%;
}

.textPositionLeftReservation {
    text-align: left;
    width: 100%;
    display: flex;
    padding-top: 5px;
}

.moreInfo {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
}

.line_separator {
    margin: auto;
    width: 90%;
}

.imageProfile {
    width: 60px;
    margin-left: 5%;
    content: url('../src/resources/img/profile-icon.png');
    height: 60px;
}

.imageProfileWeb {
    width: 60px;
    min-width: 60px;
    content: url('../src/resources/img/profile-icon.png');
    height: 60px;
}

.infoProfileContainerWeb {
    margin-left: 10px;
    text-align: -webkit-left;
}

.text_separator {
    content: url('../src/resources/img/next.png');
    width: 32px;
    height: 20px;
    padding-left: 7px;
    padding-right: 7px;
    align-self: center;
}

.profileDetalsline {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
}

.profileDetalsline_disabled {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    color: #d8d8d8;
}

.select_floor_disabled {
    color: #d8d8d8;
}

.save_disabled {
    background: #89b0ac;
    cursor: auto;
}

.btn-secondary {
    background-color: #000000 !important;
    border: 1px solid white;
}

.ReservationDetails {
    padding-top: 30px;
    padding-bottom: 30px;
    visibility: hidden;
}

.ReservationDetailsfixed {
    padding-top: 30px;
    padding-bottom: 30px;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    width: 547px;
    border-radius: 15px 15px 0 0;
    background-color: #F0EFEF;
    animation-duration: 0.5s;
    animation-name: slideinDetail;
    left: calc(75.8%);
    transform: translateX(-50%);
    border: 1px solid #c5c5c540;
}

.ReservationDetailsfixed.delete {
    padding-top: 30px;
    padding-bottom: 30px;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    width: 100%;
    border-radius: 15px;
    background-color: #F0EFEF;
    animation-duration: 0.5s;
    animation-name: slideinDetailDelete;
}

.reservationButton {
    text-align: -webkit-center;
    margin-top: 20px;
}

.datePickerContainer {
    flex-wrap: wrap;
}

.input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.forgot-password-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.forgot-password-cell {
    font-size: 16px;
    color: #ffffff;
    font-family: 'GucciSans-Book';
    cursor: pointer
}

.reservation_line_separator {
    width: 100%;
}

.reservation_textinone {
    display: inline-block;
    font-size: 1em;
    line-height: 2em;
    width: max-content;
    margin-left: 25px;
}

.noPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.noMargin {
    margin-left: 0;
    margin-right: 0;
}

.BookingDetail .swipeable-list-item__content {
    display: block;
}

.deleteContainer {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    box-sizing: border-box;
    color: #eee;
    user-select: none;
    background-color: #9a1b23;
}

.icon {
    fill: white;
    width: 32px;
    height: 32px;
    align-self: center;
}

.iconCentered {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.reservationSwipeContainer.h3 {
    color: black
}

.container {
    max-width: 1140px;
    margin: auto;
    padding: 0 10px;
    text-align: -webkit-center;
}

.container-nav {
    padding-top: 25px;
    max-width: 1170px;
    margin: auto;
    margin-bottom: 3px;
    padding-bottom: 20px;
    background-color: none;
    margin-top: -30px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.Switch {
    margin-left: 4px;
}

.swipeable-list>div {
    display: flex;
    margin-bottom: 10px;
}

.pickReservationDates {
    max-width: 900px;
    margin: auto;
    text-align: left;
    padding: 30px;
}

.pickReservationDates .date {
    margin: 10px 0;
}

/*LOADING DESKS*/
.loader_ellipsis {
    position: relative;
    /* margin-left: calc(50% - 33px);*/
    margin-top: calc(50vh - 75px);
}

.loader_ellipsis_web {
    margin-top: 20px !important;
    padding-top: 30vh !important;
}

.cam_loader_ellipsis {
    position: relative;
    margin-left: 40%;
    margin-top: calc(10vh);
    margin-bottom: calc(10vh);
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

@keyframes slideinDetail {
    0% {
        top: 100vh;
    }

    100% {
        top: 60vh;
    }
}

@keyframes slideinDetailDelete {
    0% {
        top: 60vh;
    }

    100% {
        top: 100vh;
    }
}

@keyframes slidein {
    from {
        top: 70vh;
    }

    to {
        top: -10px;
    }
}

@keyframes rightslideinprofile_s {
    0% {
        left: 100%;
    }

    100% {
        left: 250px;
    }
}

@keyframes rightslideinprofile {
    0% {
        left: 100%;
    }

    100% {
        left: 300px;
    }
}

@keyframes rightslidein {
    0% {
        left: 100%;
    }

    100% {
        left: 0;
    }
}

@keyframes rightslideinmax {
    0% {
        left: 100%;
    }

    100% {
        left: 300px;
    }
}

@keyframes rightslideinmax_s {
    0% {
        left: 100%;
    }

    100% {
        left: 250px;
    }
}

@keyframes slideinQR {
    0% {
        top: 100vh;
    }

    100% {
        top: 0;
    }
}

@keyframes slideinContent {
    0% {
        top: 100%;
    }

    75% {
        top: 5px;
    }

    100% {
        top: 18px;
    }
}



.no_burger {
    width: 50px;
    height: 50px;
}

.close_scan {
    position: absolute;
    top: 15px;
    left: 90%;
}

.calendar_card>.rbc-calendar>.rbc-toolbar {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px !important;
    font-size: 16px !important;
    padding-left: 20px !important;
    padding-top: 20px;
    padding-right: 20px;
}

.rbc-toolbar>.rbc-toolbar>span>button {
    cursor: pointer;
}

.rbc-toolbar button {
    font-family: 'GucciSans-Book'
}

.rbc-today {
    background-color: #ebebeb !important;
}

.rbc-show-more {
    color: black !important;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
    font-size: 13px;
}

.rbc-day-slot .rbc-event-content {
    font-size: 13px;
}

.rbc-day-slot .rbc-event-label {
    display: none;
}

.calendar_card>div>div.rbc-toolbar>span.rbc-toolbar-label {
    font-size: 19px;
    font-family: 'GucciSans-Bold';
}

.iframe_doc {
    width: 100%;
    height: 85vh;
}

.business_travel_menu_container {
    margin: 20px 0 20px 0;
    display: grid;
    place-items: center;
}

.business_travel_menu_container_mobile {
    display: grid;
    place-items: center;
    padding-left: 5px;
}

.business_travel_subtitle {
    text-align-last: center;
    padding-bottom: 5px;
    font-size: 14px;
    text-align: -webkit-center;
}

.business_travel_title {
    font-family: 'GucciSans-Bold';
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
}

.text_doc {
    font-family: 'GucciSans-Book';
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
    padding-left: 5px;
}


.text_doc_selected {
    font-family: 'GucciSans-Bold';
    text-decoration: underline;
    padding-bottom: 10px;
    cursor: context-menu;
    text-align: center;
}

/*meeting room detail*/
.meeting_room_detail_card_name {
    background: white;
    width: 100%;
    min-height: 100px;
    border-radius: 20px;
    padding: 10px;
    position: relative;
}

.meeting_room_detail_card_name_container {
    width: 100%;
    display: flex;
}

.meeting_room_detail_left_container {
    display: flex;
}

.meeting_room_detail_card_img {
    width: 160px;
    height: 160px;
    min-width: 160px;
    border-radius: 9px;
    margin: 16px;
    background: url("https://cdn.pixabay.com/photo/2017/03/28/12/17/chairs-2181994__340.jpg");
    background-size: cover;
    background-position-x: center;
}

.meeting_room_manager_container {
    margin-top: 15px;
    text-align: -webkit-left;
}

.meeting_room_detail_name {
    margin: 12px 0;
    font-family: 'GucciSans-Bold';
    font-size: 20px;
    float: left;
}

.meeting_room_detail_position {
    margin: 2px 0;
    font-family: 'GucciSans-Book';
    color: grey;
    font-size: 18px;
}

.meeting_room_detail_favourite {
    margin-top: 16px;
    margin-left: auto;
}

.meeting_room_detail_user_count {
    font-family: 'GucciSans-Bold';
    font-size: 20px;
    margin-left: 2px;
}

.meeting_room_vertical_line_detail {
    border-right: 1px solid grey;
    height: 40px;
    margin: 0 20px;
}

.meeting_room_vertical_line_right {
    border-left: 1px solid grey;
    height: 150px;
    margin-left: 20px;
    margin-top: 20px;
}

.meeting_room_schedule_container {
    margin-top: 12px;
    padding: 0 10px;
}


.meeting_room_status {
    text-transform: lowercase;
}

.meeting_room_status:first-letter {
    text-transform: uppercase;
}

.meeting_room_buttons_container {
    margin-top: 20px;
    text-align: -webkit-center;
}

.meeting_room_schedule {
    padding: 8px 20px;
    width: 200px;
    background-color: white;
    border: 1px solid #000;
    margin-top: 10px;
    color: black;
    font-size: 18px;
    font-family: "GucciSans-Bold";
    border-radius: 25px;
    user-select: none
}

.mail_to_meeting_room {
    text-decoration: none;
    display: block;
}

.mail_to_meeting_room:hover {
    color: black;
}

#book_meeting_room {
    padding: 8px 20px;
    background-color: black;
    border: 1px solid #000;
    margin-top: 10px;
    color: white;
    font-size: 18px;
    font-family: "GucciSans-Book";
    border-radius: 25px;
    left: 55%;
    right: 50%;
    text-align: center;
    width: 40%;
    bottom: 30px;
    position: absolute;
}

.mr_multiselect {
    padding: 10px 0;
}

.options_book_meeting_room {
    margin-right: 20px;
}

.how_to_book_container {
    padding: 5px 20px;
}

.meeting_room_map_container {
    background-color: white;
    width: 100%;
    height: 44vh;
    margin-top: 20px;
    border-radius: 20px;
    text-align: -webkit-center;

}

#mr_how_to_reach {
    font-family: 'GucciSans-Bold';
    text-transform: uppercase;
    padding: 10px 0;
    user-select: none
}

#meeting_room_map_container {
    position: relative;
}

.meeting_room_map {
    height: 38vh;
    width: 100%;
    background-size: contain;
    cursor: move;
    min-width: 53vh;
    background-repeat: no-repeat;
}

.meeting_room_tools {
    z-index: 5;
    display: grid;
    margin-left: 20px;
    position: absolute;
}

#plus_tool {
    margin-bottom: 5px;
}

/* VR Learning */

.vr_back {
    margin-top: 15px;
    z-index: 99;
    width: 3%;
    cursor: pointer;
}

.vr_global_container {
    padding: 5px 10px;
    max-width: calc(1366px - 300px);
    margin-right: auto;
    margin-left: 35px;
}

.vr_headsets_container {
    padding: 1px;
    border-radius: 15px;
    margin: 10px 0;
    overflow: auto;
    position: relative;
}

.disabled_vr_button {
    background-color: #5d5d5d;
    color: #949494;
    cursor: auto;
}

.headset_card {
    background-color: #d8d8d8;
    height: 260px;
    width: 175px;
    margin-right: 10px;
    cursor: pointer;
    min-width: 110px;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 8px;
}

.headset_card_borrowed {
    background-color: #d8d8d8;
    height: 270px;
    width: 175px;
    margin-right: 10px;
    cursor: pointer;
    border: 3px solid black;
    min-width: 110px;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5px !important;
}

.vr_infos_image_container {
    display: flex;
    margin: 20px 0;
}

#vr_headset_image {
    background-color: #d8d8d8;
    width: 220px;
    height: 300px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.vr_headset_line {
    margin: 10px 0;
}

/* COUNTDOWN */

.countdown_container {
    color: #000;
    width: 96%;
    margin-top: 9px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
}

.countdown_container_menu {
    color: #000;
    width: 96%;
    margin-top: 9px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
}

.countdown_container_leftbar {
    width: 94% !important;
    border-radius: 20px;
    box-shadow: 0 5px 5px #bfbfbf;
    /* padding: 4px; */
    margin: 8px;
}

.countdown_container_string {
    padding-top: 66px;
}

.countdown_container_string_leftbar {
    padding-bottom: 0;
    display: flex;
    place-content: center;
    position: absolute;
    width: 100%;
    margin-top: 0;
}

.counter_string {
    width: 100%;
    place-content: center;
}

.countdown_columns {
    margin: auto 10px;
}

.countdown_counter_text {
    font-size: 12px;
    cursor: pointer;
    margin-top: 20px;
}

.countdown_text {
    font-size: 9px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: -5px;
}

.event_image_container_web {
    width: 600px;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
}

.event_image_container {
    width: 480px;
    height: 220px;
    background-size: cover;
}


.agenda_events_cards_container {
    margin-top: 35px;
    padding-right: 10px;
    /*
    height: calc(100vh - 190px);
    overflow-y: auto;
*/
}

.agenda_events_card {
    font-size: 14px;
    text-align: -webkit-left;
    border-bottom: 1px solid #000;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

/* AGENDA DROPDOWN */

/* Dropdown button on hover & focus */

.logout_arrow {
    position: absolute;
    display: inline-block;
    right: -15px;
    top: 15px;
}

/* The container <div> - needed to position the dropdown content */

.dropdown {
    position: absolute;
    display: inline-block;
    right: 0px;
    top: 0px;
}

.dropbtn-agenda-container {
    position: relative;
    z-index: 10;
    top: -30px;
    right: 20px;
}

.dropbtn-agenda {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -18px;
    top: 10px;
    z-index: 5;
}

.drop-agenda {
    object-position: center;
    background: #ffffff;
    padding: 2px 11px;
    height: 35px;
    box-shadow: 1px 6px 9px #acacac63;
    border-radius: 2px;
    min-width: 110px;
    place-content: center;
    width: max-content;
}

.drop-agenda:hover {
    background: #ebebeb;
    cursor: pointer;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-header-agenda {
    display: none;
    position: absolute;
    z-index: 1;
    font-family: 'GucciSans-Book' !important;
    margin-top: 55px;
    width: auto;
    right: 10px;
}

/* Links inside the dropdown */
.dropdown-content a {
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}

.hide {
    display: none;
}


.communication_block {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    width: calc(100% - 150px);
    padding: 10px 27px;
    text-align: left;
    font-family: 'GucciSans-Bold';
    color: #000;
}

.communications_events_cards_container {
    margin-top: 30px;
    padding-top: 30px;
}

.communications_events_card {
    display: flex;
    /* align-items: flex-start;*/
    margin-bottom: 3px;
    margin-top: 3px;
    align-items: center;
}

.communication_from {
    background-size: contain;
    background-repeat: no-repeat;
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-left: 40px;
    margin-right: 30px;
    /*margin-top: 15px;*/
}

.calendar_container_agenda {
    margin-top: 20px;
    padding: 0 20px;
}

.calendar_filter_container {
    align-items: center;
}

/* The container <div> - needed to position the dropdown content */

.dropdown {
    /*position: absolute;*/
    position: relative;
    display: inline-block;
    right: 0px;
    top: 0px;
}

.dropbtn {
    width: 80px;
    height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 5;
}

.drop {
    object-position: center;
    background: #ffffff;
    padding: 2px 11px;
    height: 35px;
    box-shadow: 1px 6px 9px #acacac63;
    border-radius: 2px;
    min-width: 150px;
    place-content: center;
    font-size: 13px;
}

.drop:hover {
    background: #000;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
}

.agenda_mycalendar_container {
    width: 95%;
    overflow-x: auto;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    padding-top: 10px;
}

.wellnesslab_courses_container {
    padding: 40px 40px 0 40px;
    display: flex;
}

.wellnesslab_courses {
    width: 100%;
}

.wellness_lab_not_active_container {
    max-width: 1066px;
    overflow: auto;
    height: 85vh;
    margin-right: 10px;
    width: 97%;
}

.carousel.carousel-slider .control-arrow {
    height: calc(100% - 35px) !important;
}

.control-dots>li {
    border: 1px solid grey;
    opacity: 1 !important;
}

.control-dots>.selected {
    border: 1px solid black;
    opacity: 1 !important;
}

.carousel .control-dots {
    left: 5px !important;
    margin: 10px 0 4px 0;
}

.carousel .slider-wrapper {
    cursor: pointer;
}

.carousel.carousel-slider {
    padding: 0 0 40px 0;
    display: flex;
    align-items: center;

}

.carousel.carousel-slider .control-arrow:hover {
    background: rgb(255 255 255 / 0%) !important;
    filter: invert(1);
}

html>body {
    background-color: #e2e1e1e3 !important;
}

/* FEED */

.feed_buttons_container {
    background-color: #ededed;
    margin-bottom: 20px;
    width: 360px;
    border-radius: 20px;
    margin-top: 10px;
    height: 28px;
    text-align: center;
    display: -webkit-flex;
}

.feed_component_container {
    padding: 20px 20px 0px 20px
}

.feed_list_container {
    padding: 0 50px;
}

.feed_grouplist_container {
    padding: 0 50px;
}

.feed_button {
    padding: 2px;
    width: 180px;
    height: 25px;
    margin-top: 1px;
}

.feed_button_selected {
    background-color: #fff;
    padding: 2px;
    width: 180px;
    border-radius: 20px;
    height: 25px;
    margin-top: 1px;
    box-shadow: 0 1px 5px #cfcfcf;
}

.group_card {
    background-color: #f5f5f7;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 10px 20px;
}

.group_viewed_font {
    font-size: 14px;
    margin-left: 10px;
    letter-spacing: -1px;
}

.group_card_image {
    height: 110px;
    border-radius: 20px;
    background-size: cover;
    width: 100%;
    background-position: center;
    position: relative;
    margin: 10px 0;
}

.last_update_string {
    margin-left: auto;
    font-size: 14px;
}

.group_card_name {
    position: relative;
    padding-top: 40px;
    color: #fff;
    font-size: 18px;
    text-shadow: 0 0 5px #171717;
    background: #5c5c5c54;
    border-radius: 20px;
    height: 110px;
}

.feed_video_container {
    border-radius: 20px;
    margin: 10px 60px 25px 60px
}

.feed_video_container>:nth-child(2)>button {
    border: none;
    background: none;
}

.feed_video_title_mobile {
    display: none;
}

.feed_video {
    margin-top: 20px;
    border-radius: 20px;
}

div.feed_video_container>div:nth-child(1)>video {
    margin-top: 20px !important;
    border-radius: 20px;
}


.feed_description_content {
    padding: 20px 20px;
    text-align: -webkit-left;
    background-color: #f5f5f7;
    border-radius: 20px;
    margin: 10px 60px;
}


/* OPPORTUNITIES */

.job_role_cell {
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 190px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

textarea {
    outline: none;
}

.opportunities_main_image_title {
    width: 91%;
    background-size: cover;
    height: 135px;
    margin-bottom: 30px;
    border-radius: 15px;
    background-position: center;
    background-image: url('resources/img/opportunities_img.png');
}

.opportunities_label_feedbackform {
    text-align: -webkit-left;
    margin-bottom: 5px;
}

.opportunities_main_image_title_project {
    width: 91%;
    background-size: cover;
    height: 135px;
    margin-bottom: 30px;
    border-radius: 15px;
    background-position: center;
    background-image: url('resources/img/opportunities_img_project.png');
}

.opportunities_main_image_title_experience {
    width: 91%;
    background-size: cover;
    height: 135px;
    margin-bottom: 30px;
    border-radius: 15px;
    background-position: center;
    background-image: url('resources/img/opportunities_img_experience.png');
    border: 2px solid #610000;
}

.opportunities_input_loc_select>div>div>div {
    cursor: pointer
}

.opportunities_content {
    padding: 30px;
}

.opportunities_detail_padding {
    padding: 30px;
}

.admin_link {
    font-family: 'GucciSans-Book';
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
    width: 100px;
    text-align: center;
    margin-top: 5px;
}

.create_opportunities_button {
    display: block;
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 20px;
    width: fit-content;
    box-shadow: 0 3px 6px #bfbfbf;
}

.opportunities_buttons_container {
    background-color: #f5f5f7;
    border-radius: 20px;
    display: flex;
    width: 460px;
    margin-bottom: 40px;
}

.section_title_cont {
    width: 91%;
}

.view_all_link {
    margin-top: -25px;
    float: right;
    margin-right: 45px;
    text-decoration: underline;
    font-style: italic;
    font-size: 14px;
}

.opportunities_section {
    min-height: 250px
}

div.opportunities_boxes_container>div>div>div>ul.control-dots {
    margin-bottom: 25px;
}

#dropdownMenuButtonAvailable {
    background-color: #ffffff;
    color: black !important;
    z-index: 100;
}

.opportunities_tag_cont {
    background-color: black;
    width: max-content;
    float: left;
    color: white;
    border-radius: 20px;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.navbar-light {
    background-color: #f8f9fa
}

.navbar-nav {
    align-items: center !important;
    text-align: center !important;
}

.navbar-expand .navbar-nav .nav-link {
    min-width: 100px;
}

.selectedMenu {
    font-family: 'GucciSans-Bold' !important;
}

.opportunities_buttons {
    border-radius: 20px;
    width: 150px;
    padding: 10px 20px;
    font-size: 14px;
}

input[type="checkbox"i] {
    accent-color: black;
}

.opportuinities_info {
    width: calc(100% - 100px);
    padding-top: 40px;
}

.opportunities_info_img {
    width: 95%;
    background-image: url('resources/img/opportunities_img.png');
    background-size: cover;
    height: 135px;
    margin-bottom: 30px;
    border-radius: 15px;
    background-position: center;
}

.opportunities_faq_img {
    width: 95%;
    background-image: url('resources/img/opportunities_faq.png');
    background-size: cover;
    height: 135px;
    margin-bottom: 30px;
    border-radius: 15px;
    background-position: center;
}

.opportunities_faq_cont {
    text-align: -webkit-left;
    width: 91%;
}

.opportunities_info_text {
    font-family: 'GucciSans-Book';
    font-size: 15px;
    width: 95%;
    margin-bottom: 50px;
    margin-top: 160px;
}

.opportunities_info_video>video {
    border-radius: 20px;
}

.opportunities_info_button {
    display: block;
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 20px;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0 3px 6px #bfbfbf;
    padding: 8px 20px;
}

.opportunities_info_button_web {
    margin: 0 auto;
    min-width: 200px;
}

.opportunities_document_button {
    display: block;
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 20px;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0 3px 6px #bfbfbf;
    padding: 8px 20px;
    margin-bottom: 20px;
}

.opportunities_buttons_selected {
    font-size: 14px;
    border-radius: 20px;
    background-color: #fff;
    width: 150px;
    padding: 10px 20px;
    box-shadow: 0 3px 6px #bfbfbf;
}

.wishlist_separator {
    border-left: 1px solid #cfcfcf;
    margin: 0 5px;
}

.opportunities_boxes_container {
    margin-top: 10px;
    margin-bottom: -8px;
    display: flex;
    align-items: center;
    place-content: center;
}

.opportunities_box {
    border-radius: 20px;
    padding: 20px;
    background-color: #f5f5f7;
    width: 230px;
    min-width: 230px;
    height: 170px;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    margin-bottom: 15px;
}


.opportunities_box_view_all {
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'GucciSans-Bold';
    position: absolute;
    left: 80px;
    top: 60px;
    width: 65px;
    align-content: center;
}

.full_opportunity_box {
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 2px grey inset;
    color: #b3b3b3;
    width: 230px;
    min-width: 230px;
    height: 170px;
    margin-right: 10px;
    margin-left: 10px;
    position: relative
}

.in_approval_opportunity_box {
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 2px grey inset;
    color: #b3b3b3;
    width: 230px;
    min-width: 230px;
    height: 170px;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
}

.in_approval_opportunity_box:hover {
    color: #7e7e7e;
    box-shadow: 0px 0px 2px #2e2e2e inset;
}

/*.admin_opportunity_box {
    border-radius: 20px;
    padding: 20px;
    background-color: #f5f5f7;
    width: 230px;
    min-width: 230px;
    height: 170px;
    margin-right: 20px;
    position: relative;
    box-shadow: 0px 0px 6px grey inset;
}*/
.admin_opportunity_box {
    border-radius: 20px;
    padding: 20px;
    background-color: #f5f5f7;
    width: 230px;
    min-width: 230px;
    height: 170px;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    margin-bottom: 15px;
}

.opportunities_box:hover {
    /* background-color: #e9e9ed*/
    /* border: 1px solid #cccccc inset;*/
    /* box-shadow: 0px 0px 2px black inset;*/
    box-shadow: 0px 0px 2px #6f6f6f inset;
}

.opportunities_box_description {
    text-align: left;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    max-height: 85px;
    width: 200px;
    white-space: nowrap;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.opportunities_box_description_notag {
    text-align: left;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-top: 25px;
    max-height: 105px;
    width: 200px;
    white-space: nowrap;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description_opportunities_ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.card_opportunities_title {
    font-size: 14px;
    text-align: -webkit-left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 45px;
}

.opportunities_box_position {
    font-size: 14px;
    text-align: -webkit-left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
}

.opportunities_box_viewmore {
    text-align: -webkit-right;
    text-decoration: underline;
    font-size: 14px;
}

.navbar_opportunities_cont {
    margin-bottom: 20px;
}

.opportunities_card_cont {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-gap: 0px;
    justify-content: center;
    padding: initial;
}

.opportunities_calendar_card>.rbc-calendar>.rbc-time-view>.rbc-time-content>.rbc-time-column>.rbc-timeslot-group {
    min-height: 45px;
}

/*
.recap_slot_card {
    background: #d2d2d2;
    width: auto;
    margin: 2px 8px 2px 0px;
    padding: 5px;
    border-radius: 6px;
    min-width: 205px;
    min-height: 100px;
    cursor: grab;
}*/
/*.recap_slot_card {
    background: linear-gradient(348deg, #eaeaea, transparent);
    width: auto;
    margin: 2px 8px 2px 2px;
    padding: 10px;
    border-radius: 6px;
    min-width: 185px;
    min-height: 100px;
    cursor: grab;
    box-shadow: 0px 0px 1px black;
}
*/
.recap_slot_card {
    border-radius: 15px;
    background: #f5f5f5;
    width: auto;
    margin: 10px 20px 9px 2px;
    padding: 15px;
    min-width: 175px;
    min-height: 100px;
    cursor: grab;
    box-shadow: 1px 3px 8px #b2b0b0;
    font-size: 14px;
}

.recap_slot_value {
    font-size: 13px;
    font-family: 'GucciSans-Book';
    margin-top: -3px;
    margin-bottom: 3px;
}

.opportunities_input:disabled {
    cursor: no-drop;
    background-color: #f5f5f5;
    color: #a5a5a5
}

.disabled_text_box_cont {
    width: 46%;
    margin: 5px 2%
}

.disabled_text_box_cont_des {
    width: 92%;
    margin: 5px 2%
}

.bi-heart:active {
    width: 14px;
    height: 14px;
}

.bi-heart-fill:active {
    width: 14px;
    height: 14px;
}

.opportunities_card {
    border-radius: 20px;
    padding: 15px;
    background-color: #f5f5f7;
    width: 260px;
    min-width: 260px;
    height: 130px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: -webkit-left;
}

.opportunities_card_description {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 10px;
    height: 60px;

}

.opportunities_detail_cont {
    border-radius: 20px;
    background-color: #f5f5f7;
    width: 100%;
    text-align: -webkit-left;
}

.opportunities_detail_box_container {
    display: flex;
}

.opportunities_detail_box {
    background-color: #fff;
    margin: 0 5px;
    height: 130px;
    align-content: center;
    padding: 0 40px;
}

.show_interest_button {
    background-color: #fff;
    width: fit-content;
    padding: 5px 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px #bfbfbf;
    font-size: 14px;
    font-family: "GucciSans-Bold";
    margin-right: 20px;
}

.delete_button {
    background-color: #000;
    color: #fff;
}

.applied_button {
    background-color: #000;
    color: #fff;
    width: fit-content;
    padding: 5px 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px #bfbfbf;
    font-size: 14px;
    font-family: "GucciSans-Bold";
    margin-right: 20px;
}

.opportunities_detail_box_title {
    font-size: 14px;
    padding-top: 2px;
    margin-left: 5px;
}

.opportunities_input {
    width: 100%;
    margin-top: 0px;
    border: 1px solid #bfbfbf;
    padding: 0 5px;
    font-size: 14px;
    height: 33px;
    padding: 0 5px;
    border-radius: 5px;
}

.opportunities_input:invalid {
    border-color: #900;
}

/* Chrome, Safari, Edge, Opera */
.opportunities_input::-webkit-outer-spin-button,
.opportunities_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.opportunities_input[type=number] {
    -moz-appearance: textfield;
}

.opportunities_datepicker {
    border-bottom: 0px !important;
    border: 1px solid #bfbfbf !important;
    border-radius: 5px;
    width: 100%;
    text-align: -webkit-left;
    padding-left: 30px !important;
    height: 33px;
}

.opportunities_input:focus {
    outline: none !important;
}

.opportunities_select {
    width: 100%;
    border: 0;
    height: 33px;
    font-size: 14px;
    margin-top: -1px;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    padding-top: 0px !important;
    padding-left: 4px;
}

div.opportunities_select.pt-1>div>div {
    padding: 2px 0 !important;
}

div.opportunities_datepicker_container>.react-datepicker-wrapper {

    width: 100% !important;
    padding-right: 20px;

}

.opportunities_datepicker_feedback {
    width: 32% !important;
}

.opportunities_description {
    margin-top: 10px;
}

div.quill.opportunities_description>div.ql-container.ql-snow {
    font-size: 16px !important;
}

div.quill.opportunities_description>div.ql-container.ql-snow>div.ql-editor p {

    line-height: 1.7;
}

.opportunities_search_input {
    display: inline-block;
    width: 300px;
    height: 30px;
    border: none;
    outline: none;
    padding: 4px 4px 4px 35px;
    vertical-align: middle;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
    left: -20px;
    font-size: 14px;
}

.opportunities_search_input:focus {
    outline: 0px;
}

.error_span {
    font-family: 'GucciSans-Book';
    font-style: italic;
    font-size: 13px;
}

#search-icon {
    position: relative;
    left: 8px;
    top: -4px;
    fill: #bfbfbf;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
    z-index: 1;
}

.opportunities_question_table {
    background-color: #fff;
    padding: 5px;
}

.opportunities_save_button {
    background-color: #000;
    border-radius: 20px;
    padding: 5px 20px;
    font-family: "GucciSans-Book";
    color: #fff;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
}

.opportunities_table_user_icon {
    min-width: 30px;
    min-height: 30px;
    background-color: #bfbfbf;
    border-radius: 20px;
    margin-right: 10px;
}

.opportunities_interested_table {
    width: 100%;
}

.opportunities_interested_table>tr>td {
    border: 1px solid #d4d4d4;
    background-color: #fff;
    padding: 5px 10px;
}

.opportunities_table_icon_cell {
    text-align: -webkit-center;
}

.opportunities_interested_select>div>div>div {
    cursor: pointer;
    color: black;
}

.opportunities_interested_select {
    width: 150px;
    font-size: 14px;
    margin-right: 20px;
    top: -4px;
    border-radius: 10px;
    cursor: pointer;
}

.opportunities_slot_select {
    width: 300px;
}

.opportunities_form_modal_body {
    margin-top: 10px;
    height: calc(100vh - 200px);
    overflow: auto;
    overflow-x: hidden;
}

.opportunities_send_email{
    height: 35vh;
}

.opportunities_action_button_container {
    float: right;
    margin-top: 30px;
}

.opportunities_action_button {
    background-color: #000;
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    width: fit-content;
}

.opportunities_action_white_button {
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    width: fit-content;
    box-shadow: 0 3px 6px #bfbfbf;
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
    cursor: pointer;
}

.radio-item input[type='radio'] {
    display: none;
    cursor: pointer;
}

.radio-item label {
    color: #000;
    font-weight: normal;
    cursor: pointer;
}

.radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    left: -5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #000;
    cursor: pointer;
    /*background-color: transparent;
    */
}

.opportunities_table_legend{
    display: none ;
}

.radio-item input[type=radio]:checked+label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #000;
    cursor: pointer;


}

.opportunities_question_row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.rating_question_number {
    border: 1px solid black;
    padding: 5px;
    margin: 3px;
    border-radius: 5px;
    display: block;
    width: 35px;
    min-width: 35px;
    text-align: -webkit-center;
    max-width: 35px;
}

.rating_question_number_selected {
    background-color: #000;
    color: #fff;
}

.likert_questions_options {
    margin-right: 10px;
    font-size: 12px;
}

.multiselect_admin {
    -webkit-text-fill-color: black;
    text-align: -webkit-left;
}

.opportunities_select_disabled {
    background-color: #f5f5f5 !important;
}

.multiselect_admin_disabled {
    --rmsc-bg: #f5f5f5 !important;
    -webkit-text-fill-color: #aaaaaa !important;
}

.rmsc {
    --rmsc-main: #bfbfbf !important;
    --rmsc-hover: #fff !important;
    --rmsc-selected: #e9e9e9 !important;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px;
    --rmsc-radius: 4px;
    --rmsc-h: 38px;
}

div>div.rmsc.multiselect_admin>div>div.dropdown-content>input[type=checkbox] {
    accent-color: black;
}

/* share to go */

 .share_to_go_see_more_container{
        margin-top: 15px;
    }

.s2g_button {
    width: 300px !important;
    padding: 2px 10px;
}

.share_to_go_titleday{
    text-align: left;
    width: 54%;
    margin-top: 20px;
}

.share_to_go_calendar{
    width: 60%;
}

.share_to_go_padding{
    padding: 30px;
}

.share_to_go_document_icon {
    background-image: url('resources/img/s2g_document_icon.png');
    height: 80px;
    width: 80px;
    min-width: 80px;
    background-size: contain;
    background-repeat: no-repeat;
}

.share_to_go_documents_list{
    display: flex;
}

.a-booked {
    background-color: #ededed !important;
    border-radius: 50% !important;
    font-size: 16px;
}

.share_to_go_img{
    height: 70px;
    border-radius: 10px;
    padding: 10px;
    place-content: center;

}

.share_to_go_doc_button{
    background-color: #000;
    width: 200px;
    padding: 10px;
    color: white;
    border-radius: 20px;
}

.share_to_go_img_title{
    text-transform: uppercase;
    font-size: 24px;
    color: #4b000a;
}

.share_to_go_form_line{
    margin-top: 10px;
    justify-items: center;
}

.share_to_go_select_width{
    width: 54%; 
}

.share_to_go_card_container {
    /*
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
    grid-gap: 0px;
    justify-content: center;
    padding: initial;
*/
}

.share_to_go_datepicker {
    border-bottom: 0px !important;
    border: 1px solid #bfbfbf !important;
    border-radius: 5px;
    width: 300px;
    text-align: -webkit-left;
    padding-left: 30px !important;
    height: 33px;
}


.share_to_go_datepicker>.react-datepicker__tab-loop {
    position: absolute !important;
    top: auto !important;
}


div.share_to_go_form_line > div > div > div.react-datepicker-wrapper{

    width: 100% !important;
    padding-right: 20px;

}

.share2go_card_line {
    margin-bottom: 2px;
}

.share_to_go_card {
    border-radius: 20px;
    padding: 20px;
    background-color: #f5f5f7;
    width: 270px;
    min-width: 230px;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    margin-bottom: 15px;
    font-size: 12px;
    text-align: -webkit-left;
    height: 220px;
    position: relative;
}

.share_to_go_card_deleted {
    color: #a2a2a2;
}

.share_to_go_status_card_cont{
    width: 100%;
    position: absolute;
    left: 0;
    padding: 0 20px;
    bottom: 20px;
}

.share_to_go_status_card {
    height: 20px;
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    text-align: -webkit-center;
    padding: 2px 0;
}

.status_deleted{
    background-color: #a2a2a2 !important;
}

.share2go_accordions {
    background-color: transparent !important;
    margin-bottom: 30px;
}

.share_to_go_documents_container{
    margin: 20px 0;
}

.share_to_go_documents_card {
    text-align: -webkit-left;
    padding: 15px 13px;
    /*background: linear-gradient(180deg, #000 0%, rgba(75, 0, 10, 1) 100%);*/
    background-color: #4b000a;
    height: 260px;
    width: 260px;
    min-width: 260px;
    border-radius: 20px;
    margin: 0 auto;
    color: #fff;
    cursor: pointer;
}

.share2go_reservations_string{
    font-size: 14px;
}

.share_to_go_card_icon{
    text-align: -webkit-center;
    margin-top: 35px;
    margin-bottom: 40px;
}

.share_to_go_card_description{
    font-size: 13px;
    color: #c9b2b5;
}

.share_to_go_buttons_container_padding{
    padding: 20px;
}

.share_to_go_buttons_container{
    width: 550px;
}

.share_to_go_section_line_title{
    text-align: -webkit-left;
    border-bottom: 1px solid #b4b4b4;
    margin-left: 10px;
    margin-bottom: 10px;
}

.share_to_go_section_line_accordion{
    margin-bottom: -50px; 
    margin-top: 20px;
}

.share_to_go_book_slot_container{
    width: 54%;
    border-left: 2px solid black;
    padding-left: 10px;
}

.share_to_go_book_slot{
    font-size: 14px;
}

#isAcceptedCheckbox:checked {
  accent-color: #000;
}

.share_to_go_message {
    text-align: -webkit-left;
    color: #b7b7b7;
    width: 85%;
    margin-top: -20px;
    font-size: 13px;
}


@media (max-width: 2200px) {

    .home_title {
        width: 86%;
    }
}

@media (min-width:1900px) {
    .countdown_container_string {
        margin-bottom: 27%;
    }
}


@media (max-width: 1800px) {

    .gym_info_container {
        margin-right: 0;
        margin-left: 5px;
    }

}

@media (max-width: 1700px) {

    .course_page_info_title {
        font-size: 18px;
    }

    .course_page_info_subtitle {
        font-size: 14px;
    }

    .gym_info_container_hours {
        margin-left: 0;
    }

    .gym_info_hours {
        margin-right: 0;
        margin-left: 2px;
        padding: 0.4rem;
    }

    /* meeting room */

    .mr_filter_header_container {
        display: grid;
        height: 110px;
        margin-bottom: 10px;
    }

    #mr_filter_header_right {
        width: 100%;
    }

    #mr_filter_header_left {
        width: 100%;
    }

    .mr_datepicker_container {
        margin-left: 20px;
    }

    .mr_timepicker {
        margin-left: 0px;
        margin-right: 30px;
    }

    .meeting_room_switch_label {
        margin-left: 1px !important;
    }

    /* meeting room detail */


    .meeting_room_detail_right_header_container {
        display: grid;
        justify-content: center;
    }

    .meeting_room_detail_left_container {
        display: block;
    }

    .meeting_room_detail_central_header {
        width: 100%;
    }

    .meeting_room_detail_card_img {
        width: 260px;
        height: 170px;
    }

    .meeting_room_manager_container {
        margin-left: 15px;
    }

    .meeting_room_detail_name_container {
        margin: 0 16px;
    }

    .meeting_room_detail_favourite {
        margin-left: 115px;
    }

    .meeting_room_detail_right_header {
        position: absolute;
        right: 50px;
    }

    .meeting_room_vertical_line_right {
        border-left: 0px;
        margin-left: 0px;
    }

}

@media (max-width: 1600px) {
    .countdown_container_string {
        margin-bottom: 120px;
    }

    /* ready to grow */

    .booked_byme_slot_button,
    .booked_slot_button,
    .book_slot_button {
        font-size: 12px;
        width: 105px !important;
        min-width: 105px;
    }

    .slot_button_container {
        width: 150px !important;
        margin-left: auto;
    }

    .gucci-text-bold-s {
        font-size: 12px;
    }

    .book_button {
        font-size: 12px;
        width: 105px !important;
    }

    .book_button_disabled {
        font-size: 12px;
        width: 105px !important;
    }

    .remove_booking {
        font-size: 12px;
        width: 105px !important;
    }

    .remove_waiting {
        font-size: 12px;
        width: 105px !important;
    }

    .filters_container_admin {
        width: 300px;
        left: calc(97% - 300px);
    }

    .label {
        width: 55px;
        margin-top: 10px;
    }


    .filter_text {
        width: 30%;
    }

    #search_reset {
        margin-left: -7%;
        margin-top: 7px;
    }

    .reservation_course_dx {
        width: 40%;
    }


    /* wellness lab */

    .course_info_manage {
        margin-top: 5px
    }

    .info_header {
        height: 200px;
    }

    .course_header {
        height: 200px;
    }
}

@media (max-width: 1500px) {

    @keyframes openNotificationBar {
        from {
            right: 0px;
        }

        to {
            right: 250px;
        }
    }

    /*
    .notificationBar {
        width: 250px;
    }

    .notificationContent {
        width: 250px;
    }

    .notificationContentClosed {
        width: 250px;
        animation: closeNotificationBar 1s forwards;
    }*/

    @keyframes closeNotificationBar {
        from {
            background-color: none;
            right: 250px;
        }

        to {
            right: -300px;
        }
    }

    /* .tutorial_container {
        margin-top: 80px;
    }*/


    .filters_container {
        left: calc(100% - 400px);
    }


    .empty_row {
        display: grid;
        place-items: center;
    }

    .info_utili_title_cont {
        height: 66px !important;
        width: 90%;
        margin-top: 0;

    }

    .timetable_canteen {
        margin-top: 25px
    }

    .pm_info_row {
        text-align: -webkit-center;
    }

    .timetable {
        text-align: -webkit-left;
    }

    .home_menu {
        width: calc(100% - 250px);
        display: inline-flex;
    }

    .leftbar {
        width: 250px;
        margin-top: 60px;
    }

    .leftbar_closed {
        width: 250px;
        background-color: #e5e4e4;
        margin-top: 60px;
    }

    .location_info_container {
        width: 100%;
    }

    /*
    .canteen_global_container,
    .bookshelf,
    .meeting_room_container,
    .vr_global_container,
    .single_module_container,
    .custom_page_container,
    .deskbooking_container,
    .calendar_container_agenda,
    .wellness_container {
        margin-top: 80px;
    }
    */

    .pm_info_container_bookshelf_sm {
        margin-top: 0;
    }

    .home_title {
        width: auto;
    }

    .wellness_admin_filters_container {
        margin-left: 30px;
    }

    .navbar_container {
        /*  z-index: 16;*/
        left: 0;
    }

    .step_bar {
        width: 250px;
    }

    .change_buttons {
        width: 250px;
    }

    #ChooseDefaultFl {
        width: calc(100% - 250px);
        left: 250px;
        animation: rightslideinmax_s;
    }

    .ProfileBox {
        animation-name: rightslideinprofile_s;
        width: calc(100% - 258px);
    }

    .CloseProfileBox {
        width: calc(100% - 258px);
        animation-name: rightslideinprofile_s;
    }

    .user_text {
        width: 250px;
    }

    .back_left_bar {
        margin-left: 8px;
    }

    .ReservationDetailsfixed {
        width: 603px !important
    }

    /* wellness */

    .progressbar {
        margin-left: 15px;
        width: 65px;
        height: 65px;
    }

    .today_info_container {
        display: none;
    }

    /* bookshelf */

    .bookshelf_detail_header_container {
        display: block !important;
        padding-bottom: 100px;
    }

    .bookshelf_detail_header_container_single_module {
        display: block !important;
        padding-bottom: 100px;
    }

    .bookshelf_detail_cover_container {
        text-align: -webkit-center;
    }

    .bookshelf_detail_cover_container_single_module {
        text-align: -webkit-center;
        margin-bottom: 15px;
    }


    .bookshelf_detail_button_single_module {
        margin-left: 0;
    }

    .bookshelf_detail_title_container {
        overflow: hidden;
        height: auto;
    }

    .bookshelf_detail_title_container_single_module {
        overflow: hidden;
        height: auto;
        margin-left: 25px;
    }

}

@media (max-width: 1400px) {

    .desk_guide_page {
        width: 400px;
        height: 70vh;
    }

    
    .opportunities_table_legend {
        display: block;
        margin-bottom: 10px;
        color: #818181;
        width: 75%;
    }


    .day_card {
        height: calc(100vh - 100px);
    }

    .card_day_container {
        height: calc(100vh - 180px);
    }

    .today_card {
        height: calc(100vh - 115px);
    }

    .central_component_wellness_admin {
        min-height: 100vh !important;
    }

    .card_days_overflow {
        height: calc(100vh - 100px);
    }

    /*
    */
    body {
        -moz-transform: scale(0.8, 0.8);
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        overflow: hidden !important;
        width: 125%;
        /* to compensate for the 0.8 scale */
        transform-origin: 0 0;
        /* to move it back to the top left of the window */
    }

    .popover {
        display: none !important;
    }

    .rbc-calendar {
        -moz-transform: scale(1.25, 1.25);
        -ms-transform: scale(1.25);
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        overflow: hidden !important;
        width: 80%;
        margin-top: 110px;
        padding-bottom: 60px;
    }

    /*first cell*/
    .opportunities_calendar_card>div.rbc-calendar>div.rbc-time-view>div.rbc-time-header.rbc-overflowing>div.rbc-label.rbc-time-header-gutter {
        min-width: 53px !important;
        max-width: 53px !important;
    }

    .rtg_calendar_card>div.calendar_card>div>div.rbc-time-view>div.rbc-time-header.rbc-overflowing>div.rbc-label.rbc-time-header-gutter {
        min-width: 59px !important;
        max-width: 59px !important;

    }

    /*time label font*/
    .opportunities_calendar_card>div.rbc-calendar>div.rbc-time-view>div.rbc-time-content>div.rbc-time-gutter.rbc-time-column>div>div>span {
        font-size: 14px !important;
    }

    /*button group calendar*/
    .opportunities_calendar_card>div.rbc-calendar>div.rbc-toolbar {
        font-size: 14px !important;
    }

    #root {
        height: 125vh;
        overflow-x: auto;
    }

    body>div>.login {
        height: 125vh !important;
    }

    .other_location_menu_cont {
        height: calc(100vh - 170px);
    }

    .sidemenu_cont {
        height: calc(100vh - 170px);
    }

    .step_bar_mobile_up {
        width: calc(100% - 450px);
    }

    .map_style {
        width: calc(100% - 150px) !important;
    }


    .logo_preview {
        width: 250px;
        height: 250px;
        /*
        top: calc(35% - 125px);
        left: calc(50% - 125px);
        */
    }



    .ReservationDetailsfixed {
        max-width: 436px;
        left: 78%;
    }


    .course_page_info_title {
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .course_page_info_subtitle {
        font-size: 12px;
    }



    /* READY TO GROW */

    .ready-to-grow-calendar {
        width: 100%;
    }

    .slot_list_container {
        width: 100%;
    }

    #title-ready-to-grow {
        width: 90%;
    }


    /* meeting room */


    .filter_board_options_container {
        display: flex;
        flex-flow: wrap;
    }

    .filter_board_column {
        border-left: 0px;
        height: fit-content;
        padding: 0px;
    }


    /* meeting room detail */

    .meeting_room_detail_card_name_container {
        display: contents;
    }

    .meeting_room_detail_central_header {
        display: block;
    }

    .meeting_room_detail_favourite {
        margin-left: auto;
    }

    .meeting_room_manager_container {
        margin-left: 20px;
    }


    .meeting_room_detail_right_header {
        position: inherit;
    }

    .meeting_room_detail_card_img_container {
        text-align: -webkit-center;
    }

    .meeting_room_detail_card_img {
        width: 400px;
        height: 250px;
    }


}

@media (max-width: 1300px) {
    .event_menu_bottom {
        height: calc(95vh - 32vh) !important
    }

    /* wellness lab */

    .course_page_info_title_manage {
        display: block;
    }

    .flag_complete {
        margin-left: 0px;
        width: fit-content;
    }

    .progressbar_container {
        display: none;
    }

    #list_container_trainer {
        width: 95%;
    }

    .name_manage_card {
        font-size: 12px;
    }

    .timestamp_manage_card {
        font-size: 12px;
    }


    @keyframes login_animation {
        from {
            width: 0%;
            font-size: 0px;
        }

        to {
            width: 25%;
            font-size: 13px;
        }
    }

    .login-button {
        font-size: 13px;
    }

    .center_menu_s {
        width: 48%;
    }

    .right_menu {
        width: 52%;
    }

    /* canteen */

    .canteen_datepicker {
        font-size: 12px;
    }

    .canteen_date_tag {
        font-size: 12px;
        height: fit-content;
        margin-top: 5px;
    }

    /* ready to grow */
    .ready-to-grow-calendar>.e-calendar,
    .e-bigger.e-small .e-calendar {
        max-width: 100% !important;
    }

    /* meeting room */

    .meeting_room_line {
        padding: 15px 0;
    }

    .meeting_room_capacity_container {
        max-width: 20px;
        width: 20px;
        justify-content: right;
    }

    .mr_icon_line_container {
        padding: 10px 0;
        place-content: center;
    }

    .meeting_room_name_container {
        width: 60%;
        text-align: -webkit-left;
    }

}


@media (max-width: 1200px) {
    .countdown_container_string {
        margin-bottom: 100px;
    }

    /* ready to grow */

    .slot_subtitle {
        display: grid;
    }

    .slot_icon {
        width: 50px;
        height: 50px;
        min-width: 50px;
    }

    .slot_hr_name {
        font-size: 22px;
        margin-top: 8px;
    }


    .booked_byme_slot_button,
    .booked_slot_button,
    .book_slot_button {


        font-size: 10px;
        width: 90px !important;
        min-width: 90px;
        line-height: 10px;
        padding: 10px 7px;
    }

    .slot_button_container {
        width: 125px !important;
        margin-left: auto;
    }



    #search_reset {
        margin-left: -10%;
    }

    .book_button {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .book_button_disabled {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .remove_booking {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .remove_waiting {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .desk_tutorial_page,
    .desk_guide_page {
        width: 330px;
        height: 670px;
    }

    .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
        overflow: hidden !important;
    }

    /* meeting room */

    .mr_filter_bottom {
        display: grid;
    }

    .mr_filter_buttons_container {
        justify-content: left;
    }

    .meeting_room_detail_card_img {
        width: 300px;
        height: 250px;
    }

    .meeting_room_map {
        min-width: 40vh;
    }

    .logo_preview {
        width: 200px;
        height: 200px;
    }
}


@media (max-width: 1100px) {


    .countdown_text {
        font-size: 12px;
    }

    /* ready to grow */

    .slot_subtitle {
        font-size: 13px;
    }

    .slot_icon {
        width: 40px;
        height: 40px;
        min-width: 40px;
    }

    .slot_hr_name {
        font-size: 18px;
        margin-top: 5px;
    }

    .slot_button_container {
        margin-left: 1%;
    }

    /* opportunities */

    .opportunities_box, 
    .admin_opportunity_box,
    .in_approval_opportunity_box {
        width: 205px;
        min-width: 205px;
    }

    .opportunities_box_description_notag, .opportunities_box_description {
        width: 165px;
    }

    .card_opportunities_title{
        width: 140px; 
    }

    .opportunities_carousel_content{
        place-content: center;
        padding-left: 0 !important;
    }

}

@media (max-width: 1000px) {

    .today_card {
        height: calc(100vh - 175px);
    }

    body {
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        overflow: auto;
        width: 100%;
        /* to compensate for the 0.8 scale */
        transform-origin: 0 0;
        /* to move it back to the top left of the window */
    }

    #root {
        height: auto;
        overflow-x: hidden;
    }

    body>div>.login {
        height: 100vh !important;
    }

    #dropdownMenuButtonAvailable {
        background-color: #e5e4e4;
    }

    .opportunities_global_container {
        margin-top: 70px;
    }

    /**/
    .opportunities_info_text {
        margin-top: 80px;
    }

    .opportunities_info_button {
        margin-top: 10px;
        width: 95%;
    }

    .opportunities_box_position {
        max-width: 110px;
    }

    .opportunities_box_position_view_all {
        width: 100% !important;
        max-width: fit-content;
    }

    .opportuinities_info {
        margin-top: 60px;
        width: 100%;
    }

    .opportunities_box_viewall {
        width: 100% !important;
        margin-left: 0;
    }

    .opportunities_box_description_notag_viewall {
        width: 100%;
    }

    .opportunities_faq_cont {
        height: calc(100vh - 270px);
        overflow-y: auto;
        width: 94%;
    }

    .opportunities_main_image_title {
        display: none;
    }

    .opportunities_label_feedbackform {
        text-align: -webkit-left;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .opportunities_feedbackform_card {
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        margin: 10px 0;
        box-shadow: 0 3px 6px #bfbfbf;
    }

    .create_opportunities_button {
        display: none;
    }

    .opportunities_box,
    .admin_opportunity_box,
    .in_approval_opportunity_box {
        width: 190px;
        min-width: 190px;
        height: 160px;
    }

    .opportunities_box_description {
        width: 150px;
    }



    .opportunities_box_description_viewall {
        width: 100% !important;
        margin-top: 0;
    }

    .opportunities_content {
        margin-top: 80px;
        padding: 10px;
        padding-left: 0;
        padding-right: 0;
    }

    .opportunity_mobile_content {
        height: calc(100vh - 250px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;
    }

    .opportunities_info_cont {
        padding: 10px !important;
    }

    .opportunities_info_text_mobile {
        font-size: 12px !important;
    }

    .opportunities_info_text {
        margin-bottom: 20px;
        margin-top: 13vh;
    }

    .opportunities_infopage_mobile {
        height: calc(100vh - 340px);
        overflow: auto;
        padding-bottom: 50px;
    }

    .opportunities_boxes_container {
        justify-content: left !important;
    }


    .opportunities_card_cont_viewall {
        max-height: calc(100vh - 300px);
        overflow: auto;
        grid-template-columns: repeat(auto-fit, minmax(91%, max-content));
    }

    .opportunities_feedback_content {
        margin-top: 90px;
        height: calc(100vh - 210px);
        overflow: auto;
    }

    div.opportunities_content.opportunities_feedback_content>div:nth-child(2)>div:nth-child(3)>div>div.react-datepicker-wrapper>div>input {
        background-color: white !important;
    }

    .rating_question_number {
        padding: 0px;
        width: 25px;
        min-width: 25px;
        max-width: 25px;
    }

    .show_interest_button {
        margin-right: 0px;
        margin-top: 5px;
        font-size: 12px;
        padding: 5px 15px;
    }

    .opportunities_form_modal_body {
        width: 100%;
    }

    .opportunities_interested_select>div>div>div {
        color: black;
    }

    .opportunities_detail_cont_mobile{
          height: calc(100vh - 210px);
        overflow: auto;
    }

    /*
    .opportunities_overview_description {
        height: calc(100vh - 420px);
        overflow: auto;
        padding-bottom: 20px;
    }
    */

    .opportunities_mobile_width {
        width: 50%;
    }

    .opportunities_line_info_mobile {
        display: flex;
        margin-top: 5px;
    }

    .opportunities_detail_cont {
        margin-top: 10px;
        padding: 10px 0;    
    }

    .opportunities_detail_padding {
        padding: 10px;
    }

    .opportunities_detail_box_container {
        display: block;
    }

    .opportunities_detail_box {
        height: auto;
        padding: 5px 18px;
    }

    .opportunities_detail_box_title {
        font-size: 12px;
    }

    .view_all_link {
        margin-right: 0px;
    }

    .opportunities_section_title {
        margin-left: 10px;
        text-align: -webkit-left;
    }

    .opportunities_section {
        min-height: 225px;
    }

    .card_opportunities_title {
        width: 130px;
    }

    .card_opportunities_title_viewall {
        width: 100% !important;
    }

    .feed_component {
        margin-top: 40px;
    }

    .feed_card {
        align-items: center;
        border-radius: 10px;
    }

    .feed_thumb {
        border-radius: 10px;
        width: 90px;
        height: 90px;
        min-width: 90px;
    }

    .feed_component_container {
        padding: 50px 20px 0px 20px;
    }

    .feed_mobile_overflow {

        height: calc(100vh - 200px);
        overflow: auto;
    }

    .feed_content {
        width: calc(100% - 140px);
        /* margin: 0 10px; */
        margin-right: 10px;
        margin-left: 20px;
    }

    .feed_title {
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .feed_list_container {
        height: calc(100vh - 200px);
        padding: 0 50px 30px 50px;
        overflow: auto;
    }

    .feed_detail_container {
        height: calc(100vh - 200px);
        overflow: auto;
    }

    .feed_grouplist_container {
        height: calc(100vh - 200px);
        padding: 0 50px 30px 50px;
        overflow: auto;
    }

    .feed_description_content {
        border-radius: 10px;
        margin: 10px 50px;
    }

    .audio_date {
        padding: 0;
        margin: 0;
        margin-left: auto;
    }

    .feed_video_container {
        padding: 10px 0;
        text-align: -webkit-left;
        background-color: #f5f5f7;
        margin: 10px 20px;
        border-radius: 10px;
    }

    div.feed_video_container>div:nth-child(1)>video {
        border-radius: 10px;
    }

    .feed_video_title {
        display: none;
    }

    .feed_video_title_mobile {
        display: block;
        text-align: -webkit-center;
    }

    .feed_video {
        border-radius: 10px;
        margin-top: 20px;
    }

    .feed_description_detail {
        font-size: 14px;
    }

    .feed_buttons_container {
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .feed_button {
        border-radius: 5px;
    }

    .feed_button_selected {
        border-radius: 5px;
    }

    .audio_component_width {
        width: 100% !important;
        padding: 0 20px;
    }

    .multimedial_content {
        background-color: #f5f5f7;
        margin: 20px 50px;
        border-radius: 10px;
    }

    .rhap_container {
        background-color: #f5f5f7 !important;
    }

    .sidemenu_mobile {
        /*
        */
        padding-bottom: 100px !important;
    }


    .text_sub_menu_container {
        width: 80%;
        text-align-last: center;
    }

    .extra_info_row {
        margin-top: -13px;
    }

    .ReactModal__Content {
        border-radius: 4px !important;
    }

    .profile {
        width: 100%;
        margin-left: 0;
        margin-top: 46px;
        z-index: 200;
        position: absolute;
        background-color: #e5e4e4;
        height: 100vh;
    }

    .profile_container {
        padding-top: 20px;
    }

    .welcome_string {
        margin-top: 0px;
        margin-bottom: 60px;
    }


    .bookshelf_section_books_all_single_module,
    .bookshelf_section_books_all {
        height: calc(100vh - 360px);
        overflow: auto;
    }

    .book_cover_img {
        border-radius: 0px;
    }

    .book_cover_img_sigle_module {
        border-radius: 0px;
        margin: 5px 15px 5px 0;
    }

    .button_sub_menu {
        font-family: "GucciSans-Bold";
        font-size: 15px;
        color: black;
        border: 0;
        height: 45px;
        display: table-cell;
        vertical-align: middle;
        text-align: -webkit-left;
        border-radius: none;
        box-shadow: none;
    }

    .bookshelf_container {
        height: 85vh;
        padding-bottom: 100px;
        overflow: auto;
    }

    .canteen_container {
        height: 85vh;
    }

    .profile_container {
        height: 85vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .carousel_cont {
        position: relative;
        top: -30px;
        width: auto;
    }

    .central_component {
        width: 100%;
        height: auto;
        overflow: hidden;
        padding-bottom: 0;
        position: static;
    }

    .central_component_width_mobile {
        width: 100%;
        box-shadow: none !important;
        background-color: transparent;
        border-radius: 0 !important;
        height: auto !important;
    }


    .central_component_wellness_admin {
        width: 100%;
        float: none;
    }

    .carousel.carousel-slider .control-arrow {
        height: calc(100% - 35px);
    }

    .carousel.carousel-slider .control-arrow:hover {
        filter: invert(1);
    }

    .carousel.carousel-slider {
        padding: 0 0 40px 0
    }

    .no_logo_menu {
        margin: 2% 0;
        width: 100%;
    }

    .carousel_contain {
        width: 50%;
    }

    .side_submenu_cont_mobile {
        height: calc(100vh - 200px) !important;
        margin-top: 20px !important;
    }

    .sidemenu_cont {
        height: calc(100vh - 330px);
        justify-content: center;
        position: static;
        width: auto;
        margin-top: -50px;
    }

    .sidemenu_mobile {
        max-width: 350px;
    }

    .other_location_menu_cont {
        position: static;
        width: auto;
        height: calc(100vh - 110px) !important;
    }

    .accordion_details_container,
    .other_loc_accordions {
        background-color: transparent !important;
    }

    .logo_menu_other_locations1 {
        height: 55px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    .text_menu_container_other_locations1 {
        height: 55px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
    }

    .text_menu1 {
        height: 55px;
    }

    .offices_logo1 {
        margin-top: 8px;
    }

    .home_page {
        padding: 0px;
        position: static;
    }

    /*.sidemenu_cont {
        justify-items: center;
        display: grid;
    }
*/
    .reservation_course_dx {
        display: none;
    }

    .reservation_course_button {
        display: none;
    }

    .reservation_course_sx {
        width: 100%;
    }



    .wellness_container {
        margin-top: 70px;
    }

    .navbar_container {
        padding: 0 10px 0 0;
    }

    /* whats new */

    .tutorial_dialog>.MuiDialog-container>.MuiPaper-root,
    .whats_new_dialog>.MuiDialog-container>.MuiPaper-root {
        max-width: 420px !important;
        width: 420px;
        border-radius: 5px;
    }


    .loader_ellipsis_web {
        margin-top: 0;
        padding-top: 0;
    }


    .notificationBar {
        width: 100%;
        animation: openNotificationMobile 1s forwards;
        z-index: 14;
    }

    .notificationContentClosed {
        width: 120%;
        max-width: 100%;
    }

    .tutorial_icon {
        right: 5px;
        top: 5px;
    }

    .tutorial_icon_readytogrow {
        position: absolute;
        right: 10px;
        top: -30px;
    }

    .tutorial_icon_canteen {
        position: absolute;
        right: 10px;
        top: -30px;
    }

    .tutorial_icon_bookshelf {
        position: absolute;
        right: 10px;
        top: -50px;
    }

    .tutorial_icon_bookshelf_sm {
        position: absolute;
        right: 15px;
        top: 3px;
        z-index: 14;
    }

    .tutorial_icon_wellness {
        position: absolute;
        right: 20px;
        top: 40px;
    }

    .tutorial_icon_info {
        position: absolute;
        right: 17px;
        top: -35px;
    }

    .tutorial_icon_sharetogo {
        position: absolute;
        right: 17px;
        top: 50px;
    }

    @keyframes openNotificationMobile {
        0% {
            left: -1000px;
        }

        100% {
            left: 0px;
        }
    }

    .notificationBar_closed {
        width: 100%;
        animation: closeNotificationMobile 1s forwards;
    }

    @keyframes closeNotificationMobile {
        0% {
            left: 100px;
        }

        100% {
            left: -100%;
        }
    }

    .notification {
        height: 75px;
    }

    .notificationContent {
        width: 100%;
        max-width: 1000px;
    }

    .notificationBar_closed {
        z-index: 13;
    }

    .notificationBar_closed_one {
        display: none;
    }

    .office_page_title {
        text-align: -webkit-center;
    }

    .business_travel_center {
        height: 85vh !important;
        overflow: auto;
        z-index: 1000;
        margin-top: 55px !important;
        padding-bottom: 145px;
    }

    .calendar_card {
        margin-top: 50px !important;
        margin-left: 20px;
        margin-right: 20px;
    }

    .calendar_card>.rbc-calendar>.rbc-toolbar {
        justify-content: left;
    }

    .rbc-calendar {
        height: calc(100vh - 200px) !important;
    }

    .empty_row {
        display: flex;
        place-items: center;
    }

    .info_utili_title_cont {
        height: 66px !important;
        width: 90%;
        margin-top: 0;

    }

    .info_utili_row {
        /* padding-top: 50px;*/
        padding-top: 10px;
    }

    .timetable_canteen {
        margin-top: 0px
    }

    .barcode_cont {
        display: block;
        width: 47px;
        background: #ffffff00;
        border-radius: 5px;
        height: 58px;
        margin-right: 7px;
    }

    #barcode_search {
        padding-top: 4px;
        display: block;
        padding-left: 7px;
        border-bottom: 1px solid #d5d5d5;
        border-right: 1px solid #d5d5d5;
        border-radius: 5px;
        height: 40px;
        padding-right: 7px;
    }

    .search_button_bookshelf {
        margin-right: 8px;
    }

    .offices_menu_center {
        margin-top: 55px !important;
    }

    /*LEFTBAR*/

    .leftbar {
        height: 110vh;
        background: #e5e4e4;
        margin-top: 96px;
    }

    .notification_num {
        right: 7px;
    }

    .leftbar_closed {
        z-index: 6;
        height: 110vh;
        top: 0;
        /*padding-top: 62px;*/
    }

    div.pm_info_row_timetable.selected_today>div.pm_info_label {
        color: rgb(255, 255, 255) !important;
        -webkit-text-fill-color: rgb(255, 255, 255);
        -webkit-text-stroke-color: rgb(255, 255, 255)#fff;
        text-decoration-color: rgb(255, 255, 255);
        -webkit-opacity: 1;
    }

    .left_bar_menu_container {
        height: calc(100vh - 380px);
        text-align: center;
    }

    .left_menu {
        padding-bottom: 50px;
        text-align: center;
        display: inline-grid;
    }

    .event_menu_bottom {
        margin-top: 8%;
    }

    .event_menu_bottom {
        height: calc(95vh - 20vh) !important;
    }

    .today_left_container {
        /*padding-bottom: 110px;*/
        padding-bottom: 50px;
    }

    .mobile_go_next {
        display: block;
    }

    .home_text_container {
        padding-top: 100px;
    }

    .home_text_container_right {
        padding-top: 100px;
    }

    .footer_course {
        display: none;
    }

    .arrow {
        margin-top: -45px;
        margin-left: 0px;
    }

    .sub_menu_mobile {
        display: block;
    }

    .back_button_right_menu_mobile {
        position: absolute;
        top: 11px;
        display: block;
        width: 90%;
        cursor: pointer;
        padding-left: 10px;
        left: 25px;
        color: white;
    }

    .admin_disabled {
        display: none;
    }

    /* ready to grow */

    .slot_global_container {
        padding-bottom: 100px;
    }

    .slot_icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
    }

    .slot_hr_name {
        font-size: 26px;
        margin-top: 10px;
    }

    .booked_byme_slot_button,
    .booked_slot_button,
    .book_slot_button {
        font-size: 15px;
        width: 130px !important;
        min-width: 130px;
    }

    .slot_button_container {
        width: 160px !important;
        margin-left: auto;
    }

    /* WELLNESS LAB */

    .wellnesslab_courses {
        margin-top: 80px;
    }

    .wellnesslab_courses_container {
        padding: 0;
    }

    .title_container {
        margin: 10px;
        justify-content: right;
    }


    .filters_container {
        animation: open_menu 0.3s forwards;
        position: absolute;
        left: calc(100% - 340px);
        width: 330px;
        top: 80px;
        z-index: 15;
        background-color: #f3f3f3;
        border: 3px solid #c5c5c540;
        border-radius: 5px;
    }

    .name_manage_card {
        font-size: 15px;
    }

    .timestamp_manage_card {
        font-size: 15px;
    }

    .course_page_info_title_manage {
        display: flex;
    }

    .flag_complete {
        margin-left: 10px;
        width: fit-content;
    }

    .gucci-text-bold-s {
        font-size: 15px;
    }

    .book_button {
        font-size: 15px;
        width: 130px !important;
    }

    .book_button_disabled {
        font-size: 15px;
        width: 130px !important;
    }


    .header_container {
        display: block;
        margin-left: 0px;
    }


    .reserv_menu_container {
        padding: 0;
    }

    .reserv_menu {
        margin-top: 40px;
        height: 93vh;
        width: 98%;
        padding-bottom: 150px;
    }

    .info_manage_container {
        width: 100%;
    }

    .loader_modal_managew_mobile_container {
        text-align: -webkit-center;
    }

    .loader_modal_managew_mobile {
        margin-top: 30% !important;
    }

    .statistic_container {
        padding-right: 10px;
    }

    .reserv_menu_manage {
        margin-top: -20px;
        width: 100%;
    }

    .course_header_manage {
        margin-top: 0;
        margin-bottom: 10px;
        width: 36%;
    }

    .remove_booking {
        font-size: 13px;
        line-height: 13px;
        width: 130px !important;
    }

    .remove_waiting {
        font-size: 13px;
        line-height: 13px;
        width: 130px !important;
    }

    .reservations_container {
        height: 52vh;
        overflow-y: auto;
        padding: 0;
        padding-bottom: 90px;
    }

    .card_day_container {
        padding-bottom: 100px;
        height: calc(100vh - 230px);
    }

    .card_days_container {
        margin-bottom: 90px;
    }

    .card_days_overflow {
        height: calc(100vh - 160px);
    }

    .courses_container {
        overflow-y: auto;
        height: 72vh;
        padding-bottom: 150px !important;
        margin-bottom: 100px !important;
        width: 98%;
        padding-left: 10px;
    }

    .course_description_title {
        margin-left: 0px;
    }

    .course_description_content {
        margin-left: 0px;
        padding: 5px 15px;
    }

    .calendar_title {
        margin-top: 20px;
    }

    .left_bar_menu_container_closed {
        overflow-y: hidden;
    }


    .info {
        width: 100%;
    }

    .course_info {
        width: 85%;
    }

    .course_header {
        width: 36%;
        margin-top: 20px;
        margin-top: 75px;
    }

    .info_header {
        width: 100%;
        margin-top: 20px;
        padding: 5px 15px;
    }

    .course_description_row {
        width: 100%;
        align-items: center;
        margin-top: 45px;
        padding: 5px 15px;
    }

    .course_description_container {
        padding: 5px;
    }

    .header_fixed {
        position: fixed;
        position: -webkit-sticky;
        width: 100%;
        background-color: #F0EFEF;
    }

    .wellness_lab_center_menu {
        height: 95vh !important;
    }

    .filters_container_admin {
        animation: open_menu 0.3s forwards;
        position: absolute;
        left: calc(88% - 330px);
        width: 330px;
        top: 115px !important;
        z-index: 5;
        background-color: #f3f3f3;
        border: 3px solid #c5c5c540;
        border-radius: 5px;
    }


    .back_to_calendar {
        visibility: visible;
        margin-left: -30px;
    }

    .gym_info_hours_mobile {
        display: none;
    }

    .gym_info_hours {
        display: block;
    }

    .reservation_container {
        display: block;
    }

    .progressbar_container {
        display: flex;
    }

    .progressbar {
        margin-left: 25px;
        width: 80px;
        height: 80px;
    }

    .reservation_title {
        width: 100%;
    }

    .map_info_container_mobile {
        width: 100%;
        margin-top: 1px;
    }

    .map_info_container_mob {
        width: 100%;
    }

    .course_page_info_title {
        font-size: 24px;
    }

    .course_page_info_subtitle {
        font-size: 22px;
    }

    .course_page_info_subtitle_manage {
        font-size: 20px;
    }

    .course_page_info_title_manage {
        font-size: 22px;
    }

    .manage_location {
        font-size: 18px;
    }

    .custom_page_container {
        overflow: auto;
        height: calc(100vh - 230px);
    }

    .gym_info,
    .canteen_global_container,
    .meeting_room_container,
    .vr_global_container,
    .single_module_container,
    .custom_page_container,
    .generic_menu_global_container,
    .deskbooking_container {

        margin-top: 110px;
    }

    .deskbooking_container {
        padding-top: 0;
    }

    .bookshelf {
        margin-top: 80px;
    }

    .bookshelf_margin_mobile {
        margin-top: 80px;
    }

    .generic_menu_global_title_container {
        text-align: -webkit-center;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .gym_info_image_container {
        width: 320px;
    }

    .map_info_icon_mobile {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        font-size: 15px;
        color: #fff;
        text-align: center;
        margin-right: 25px;
        background: rgb(141 152 170);
        font-family: 'GucciSans-Bold';
    }

    .map_info_name_mobile {
        color: #000000;
        font-family: "GucciSans-Bold";
        padding: 16px 0px 0px 2px;
        font-size: 22px;
        width: 100%;
        margin-top: -49px;
        text-align: -webkit-center;
    }

    .map_info_description {
        place-content: center;
        margin-top: 11px;
        min-height: 20px;
    }

    .map_building_info {
        align-self: center;
    }

    .go_to_container_mobile {
        margin-top: 11px;
        width: 95%;
        text-align: -webkit-right;
    }

    #box_description {
        position: absolute;
        width: -moz-fit-content;
        width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 5px !important;
        background: white;
        top: 88px;
        left: 11px;
        z-index: 10000;
        font-size: 12px;
        display: none;
        border-radius: 6px;
        color: #000000;
        text-align: -webkit-center;
        border: 1px solid #e0ded9;
        box-shadow: 2px 2px #cfcdcd;
        min-width: 160px;
        min-height: 77px;
    }

    .back_button_right_menu {
        display: block;
        position: absolute;
        top: 114px;
        width: 96%;
        cursor: pointer;
    }

    .reservation_header {
        margin-left: 0px !important;
    }

    .pm_info_container {
        display: block;
    }

    .pm_info_global_container {
        display: block;
        overflow-y: auto;
        height: 84vh;
        margin-top: 165px;
        width: 98%;
        padding-bottom: 100px;
    }

    .vr_global_container {
        margin-top: 80px;
        padding-bottom: 150px;
        margin-bottom: 140px;
        overflow: hidden;
        height: auto;
    }


    .vr_headsets_container {
        height: calc(100vh - 235px);
        padding-bottom: 100px;
    }

    .pm_bus_rides {
        margin: 0 5px;
    }

    .location_info_container {
        display: block;
        overflow-y: auto;
        height: calc(100vh - 130px);
        margin-top: 100px;
        width: 98%;
        padding-bottom: 100px;
        margin-left: 0px;
    }

    .ready_to_grow_container {
        height: 85vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 20px;
        margin-top: 100px;
    }


    .info_row {
        width: 100%;
    }

    .pm_info_submenu {
        padding-left: 20px;
    }

    .pm_info_bus_table {
        margin: 2% 10%;
        width: 80%;
    }

    .pm_changemakers_global_container {
        display: block;
        overflow: auto;
        height: 85vh;
        width: 98%;
        margin-top: 120px;
        padding-bottom: 150px
    }

    .gh_changemakers_global_container {
        display: block;
        overflow: auto;
        height: 85vh;
        margin-top: 165px;
        width: 98%;
        padding-bottom: 150px
    }

    .canteen_global_container {
        display: block;
        height: 85vh;
        margin-top: 50px;
        width: 98%;
        padding-bottom: 100px
    }

    .canteen_dates_container {
        margin-left: 0px;
    }

    .react-datepicker__input-container>input {

        background-color: black;
        text-align: center;
        color: white;
        border: 0;
        border-radius: 20px;
        padding: 5px 0px !important;
        width: fit-content !important;
        font-size: 13px !important;
    }

    .canteen_date_tag {
        padding: 5px 10px !important;
        width: fit-content !important;
        font-size: 13px !important;
    }

    .cs {
        text-align: -webkit-center;
    }

    .right_menu_mobile_dis {
        display: none;
    }

    .right_menu_mobile_dis_l {
        display: none;
    }

    .center_menu_s_mobile_dis {
        display: none;
    }

    /* BOOKSHELF */

    .category_margin {
        margin-top: 0;
    }

    .bookshelf_detail_title_container {
        margin-left: 0;
        padding: 10px 10px 0 0;
    }

    .bookshelf_search_input_single_module {
        width: 80%;
    }

    .bookshelf_search {
        margin-top: 50px;
    }

    .bookshelf_search_sm {
        margin-top: 80px;
    }

    #search_reset_bookshelf {
        margin-left: -5%;
        margin-top: 2px;
    }

    .bookshelf_detail_cover_container_single_module {
        margin-top: 15px;
    }

    .bookshelf_detail_container {
        margin-top: -50px;
        overflow: auto;
        height: 85vh;
    }

    .bookshelf_detail_container_sm {
        margin-top: -50px;
        overflow: auto;
        height: 85vh;
    }

    /*.bookshelf_detail_header {
        margin-top: 60px;
    }*/

    .bookshelf_detail_title_container_single_module {
        margin-right: 0;
        margin-left: 0;
        text-align: -webkit-left;
    }

    @keyframes fullscreen {
        0% {
            height: 30vh
        }

        100% {
            height: 70vh
        }
    }

    @keyframes hidescreen {
        0% {
            height: 30vh
        }

        100% {
            height: 47px
        }
    }

    @keyframes open_up {
        0% {
            height: 0px
        }

        100% {
            height: 200px
        }
    }

    .ProfileBox {
        padding: 50px 10px 95px 7px !important;
    }

    .map_search {
        display: none;
    }

    .back_button_map {
        width: 10%;
        padding-left: 5px;
        display: block;
    }

    .map_search_results {
        margin-left: -8px;
        margin-top: 63px;
    }

    .map_search_mobile {
        width: 97%;
        height: 50px;
        display: flex;
        z-index: 1;
        margin-left: 8px;
        position: absolute;
        margin-top: 8px;
    }

    #search_component {
        justify-self: left !important;
    }

    .full_screen {
        animation: fullscreen 0.6s forwards !important;
    }

    .hide_screen {
        animation: hidescreen 0.6s forwards !important;
    }

    .screen_settings {
        margin-top: 4px;
    }

    .directions_container_disabled {
        visibility: hidden;
    }

    .directions_container_mobile_disabled {
        display: none;
    }

    .step_title {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        margin-top: 2px;
    }

    .step_row_mobile {
        padding-top: 2px;
        padding-bottom: 2px;
        place-content: center;
    }

    .step_container_selected_mobile {
        background-color: #F0EFEF;
        cursor: pointer;
        text-align: -webkit-center;
        /* border-radius: 20px;*/
    }

    .step_container_mobile {
        text-align: -webkit-center;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .expand_icon_mobile {
        align-self: center;
        color: #000000;
        width: 5%;
    }

    .step_summary_mobile {
        width: 90%;
        text-align: -webkit-center;
    }

    .step_bar {
        display: none;
    }

    .step_bar_mobile {
        display: none;
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 100%;
    }

    @keyframes goUp {
        0% {
            height: 0;
        }

        100% {
            height: 200px;
        }
    }

    .step_bar_mobile_up {
        display: block;
        position: fixed;
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 230px;
        animation: goUp 1s;
        margin-left: 0;
    }

    .directions_search_mobile {
        text-align: left;
        text-align: -webkit-center;
        margin-top: 13px;
    }

    .direction_button {
        height: 40px;
        background-color: #000000;
        border-radius: 8px;
        width: 130px;
        font-family: 'GucciSans-Book';
        margin-right: 14px;
        margin-left: 14px;
        padding-left: 6px;
        padding-top: 7px;
        padding-right: 9px;
        text-align: -webkit-right;
        cursor: pointer;
        position: absolute;
        bottom: 17px;
        right: 18px;
        display: flex;
        z-index: 1;
        justify-content: center;
    }

    .return_button {
        height: 40px;
        background-color: #000000;
        border-radius: 8px;
        width: 130px;
        font-family: 'GucciSans-Book';
        margin-right: 14px;
        margin-left: 14px;
        padding-left: 6px;
        padding-top: 7px;
        padding-right: 9px;
        text-align: -webkit-right;
        cursor: pointer;
        position: absolute;
        bottom: 17px;
        left: 18px;
        display: flex;
        z-index: 1;
    }

    .direction_button_text {
        font-family: "GucciSans-Bold";
        color: white;
    }

    #directions_img {
        width: 24px;
        margin-left: 11px;
        height: 30px;
        background-image: url('./resources/img/directions1.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        cursor: pointer;

    }

    .steps_duration {
        margin-top: 11px;
    }

    .steps_duration_mobile {
        margin-top: 2px;
        width: 85%;
        font-size: 14px;
        text-align: -webkit-center;
    }

    .step_containers_mobile {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        width: 100%;
        background: #fbfbfb;
        bottom: 0px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        animation: open_up 0.6s forwards;

    }

    .steps_mobile {
        margin-top: 48px;
        margin-bottom: 20px;
    }

    .change_buttons_mobile {
        display: flex;
        width: -moz-fit-content;
        /* width: fit-content; */
        margin-top: 3px;
        margin-bottom: 16px;
        margin-right: 3px;
        margin-left: auto;
    }

    .next_button_mobile {
        height: 35px;
        background-color: #000000;
        border-radius: 8px;
        width: 130px;
        font-family: 'GucciSans-Book';
        margin-right: 10px;
        margin-left: 5px;
        padding-top: 6px;
        padding-right: 5px;
        text-align: -webkit-right;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        justify-content: right;
        color: white;
    }

    .prev_button_mobile {
        height: 35px;
        background-color: #000000;
        border-radius: 8px;
        width: 130px;
        font-family: 'GucciSans-Book';
        margin-left: 15px;
        margin-right: 5px;
        padding-top: 6px;
        padding-left: 0px;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        justify-content: center;
        color: white;
    }

    .prev_text_mobile {
        padding-top: 2px;
    }

    .next_text_mobile {
        padding-top: 2px;
    }

    .direction_header_mobile {
        display: flex;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        margin-left: 10px;
    }

    .directions_container_mobile {
        height: 48px;
        background-color: #f1f1f1;
        padding: 2px;
        display: flex;
        position: fixed;
        width: 100%;
        z-index: 1;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        border: 1px solid #cbc6c6;
    }

    .directions_container_start {
        height: 230px;
        background-color: #F0EFEF;
        padding: 2px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border: 1px solid darkgrey;
    }

    .back_mobile_button {
        width: 18%;
        text-align: -webkit-left;
        margin-left: 15px;
    }

    .next_mobile_button {
        width: 80%;
        text-align: -webkit-right;
        padding-right: 13px;
        margin-top: 13px;
    }

    .direction_header_mob {
        display: flex;
        width: 100%;
        height: 38px;
    }

    .logout-container-open {
        position: absolute;
        bottom: 80px;
        left: 0;
        width: 100%;
    }

    .Navbar {
        display: none !important;
    }

    .flex_menu {
        display: grid;
        max-height: 790px;

    }

    .map_style {
        color: black;
        width: 95% !important;
        height: 74vh !important;
        position: absolute;
        justify-self: center;
        z-index: 1;
        border: 1px solid #F0EFEF;
        box-shadow: 3px 2px #cfcdcd;
        border-radius: 24px;
        overflow: hidden;
        margin-left: 2.5%;
        margin-top: 70px;
        margin-right: 2.5%;
    }

    .my_booking {
        display: none;
    }


    /*CANTEEN*/


    .canteen_date_tag {
        font-size: inherit;
        margin-top: 0px;
    }

    @keyframes rightslideinprofilemin {
        0% {
            left: 100%;
        }

        100% {
            left: 0px;
        }
    }

    @keyframes rightslideinmin {
        0% {
            left: 100%;
        }

        100% {
            left: 0;
        }
    }

    .canteen_graph {
        width: 90%;
        height: 280px;
        margin: 10px 0 0 0;
        padding: 10px 0;
        background-color: #f6f6f6;
    }

    #ChooseDefaultFl {
        width: 100% !important;
        background: #F0EFEF;
        position: absolute;
        top: 0;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 80px;
        min-height: 100vh;
        animation-duration: 0.6s;
        animation-name: rightslideinmin !important;
        animation-fill-mode: forwards !important;
        z-index: 10000;
        height: 103vh;
    }

    .ProfileBox {
        animation-name: rightslideinprofilemin !important;
    }

    .CloseProfileBox {
        animation-name: rightslideinprofilemin !important;
    }

    @keyframes logout_animation_open {
        from {
            width: 0%;
        }

        to {
            width: 30%;
        }
    }

    .CloseOfficeDetailBox {
        top: 9px !important;
        right: 8px !important;
    }

    .logout-button {
        display: none;
    }

    .ReservationDetailsfixed {
        width: 100% !important;
        max-width: 1200px !important;
    }

    .Navbar .ProfileLogo {
        display: none;
    }

    .course {
        height: 88vh;
    }

    .course::-webkit-scrollbar-thumb {
        background: #000000 !important;
    }

    .top_course {
        margin-top: 80px;
    }

    .leftbar {
        width: 100vw;
        position: absolute;
        z-index: 13;
    }

    .footer_course {
        height: auto;
    }

    .leftbar_closed {
        width: 100vw;
        position: absolute;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .burger_menu {
        display: block;
        padding-left: 20px;
        cursor: pointer;
        padding-top: 13px;
        width: 50px;
    }

    .user_icon_top {
        display: block;
        margin: auto;
        padding-right: 20px;
        cursor: pointer;
        width: 65px;
        margin-top: 22px;
    }

    .user_icon_top_disabled {
        display: block;
        margin: auto;
        padding-right: 20px;
        cursor: auto;
        width: 65px;
    }

    .smart_office {
        visibility: hidden;
        width: 0;
    }

    .gucci_logo {
        width: 100%;
        height: 130px;
        margin-top: -30px;
        margin-left: 50px;
    }

    .top_header {
        width: 100%;
    }

    .home_menu {
        margin-top: 76px;
        width: 100%;
    }

    .center_menu_s {
        height: 450px;
        width: 98vw;
        margin: -9px 6px 0px 0;
        border-radius: 0px;
    }

    .center_menu_s_close {
        display: none;
    }

    .right_menu {
        height: 90vh;
        margin: 0;
        margin-top: -90px !important;
    }

    .single_module_container {
        height: 90vh;
        margin: 0;
        margin-top: 80px !important;
    }

    .pm_info_container_bookshelf_sm {
        margin-top: 0px !important;
    }

    .user_info_closed {
        visibility: hidden;
    }

    .left_menu_closed {
        visibility: hidden;
    }

    .user_icon {
        visibility: hidden;
        width: 0;
    }

    .user_text {
        text-transform: capitalize;
        text-align: center;
        width: 100% !important;
        font-size: 25px;
        margin-top: 4%;
    }

    .menu_row {
        width: 100%;
        margin: 0;
        /*
        width: 370px;
        margin: 0 7%;
        */
    }

    .menu_row1 {
        width: 100%;
        margin: 0;
    }

    .no_logo_menu_container {
        width: 100%;
        margin: 0;
    }

    .submenu_row {
        margin-left: 21%;
        width: 60%;
        margin-right: 13%;
    }

    .logo_menu {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        height: 55px;
        background-color: white;
        margin-left: 0;
    }

    .text_menu {
        margin-right: 12px;
        width: 85%;
        color: black;
        /*background-color: white;
        height: 65px;
        */
        border: 0;
        padding-right: 22px;
        font-size: 20px;
        text-align-last: left;
        padding-left: 22px;
        font-size: 16px !important;
    }

    .text_menu_container {
        margin-right: 0;
        padding-left: 0px;
        /*text-align: -webkit-center;*/
    }

    .subtext_menu {
        margin-right: 12px;
        color: black;
        background-color: white;
        border: 0;
        text-align: center;
        padding-right: 12px;
        font-size: 16px;
    }

    .submenu_arrow {
        margin-top: 0px;
        margin-left: 0px;
    }

    .logo_wellness_lab_icon {
        background-color: #000000;
        border-radius: 26px;
    }

    .logo_ready_to_grow_icon {
        background-color: #000000;
        border-radius: 26px;
    }

    /* desk menu selected*/
    .desk_booking_menu {
        background-color: #000000;
        border-radius: 18px;
    }

    .desk_booking_menu_selected .desk_menu {
        background-color: white;
    }

    /* canteen selected*/

    .menu_button_canteen {
        width: 90%;
    }

    .canteen_status {
        width: calc(45% - 10px);
        border-radius: 10px;
    }

    .max_seats {
        width: calc(45% - 10px);
        border-radius: 10px;
    }

    .canteen_container {
        padding-bottom: 100px;
        margin-left: 0;
        margin-top: 50px;
        padding-top: 0;
    }

    /*wellness_lan menu hover*/

    .wellness_lab_menu {
        border-radius: 18px;
    }

    .wellness_lab_menu:hover .wellness_menu {
        background-color: #357693;
    }

    .wellness_lab_menu:hover .logo_wellness_lab {
        background-color: #357693;
        border-radius: 0;
        height: 65px;
    }

    /* profile */

    .ProfileBox {
        width: 100% !important;
        height: 90vh !important;
        padding-top: 10px !important;
    }

    .CloseProfileBox {
        width: 100% !important;
        padding-left: 0px;
        left: 0;
    }

    .CalendarFooterComplete {
        display: none;
    }

    .CalendarFooterThin {
        display: block;
        width: 91%;
    }

    .home_menu {
        display: inline-grid;
        overflow-y: auto;
        overflow-x: auto;
        height: calc(100vh + 125px);
    }

    .e-calendar .e-content table,
    .e-bigger.e-small .e-calendar .e-content table {
        min-height: 9vh !important;
        width: 95%;
    }

    .OpenOfficeDetailBox {
        margin-top: -22px;
        max-width: 800px !important;
    }

    .container-nav {
        max-width: 800px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .right_menu {
        width: 100vw;
        height: fit-content;
        padding-bottom: 70px;
        margin-bottom: 80px;
    }

    .single_module_container {
        width: 100vw;
        padding-bottom: 70px;
        margin-bottom: 80px;
    }

    .book_detail_container {
        width: 100%;
        position: relative;
        padding-bottom: 250px;
    }

    .CalendarContainer {
        padding-bottom: 0 !important;
    }

    .detail_open {
        margin-top: 90px !important
    }

    .BookingContainer,
    .CalendarFooter .BookingContainer {
        margin-left: 8px;
        margin-top: 8px;
    }

    .ProfileBox {
        width: 100% !important;
        height: 90vh;
        left: 0;
    }

    .desk_calendar_container {
        height: 25vh;
        padding-bottom: 0px !important;
    }

    .selected_date_container {
        margin-left: 25% !important;
    }

    .container-nav {
        padding-top: 20px !important;
        padding-bottom: 30px !important;
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
        border-bottom-right-radius: 30px !important;
        width: 97vw;
    }

    .OfficeDetailBox {
        width: 100% !important;

    }

    .OfficeDetailInfoContainer {
        width: 100% !important;
        max-width: 800px;
        margin-right: 0 !important;
        padding-right: 0 !important;
        margin: auto !important;
        padding-top: 20px !important;
        padding-bottom: 14px !important;
    }

    .ReservationDetailsfixed {
        left: 5% !important;
        transform: none !important;
        width: 90% !important;
    }


    .e-calendar .e-content table,
    .e-bigger.e-small .e-calendar .e-content table {
        width: 100% !important;
    }

    .list_container {
        padding-bottom: 100px;
    }

    .card_days_container {
        margin-top: 10px;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 50px;
        overflow-y: auto;
    }

    .day_card {
        margin-bottom: 15px;
    }

    /* interactive map */
    .map_container {
        margin-top: 80px;
        padding-top: 0;
    }

    /* meeting room */

    .meeting_room_line_web {
        box-shadow: none;
    }

    .meeting_room_container {
        height: 80vh;
        margin-top: 110px;
        padding: 0 5px 100px 5px;
    }

    .meeting_room_container_web {
        overflow: auto;
        padding-top: 0;
    }

    .mr_mobile_filter_container {
        background-color: #f0efef;
        margin-top: 85px;
        height: 100vh;
        width: 100%;
        position: absolute;
        left: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
        animation: bottom_to_top 0.2s forwards;
    }

    .mr_mobile_filter_body {
        height: 85vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 100px;

    }

    @keyframes bottom_to_top {
        0% {
            top: 100vh;
        }

        100% {
            top: 20px;
        }
    }

    .meeting_room_detail_name,
    .meeting_room_detail_position {
        text-align: -webkit-left;
    }

    .meeting_room_detail_card_name {
        margin-top: 0px;
    }

    .meeting_room_detail_card_img {
        width: 300px;
    }

    .meeting_room_map {
        min-width: 42vh;
        margin-top: 20px;
    }

    #mr_modal_container {
        overflow: auto;
        height: 65vh;
        max-height: 65vh;
        min-height: 65vh;
    }

    .mr_global_container_modal {
        display: grid;
    }

    .calendar_card {
        margin-top: 50px !important;
    }

    #book_meeting_room {
        width: auto;
        position: inherit;
    }

    .availability_container {
        width: 100%;
    }

    .mr_multiselect_container {
        width: 100%;
        margin-top: 20px;
    }

    .home_menu {
        z-index: 12;
    }

    .pathlist {
        position: absolute;
        width: 100%;
        top: 60px;
        height: fit-content;
        background: white;
        z-index: 5;
        padding-top: 5px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 5px;
        flex-wrap: wrap;
        display: flex;
        font-family: 'GucciSans-Bold';
    }

    .ReservationDetailsfixed {
        max-width: 322px;
        left: 80%;
    }

    .logo {
        margin-bottom: 0;
    }

    .login-input-text {
        width: 50%;
    }

    .login-button {
        width: 50% !important;
    }

    .login-separator {
        width: 17vw;
    }

    /* wellness lab */

    #search_reset {
        margin-left: -7%;
    }


    .course_page_info_title {
        font-size: 18px;
    }

    .course_page_info_subtitle {
        font-size: 16px;
    }

    .course_page_info_subtitle_manage {
        font-size: 16px;
    }

    .course_page_info_title_manage {
        font-size: 16px;
    }

    .manage_location {
        font-size: 12px;
    }

    .reservation_course_sx {
        width: 40%;
    }

    .reservation_course_dx {
        display: contents;
    }

    .reservation_course_button {
        display: contents;
    }

    /* bookshelf */

    .bookshelf_section_title {
        margin-left: 2%;
    }

    .bookshelf_search_input {
        margin-left: 2%;
    }

    .bookshelf_detail_header_container {
        display: block !important;
    }

    .bookshelf_detail_cover_container {
        text-align: -webkit-center;
        margin-top: 15px;
    }

    #dropdown-bookshelf {
        margin-right: 0px !important;
    }

    .bookshelf_section_title_container {
        padding: 0px;
    }

    /* event module */

    .dropbtn-agenda-container {
        right: 10px;
    }

    .communication_from {
        margin-right: 0;
        margin-left: 20px;
    }

    .communication_block {
        width: 100%;
        margin-right: 0;
        padding: 10px 15px;
    }

    .countdown_container_string {
        margin-bottom: 0;
        width: 100%;
        display: flex;
        place-content: center;
    }

    .counter_string {
        width: 100%;
    }

    .countdown_columns {
        margin: 0 10px;
    }

    .communications_events_cards_container {
        overflow: auto;
        height: calc(100vh - 130px);
        margin-top: 100px;
        padding-top: 0;
    }

    .agenda_events_cards_container {
        height: calc(100vh - 240px);
        overflow: auto;
    }

    .calendar_container_agenda {
        margin-left: 0 !important;
        margin-top: 50px;
    }

    .agenda_events_card {
        margin-left: 0;
    }

    .countdown_container {
        margin-top: 50px;
        margin-left: 15px;
    }

    .countdown_container_menu {
        width: 60%;
    }

    .countdown_container_leftbar {
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        width: 99% !important;
    }

    .countdown_container_string_leftbar {
        padding-bottom: 0;
        display: flex;
        place-content: center;
        position: absolute;
        width: 100%;
        margin-top: 0;
    }


    /* share to go */
    .share_to_go_calendar>div>div.e-content.e-month>table>tbody>tr>.e-cell {
        padding: 1px 0px;
    }

    .share2go_document_overflow {
        height: calc(100vh - 200px);
        overflow: auto;
        padding-bottom: 50px;
    }

    
    .share2go_reservations_string{
        font-size: 13px;
    }

    .share_to_go_padding{
        padding: 0 10px;
    }
        
    .a-booked {
        background-color: #fff !important;
        border-radius: 50% !important;
        font-size: 16px;
    }

    .s2g_select{
        padding-left: 0px !important;
    }

    .share_to_go_calendar {
        width: 60% !important;
        margin-top: -10px !important;
    }

    .s2g_day_subtitle, .s2g_day_title, .share_to_go_message, .share_to_go_book_slot {
        font-size: 12px !important;
    }

    .share_to_go_book_slot_container, .share_to_go_titleday{
        width: 60%;
    }

    .share_to_go_form_container{
        margin-top: -30px;
    }

    .share_to_go_form_container_mobile{
        height: calc(100vh - 220px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 50px;
    }
    
    .share_to_go_select_width{
        width: 60%; 
    }

    .share_to_go_documents_list{
        display: block;
    }

    .share_to_go_documents_container{
        margin: 0 10px;
    }

    .share_to_go_documents_card {
        margin: 0;
        margin-bottom: 10px;
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
    }

    .share_to_go_card_icon{
        margin: 0;
        margin-bottom: 5px;
    }
        
    .share_to_go_sections_container{
        height: calc(100vh - 210px);
        overflow: auto;
        padding-bottom: 50px;
        margin-top: -30px;
    }

    .share_to_go_section_line_title{
        width: 96%;
        justify-self: left;
        padding-right: 40px;
    }

    .s2g_carousel_content{
        margin-left: -10px !important;
    }

    .share_to_go_section_line{
        margin-left: 10px;
        margin-right: 5px;
    }

    .s2g_button {
        width: 115px;
        font-size: 14px;
    }

    .share_to_go_buttons_container_padding{
        padding: 20px 0;
    }

    .share_to_go_buttons_container {
        width: 345px;
    }

}

@media only screen and (max-height: 800px) and (max-width: 1000px) {

    .desk_tutorial_page {
        width: 200px;
        height: 400px;
    }



    .pm_info_container_bookshelf {
        margin-top: -60px !important;
    }

    .vr_global_container {
        margin-top: 80px !important;
    }

    .right_menu {
        margin-top: -23px !important;
    }


    .detail_open {
        margin-top: 20px !important;
    }

    .pm_changemakers_global_container {
        margin-top: 60px !important;
    }

    .pm_info_global_container {
        margin-top: 76px;
    }

    .gh_changemakers_global_container {
        margin-top: 62px !important;
    }

}

@media (max-width: 800px) {



    .empty_row {
        display: grid;
        place-items: center;
    }

    .info_utili_title_cont {
        height: 66px !important;
        width: 70%;
        margin-top: 0;

    }

    .timetable_canteen {
        margin-top: 25px
    }

    .pm_info_row {
        text-align: -webkit-center;
    }

    .timetable {
        text-align: -webkit-left;
    }

    .wellness_central_logo {
        height: 35vh;
    }

    .home_text_welcome {
        font-size: 18px;
    }

    .home_title_welcome {
        font-size: 26px;
    }

    .subtitle_home {
        font-size: 14px;
    }

    .logo {
        height: 130px;
    }

    /* wellness lab */

    .gym_info_hours {
        margin-top: 0px;
    }

    .gym_info_hours_mobile {
        display: block;
        margin-top: -10px;
    }

    .gym_info_hours {
        display: none;
    }



    .leftbar {
        top: 0;
    }

    .carousel {
        margin-top: 0;
    }

    .carousel_contain {
        margin-top: 5px;
        width: 100%;
    }

    .countdown_container_leftbar {
        margin-top: 0;
        position: sticky;
        top: -20px;
        left: 0;
    }


    .countdown_container_string_leftbar {
        margin-top: 0;
        /* position: sticky; */
        /* margin-bottom: 115px; */
        padding: 0;
        display: flex;
        place-content: center;
        width: 100%;
        position: absolute;
        z-index: 2;
    }

}

@media (max-width: 750px) {

    
    /* share to go */

    .share2go_checkbox{
        display: flex;
        width: 60%;
    }

    #isAcceptedCheckbox_label{
        padding-left: 0px !important;
        line-height: 18px;
        font-size: 14px;
    }

    .opportunities_info_img {
        background-image: url('resources/img/opportunities_img_mobile.png');
        height: 100px;
        margin-bottom: 10px;
    }

    .opportunities_faq_img {
        background-image: url('resources/img/opportunities_faq_mobile.png');
        height: 100px;
        margin-bottom: 10px;
    }

    .opportunities_main_image_title_experience {
        background-image: url('resources/img/opportunities_img_experience_mobile.png');
        height: 100px;
        margin-bottom: 10px;
    }

    .opportunities_main_image_title_project {
        background-image: url('resources/img/opportunities_img_project_mobile.png');
        height: 100px;
        margin-bottom: 10px;
    }

}

@media (max-width: 650px) {

    /* share to go */

    .share_to_go_select_width {
        width: 90%;
    }

    .share_to_go_book_slot_container, .share_to_go_titleday {
        width: 90%;
    }

    .share_to_go_calendar {
        width: 100% !important;
    }

    .headset_card {
        height: 165px;
        width: 100px;
        min-width: 100px;
    }

    .headset_card_borrowed,
    .headset_card_borrowed_all {
        height: 175px;
        width: 100px;
        min-width: 100px;
    }


    @keyframes login_sep_animat {
        from {
            width: 0px;
        }

        to {
            width: 40px;
        }
    }

    .OfficeDetailFloorMap {
        width: 75% !important;
    }

    .login-separator {
        height: 2px !important;
        margin-right: 25px;
        margin-left: 25px;
        color: #000;
    }

    @keyframes login_animation {
        from {
            width: 0%;
            font-size: 0px;
        }

        to {
            width: 40%;
            font-size: 10px;
        }
    }

    .login-button {
        width: 40%;
        font-size: 10px;
    }

    .selected_date_container {
        margin-left: 10% !important;
    }

    .CloseOfficeDetailBox {
        top: 5px !important;
        right: 5px !important;
        width: 20px;
        height: 20px;

    }

    .text_menu {
        width: 75%;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
    }

    /*
    .text_menu_container {
        padding-left: 10%;

    }

     event

    .countdown_container_menu {
        width: 96%;
    }

    .countdown_container_string {

        margin-bottom: 135px;
    }

    .countdown_container_leftbar {
        width: 75%;
    }

    .countdown_container_string_leftbar {
        margin-top: -24%;
        position: relative;
<<<<<<< Updated upstream
        /* margin-bottom: 115px;*/

    /* margin-bottom: 115px;* /

    }


    .left_bar_menu_container {
        height: calc(100vh - 300px);
        text-align: center;
    }*/

    .audio_component_container {
        padding-top: 1px;
        display: block !important;
    }

    .audio_player {
        width: 100% !important;
    }

    .multimedial_content {
        margin: 20px 20px;
    }

    .feed_description_content {
        margin: 10px 20px;
    }

    .feed_list_container,
    .feed_grouplist_container {
        padding: 0 20px 30px 20px;
    }

}

@media (max-width: 600px) {

    .iziToast-wrapper-bottomCenter {

        top: 85vh;

    }

    .back {
        margin-left: 0px;
    }

    /* wellness lab */

    .location_icon {
        width: 12px;
    }

    .reservation_course_dx {
        display: none;
    }

    .reservation_course_button {
        display: none;
    }

    .reservation_course_sx {
        width: 100%;
    }

    .ReactModal__Content {
        width: 100% !important;
    }

    #search_reset {
        margin-left: -10%;
    }

    .reservations {
        width: 100%;
        align-content: center;
        overflow-y: auto;
        overflow-x: hidden;
        margin-left: 0px;
    }

    .reservations_container {
        width: 100%;
    }

    .gym_info_hours_mobile {
        padding-top: 25px;
        font-size: 8px;
        display: block;
    }

    .gym_info_hours {
        display: none;
    }

    .actions_card {
        display: block;
    }

    .course {
        width: 100%;
    }

    .top_course {
        margin-top: 65px;
    }


    .info_header {
        width: 100%;
        height: 130px;
    }

    .course_info {
        width: 70%;
    }

    .course_img_container {
        margin-left: 5px;
        margin-top: 5px;
        width: 60px;
        min-width: 60px;
    }

    .course_img {
        margin-top: 20px;
        width: 50px;
        height: 50px;
    }

    .course_description_row {
        align-items: center;
    }

    .course_description_title {
        font-size: 16px;
    }

    .row_info_title {
        margin-bottom: -15px;
    }

    .course_description_container {
        padding: 5px;
    }

    .flag_booked {
        font-size: 8px;
        margin-left: 5px;
        margin-top: 0px;
    }

    .flag_complete {
        font-size: 8px;
        margin-left: 5px;
    }

    .gucci-text-bold-s {
        font-size: 12px;
    }

    .book_button {
        font-size: 12px;
        width: 105px !important;
    }

    .book_button_disabled {
        font-size: 12px;
        width: 105px !important;
    }

    .remove_booking {
        font-size: 12px;
        width: 105px !important;
    }

    .remove_waiting {
        font-size: 12px;
        width: 105px !important;
    }

    /* fine wellness lab*/

    .login-input-text {
        width: 65% !important;
    }

    .login-button {
        width: 65% !important;
    }

    .steps_duration_mobile {

        padding-top: 5px;
        font-size: 12px;
    }

    .map_search_mobile {
        width: 100%;
        margin-left: 0;
    }

    .back_button_map {
        width: 20%;
    }

    .map_search_results {
        width: 82%;
        margin-left: 12%;
    }

    .pm_changemakers_global_container {
        margin-top: 140px
    }

    .gh_changemakers_global_container {
        margin-top: 140px;
    }

    /* ready to grow */

    .booked_byme_slot_button,
    .booked_slot_button,
    .book_slot_button {
        font-size: 12px;
        width: 105px !important;
        min-width: 105px;
    }

    .slot_subtitle {
        font-size: 12px;
        display: grid;
    }

    .slot_button_container {
        width: 140px !important;
    }

    .slot_icon {
        width: 50px;
        height: 50px;
        min-width: 50px;
    }

    .slot_hr_name {
        font-size: 22px;
        margin-top: 8px;
    }

    .carousel {
        margin-top: 0;
    }

    .carousel_contain {
        width: 100%;
    }

    .countdown_container_leftbar {
        margin-top: 0;
        position: sticky;
        top: -20px;
        left: 0;
    }


    .countdown_container_string_leftbar {
        margin-top: 0;
        /* position: sticky; */
        /* margin-bottom: 115px; */
        padding: 0;
        display: flex;
        place-content: center;
        width: 100%;
        position: absolute;
        z-index: 2;
    }

    .prev_button_mobile {
        width: 100px !important;
    }

    .next_button_mobile {
        width: 100px !important;
    }
}

@media (max-width: 500px) {

    /* share to go */


    .share_to_go_buttons_container {
        width: 100%;
        max-width: 345px;
    }

    .opportunities_info_text {
        margin-top: 60px;
    }


    .home_page,
    .bookshelf_container,
    .profile_container {
        padding-bottom: 100px !important;
    }

    .agenda_events_cards_container {
        height: calc(100vh - 330px);
    }


    .bookshelf_container,
    .bookshelf_detail_header_container_single_module,
    .generic_menu_global_container {
        padding-bottom: 200px !important;
        display: block;
    }


    .custom_page_container {
        padding-bottom: 50px !important;
        padding: 0 !important;
        display: block;
    }

    /* event module */

    .countdown_container_string {
        margin-bottom: 0;
        width: 100%;
        display: flex;
        place-content: center;
    }

    .event_image_container {
        width: 310px;
        height: 143px;
    }

    .countdown_container_string {
        padding: 20px;
    }

    .countdown_counter_text {
        font-size: 15px;
    }

    .countdown_text {
        font-size: 10px;
    }

    .tutorial_dialog>.MuiDialog-container>.MuiPaper-root,
    .whats_new_dialog>.MuiDialog-container>.MuiPaper-root {
        /* max-width: 100% !important; */
        width: 100% !important;
        margin: 10px;
    }

    .doc_button {
        width: 60%;
        padding: 4%;
        height: auto;
    }

    .left_menu {
        display: block;
    }

    /*
    .menu_row {
        width: 370px;
        margin: 0;
    }

    .menu_row1 {
        width: 355px;
        margin: 0 1%;
    }
    */

    .empty_row {
        display: grid;
        place-items: center;
    }

    .info_utili_title_cont {
        width: 100%;
        height: 65px !important;
    }

    .legend_progressbar {
        font-size: 8px;
    }

    .pm_info_row {
        width: 95%;
    }

    /* wellness lab */
    .filters_container_admin {
        left: calc(50% - 150px)
    }

    .book_button_add_user {
        width: 70px;
        padding-top: 9px;
        font-size: 12px;
    }

    .course_page_title {
        font-size: 20px;
    }

    .course_page_info_title_manage {
        font-size: 14px;
    }

    .course_page_info_subtitle_manage {
        font-size: 12px;
    }

    .manage_location {
        font-size: 12px;
        margin-top: 20px;
    }

    .booked_title {
        font-size: 14px;
    }

    .circle_manage_card {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        padding: 8px;
        font-size: 16px;
    }

    .name_manage_card {
        font-size: 14px;
    }

    .timestamp_manage_card {
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 13px;
    }

    .booked_manage_card {
        height: 50px;
    }

    .course_header_container {
        min-width: 100px;
    }

    .progressbar {
        margin-left: 5px;
        width: 45px;
        height: 45px;
    }

    .day_name {
        width: 40px;
    }

    /* fine wellness lab*/

    .steps_duration_mobile {
        width: 30%;
        text-align: -webkit-left;
    }

    #box_description {
        max-width: 200px;
    }

    .login-separator {
        width: 14vw;
    }


    .OfficeDetailFloorMap {
        width: 95% !important;
    }

    .ListToSelect#DateSelect {
        margin-left: 7px !important;
    }

    .ListToSelect#LocationSelect {
        margin-left: 7px !important;
    }

    .BookingContainer,
    .CalendarFooter .BookingContainer {
        margin-left: 0px !important;
    }

    .selected_date_container {
        margin-left: 0% !important;
    }

    .text_menu {
        width: 65%;
    }

    /* bookshelf */

    .bookshelf_detail_header {
        padding: 20px 0;
    }

    .bookshelf_detail_cover,
    .bookshelf_detail_cover_single_module {
        height: 290px;
        min-width: 206px;
        max-width: 206px;
        border-radius: 0px;
    }

    .bookshelf_detail_subtitle {
        font-size: 14px;
    }

    .bookshelf_detail_description {
        font-size: 14px;
    }

    /* BUSINESS TRAVEL */

    .business_travel_center,
    .offices_menu_center {
        margin-top: 55px !important;
    }

    .prev_button_mobile {
        width: 100px !important;
        font-size: 11px;
    }

    .next_button_mobile {
        width: 100px !important;
        font-size: 11px;
    }

    .prev_text_mobile {
        padding-top: 4px;
    }

    .next_text_mobile {
        padding-top: 4px;
    }
}

@media (max-width: 400px) {

    .iziToast-wrapper-bottomCenter {
        top: 78vh;
    }

    .feed_description {
        display: none;
    }

    .feed_title {
        font-size: 17px;
    }

    .headset_card {
        height: 165px;
        width: 100px;
    }

    .headset_card_borrowed,
    .headset_card_borrowed_all {
        height: 175px;
        width: 100px;
    }

    .card_container {
        padding-left: 0% !important;
        padding-right: 0% !important;
        margin-left: 2% !important;
        width: 95% !important;
    }

    .course_header {
        width: 100%;
        height: 252px;
        border-radius: 0;
        margin-top: 60px;
        background-position: 0;
    }

    .course_header_manage {
        width: 100%;
        height: 252px;
        border-radius: 0;
        background-position: 0;
    }


    .course_image {
        height: 70px;
        min-width: 70px;
        max-width: 70px;
    }

    .course_name {
        width: 45%;
        font-size: 12px;
        margin-top: 2% !important
    }

    .course_description {
        font-size: 12px;
    }

    .course_seats {
        font-size: 12px;
    }

    .book_button_container {
        width: 30%;
        margin-top: -4px;
    }

    .filters_container {
        top: 115px;
    }

    .book_button {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .book_button_disabled {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .remove_booking {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .remove_waiting {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
    }

    .steps_duration_mobile {
        font-size: 9px;
        /*width: 22%;*/
    }

    .map_search_results {
        margin-left: 14%;
    }

    .reservation_title {
        font-size: 22px;
    }

    /* ready to grow */

    .slot_title {
        font-size: 12px;
    }

    .slot_icon {
        width: 50px;
        height: 50px;
        min-width: 50px;
    }

    .slot_hr_name {
        font-size: 22px;
        margin-top: 8px;
    }

    .booked_byme_slot_button,
    .booked_slot_button,
    .book_slot_button {
        font-size: 10px;
        width: 90px !important;
        line-height: 10px;
        padding: 10px 7px;
        min-width: 90px;
    }

    .slot_button_align {
        display: grid;
        justify-items: center;
    }

    .delete_slot_button {
        margin-left: 0px;
    }

    .slot_button_container {
        margin-left: 7%;
    }

    .slot_button_container {
        width: 90px !important;
    }

    /* tutorial */

    .desk_tutorial_page {
        width: 235px !important;
        margin: 0 5px;
        height: 410px !important;
    }

    .desk_guide_page {
        width: 285px !important;
        margin: 0 5px;
        height: 410px !important;
    }

    .close_whats_new {
        position: absolute;
        right: 20px;
    }

    .css-ypiqx9-MuiDialogContent-root {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }


    /* event */
    /*
    .countdown_container_menu {
        width: 96%;
        margin-top: 10px;
    }

    .countdown_container_string {
        margin-bottom: 80px;
    }

    .countdown_container_string_leftbar {
        / *margin-top: -24%;* /
        position: relative;
        / *
        margin-bottom: 20px;
        width: 70%;* /
    }
*/
}

@media(max-width:350px) {
    .bookshelf_button_retry {
        line-height: 16px;
    }

    .close_scan {
        left: 86%;
    }
}

@media only screen and (max-width: 1100px) {
    .ListToSelect#DateSelect {
        margin-right: 50px;
    }
}

@media only screen and (max-width: 767px) {

    .Navbar .QrLogo {
        display: block;
    }

    .InfoBox {
        width: 70%;
        left: 15%;
    }

    .Desks {
        font-size: 3px;
    }
}

@media only screen and (max-width: 575px) {
    .InfoBox {
        width: 90%;
        left: 5%;
    }
}

/* wellness lab */
@media (max-height: 700px) {

    .desk_tutorial_page {
        width: 220px;
        height: 450px;
    }

    /* ready to grow */
    .ready_to_grow_container {
        height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media (max-height: 900px) {
    .manage_courses {
        height: 58vh;
    }

    #list_container #list_container_trainer {
        overflow-y: auto;
        overflow-x: hidden;
        height: 40vh;
    }

    .course_description_content {
        height: 29vh;
    }

    .filters_list {
        height: 90%;
    }
}


@media (max-height: 800px) {

    .home_text_container_right {
        padding-top: 20px;
    }

    .manage_courses {
        height: 50vh;
    }

    #list_container #list_container_trainer {
        overflow-y: auto;
        height: 35vh;
    }

    .top_course {
        margin-top: 10px;
    }

    .course_description_content {
        height: 25vh;
    }

    .filters_list {
        height: 80%;
    }

    .footer_course {
        display: none;
    }

    /* meeting room */

    .meeting_room_map {
        height: 32vh;
    }
}

@media (max-height: 700px) {

    #list_container {
        overflow-y: auto;
        height: 35vh;
    }

    #list_container_trainer {
        overflow-y: auto;
        height: 35vh;
    }

    .course_description_content {
        height: 20vh;
    }

    .filters_list {
        height: 85%;
    }

    .filters_body {
        height: 58vh;
    }
}

@media (max-height: 600px) {

    .opportunities_send_email{
        height: 25vh;
    }

    .opportunities_send_email_modal{
            transform: translate(-50%, -45%) !important;
    }

    .manage_courses {
        height: 40vh;
    }

    .container_manage_reservation {
        overflow-y: auto;
    }

    #list_container {
        overflow-y: auto;
        height: 30vh;
    }

    #list_container_trainer {
        overflow-y: auto;
        height: 30vh;
    }

    .footer_course {
        display: hidden;
    }

    .filters_body {
        height: 30vh;
        padding-bottom: 80px;
    }
}

@media only screen and (max-height: 900px) and (max-width: 450px) {
    .slot_list_container {
        height: auto;
    }

    .ready_to_grow_container {
        height: 82vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}